import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { baseUrl } from "../Utils/app";
import { useAuth } from "../AuthContext";

const About = () => {
  let loadingToast;
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useAuth();
  const [aboutContent, setAboutContent] = useState(null);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/getAbout`);
    };

    getItems()
      .then((resp) => {
        setAboutContent(resp.data.data);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  useEffect(() => {
    let screenTimeId;

    const startScreenTime = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/track-screen-time`,
          { screen_name: "About Screen" },
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          },
        );
        screenTimeId = response.data.screen_time_id;
      } catch (error) {
        console.error("Error tracking screen time:", error);
      }
    };

    const endScreenTime = async () => {
      if (screenTimeId) {
        try {
          await axios.post(
            `${baseUrl}/api/update-screen-time`,
            { screen_time_id: screenTimeId },
            {
              headers: {
                Authorization: `Bearer ${state?.token}`,
              },
            },
          );
        } catch (error) {
          console.error("Error updating screen time:", error);
        }
      }
    };

    // startScreenTime();

    return () => {
      // endScreenTime();
    };
  }, [state]);

  return !loading && aboutContent ? (
    <div>
      <div
        class="w-[100%] h-[100vh] bg-cover bg-center max-h-[800px]"
        style={{ backgroundImage: `url(${aboutContent?.scn1_bg_image})` }}
      >
        <h1 class="text-5xl md:text-6xl  font-bold flex justify-center  pt-[250px] text-[#18AD58]">
          {aboutContent?.scn1_title}
        </h1>
      </div>

      <div
        class="w-[100%] h-full bg-cover bg-red-300"
        style={{ backgroundImage: `url(${aboutContent?.scn2_bg_image})` }}
      >
        <div class=" w-[60%] mx-auto">
          <h1 class="text-white text-3xl md:text-4xl font-bold pt-32 text-center">
            {aboutContent?.scn2_title}
          </h1>
          <p class="text-white text-base  flex justify-center  mt-8 text-center ">
            {aboutContent?.scn2_description1}
          </p>
          <p class="text-white text-base  flex justify-center mt-4 text-center">
            {aboutContent?.scn2_description2}
          </p>
        </div>
      </div>

      <div
        class="hidden lg:block w-[100%] h-full bg-cover pt-36 pb-28 bg-right"
        style={{ backgroundImage: `url(${aboutContent?.scn3_bg_image})` }}
      >
        <div class="custom-container ">
          <div className="w-[60%]">
            <h2 class="text-4xl md:text-5xl font-bold  text-[#18AD58]">
              {aboutContent?.scn3_title}
            </h2>
            <p class="text-white text-base  mt-8">
              {aboutContent?.scn3_description}
            </p>

            <Link
              to="/home"
              className="block w-max  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold mt-6"
            >
              CONTINUE
            </Link>
          </div>
        </div>
      </div>

      <div class="lg:hidden h-auto w-[100%]  bg-cover pt-20 pb-28 bg-right bg-none bg-[#241C24] ">
        <div className="mb-10">
          <img className="w-full" src={aboutContent?.scn3_bg_image} />
        </div>
        <div class="custom-container ">
          <div className="w-[60%]">
            <h2 class="text-4xl md:text-5xl font-bold  text-[#18AD58]">
              {aboutContent?.scn3_title}
            </h2>
            <p class="text-white text-base  mt-8">
              {aboutContent?.scn3_description}
            </p>

            <Link
              to="/home"
              className="block w-max  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold mt-6"
            >
              CONTINUE
            </Link>
          </div>
        </div>
      </div>

      <div
        class="w-[100%] h-full bg-cover pt-36 "
        style={{ backgroundImage: `url(${aboutContent?.scn4_bg_image})` }}
      >
        <div className="w-[70%] mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-x-[70px] gap-y-[30px]">
            <div class="">
              <img src={aboutContent?.scn4_item1_image} alt="" />
              <h3 class="text-white text-base font-bold pt-1 text-center">
                {" "}
                {aboutContent?.scn4_item1_title}{" "}
              </h3>
            </div>

            <div class="">
              <img src={aboutContent?.scn4_item2_image} alt="" />
              <h3 class="text-white text-base font-bold pt-1 text-center">
                {" "}
                {aboutContent?.scn4_item2_title}{" "}
              </h3>
            </div>

            <div class="">
              <img src={aboutContent?.scn4_item3_image} alt="" />
              <h3 class="text-white text-base font-bold pt-1 text-center">
                {" "}
                {aboutContent?.scn4_item3_title}{" "}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-[100%] h-full bg-cover  py-36"
        style={{ backgroundImage: `url(${aboutContent?.scn5_bg_image})` }}
      >
        <div className="w-[80%] mx-auto flex flex-col lg:flex-row justify-between items-center">
          <div class="">
            <img class="w-96" src={aboutContent?.scn5_left_image} alt="" />
          </div>

          <div class="text-end">
            <h2 class="text-4xl md:text-5xl font-bold  text-[#18AD58]">
              {aboutContent?.scn5_title}
            </h2>
            <h4 class="text-white text-base  pt-8 w-[70%] ml-auto">
              {aboutContent?.scn5_description}
            </h4>
            <Link
              to="/home"
              className="inline-block w-max  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold mt-6"
            >
              CONTINUE
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <section
      className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
      style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
    >
      <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
    </section>
  );
};

export default About;
