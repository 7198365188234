import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../Utils/app";
import { useAuth } from "../AuthContext";

const Index = () => {
  let loadingToast;
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useAuth();
  const [indexContent, setIndexContent] = useState(null);
  const [modalProps, setModaProps] = useState({
    music: false,
    movie: false,
    books: false,
  });

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      // axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/getIndex`);
    };

    getItems()
      .then((resp) => {
        setIndexContent(resp.data.data);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  useEffect(() => {
    if (Object.values(modalProps).includes(true)) {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use 'auto' for an instant scroll
        });
      };
      scrollToTop();
    }
  }, [modalProps]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    let screenTimeId;

    const startScreenTime = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/track-screen-time`,
          { screen_name: "Index Screen" },
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          },
        );
        screenTimeId = response.data.screen_time_id;
      } catch (error) {
        console.error("Error tracking screen time:", error);
      }
    };

    const endScreenTime = async () => {
      if (screenTimeId) {
        try {
          await axios.post(
            `${baseUrl}/api/update-screen-time`,
            { screen_time_id: screenTimeId },
            {
              headers: {
                Authorization: `Bearer ${state?.token}`,
              },
            },
          );
        } catch (error) {
          console.error("Error updating screen time:", error);
        }
      }
    };

    // startScreenTime();

    return () => {
      // endScreenTime();
    };
  }, [state]);

  return !loading && indexContent ? (
    <div
      className={
        Object.values(modalProps).includes(true)
          ? "h-screen overflow-y-hidden"
          : ""
      }
    >
      <section
        className="hidden lg:block h-auto lg:h-screen w-full bg-cover max-h-[800px] bg-none bg-[#241C24] bg-opacity-60 "
        style={{ backgroundImage: `url(${indexContent?.scn1_bg_image})` }}
      >
        <div className="pt-[50px] lg:pt-[150px] lg:pb-0 pb-[50px] bg-[#241C24] lg:bg-transparent bg-opacity-60">
          <div className="custom-container h-full flex items-center justify-end">
            <div className=" w-[400px] h-auto ">
              {/* <h1 className="text-[#18AD58] text-2xl font-bold mb-4">ENTERTAINMENT UNLEASHED</h1>  */}
              <h1 className="text-[#18AD58] text-2xl font-bold mb-4">
                {indexContent?.scn1_title}
              </h1>
              {/* <p className="text-white text-lg text-justify mb-[50px]">Explore and enjoy a vast collection of movies, music, and books with us. Dive into our curated catalog and discover a world of popular titles at your fingertips.</p> */}
              <p className="text-white text-lg text-justify mb-[50px]">
                {indexContent?.scn1_description}
              </p>

              <div>
                <Link
                  to={"/register"}
                  className="  bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                >
                  {indexContent?.scn1_button_text}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" lg:hidden h-auto lg:h-screen w-full bg-cover max-h-[800px] bg-none bg-[#241C24] bg-opacity-60 ">
        <div>
          <img className="w-full" src={indexContent?.scn1_bg_image} />
        </div>
        <div className="pt-[50px] lg:pt-[150px] lg:pb-0 pb-[50px] bg-[#241C24] lg:bg-transparent">
          <div className="custom-container h-full flex items-center justify-end">
            <div className=" w-[400px] h-auto ">
              {/* <h1 className="text-[#18AD58] text-2xl font-bold mb-4">ENTERTAINMENT UNLEASHED</h1>  */}
              <h1 className="text-[#18AD58] text-2xl font-bold mb-4">
                {indexContent?.scn1_title}
              </h1>
              {/* <p className="text-white text-lg text-justify mb-[50px]">Explore and enjoy a vast collection of movies, music, and books with us. Dive into our curated catalog and discover a world of popular titles at your fingertips.</p> */}
              <p className="text-white text-lg text-justify mb-[50px]">
                {indexContent?.scn1_description}
              </p>

              <div>
                <Link
                  to={"/register"}
                  className="  bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                >
                  {indexContent?.scn1_button_text}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-[100px] pb-[100px] bg-[#241C24]"
        style={{ backgroundImage: `url(${indexContent?.scn2_bg_image})` }}
      >
        <div className="w-[95%] lg:w-[80%] mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6">
            <div className=" flex flex-col">
              <img
                className="w-full h-[300px] object-cover flex-shrink-0"
                src={indexContent?.scn2_item1_image}
              />
              <div className="px-[30px] py-[15px] h-full flex flex-col">
                <div className="flex-1">
                  <h2 className="text-[#18AD58] text-xl font-bold mb-4">
                    {indexContent?.scn2_item1_title}
                  </h2>
                  <p className="text-white text-base text-justify mb-[30px]">
                    {indexContent?.scn2_item1_description}
                  </p>
                </div>

                <div>
                  <a
                    hret="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setModaProps({ music: false, movie: true, books: false });
                    }}
                    className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold"
                  >
                    {indexContent?.scn2_item1_button_text}
                  </a>
                  {modalProps?.movie && (
                    <div className=" absolute h-full top-0 left-0 right-0 z-20">
                      <div className=" w-full h-full flex items-center justify-center relative ">
                        <div
                          onClick={() =>
                            setModaProps({
                              music: false,
                              movie: false,
                              books: false,
                            })
                          }
                          className="bg-black absolute h-full top-0 left-0 right-0 bg-opacity-50"
                        ></div>
                        <div className=" w-[600px] h-auto bg-white rounded-[40px] px-[40px] py-[20px] z-10">
                          <img
                            src="/assets/Images/logo.png"
                            className="mx-auto mb-[20px]"
                            width="120"
                          />

                          <div
                            className="w-full h-[300px] bg-cover bg-top pt-[25px]"
                            style={{
                              backgroundImage: "url(/assets/Images/screen.png)",
                            }}
                          >
                            <img
                              className="w-full h-full bg-red-400"
                              src={indexContent?.scn2_item1_modal_gif}
                            />
                          </div>

                          <Link
                            to={"/register"}
                            className="block w-max mx-auto bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold mt-[20px]"
                          >
                            START NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <img
                className="w-full h-[300px] object-cover"
                src={indexContent?.scn2_item2_image}
              />
              <div className="px-[30px] py-[15px] h-full flex flex-col">
                <div className="flex-1">
                  <h2 className="text-[#18AD58] text-xl font-bold mb-4">
                    {indexContent?.scn2_item2_title}
                  </h2>
                  <p className="text-white text-base text-justify mb-[30px]">
                    {indexContent?.scn2_item2_description}
                  </p>
                </div>

                <div>
                  {/* <Link to={"/register"} className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold">LISTEN NOW</Link> */}
                  <a
                    hret="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setModaProps({ music: true, movie: false, books: false });
                    }}
                    className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold"
                  >
                    {indexContent?.scn2_item2_button_text}
                  </a>
                  {modalProps?.music && (
                    <div className=" absolute h-full top-0 left-0 right-0  z-20">
                      <div className=" w-full h-full flex items-center justify-center relative ">
                        <div
                          onClick={() =>
                            setModaProps({
                              music: false,
                              movie: false,
                              books: false,
                            })
                          }
                          className="bg-black absolute h-full top-0 left-0 right-0 bg-opacity-50"
                        ></div>
                        <div className=" w-[600px] h-auto bg-white rounded-[40px] px-[40px] py-[20px] z-10">
                          <img
                            src="/assets/Images/logo.png"
                            className="mx-auto mb-[20px]"
                            width="120"
                          />

                          <div
                            className="w-full h-[300px] bg-cover bg-top pt-[25px]"
                            style={{
                              backgroundImage: "url(/assets/Images/screen.png)",
                            }}
                          >
                            <img
                              className="w-full h-full bg-red-400"
                              src={indexContent?.scn2_item2_modal_gif}
                            />
                          </div>

                          <Link
                            to={"/register"}
                            className="block w-max mx-auto bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold mt-[20px]"
                          >
                            START NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <img
                className="w-full h-[300px] object-cover"
                src={indexContent?.scn2_item3_image}
              />
              <div className="px-[30px] py-[15px]">
                <h2 className="text-[#18AD58] text-xl font-bold mb-4">
                  {indexContent?.scn2_item3_title}
                </h2>
                <p className="text-white text-base text-justify mb-[30px]">
                  {indexContent?.scn2_item3_description}
                </p>
                <div>
                  <a
                    hret="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setModaProps({ music: false, movie: false, books: true });
                    }}
                    className="flex-shrink-0  bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold"
                  >
                    {indexContent?.scn2_item3_button_text}
                  </a>
                  {modalProps?.books && (
                    <div className=" absolute h-full top-0 left-0 right-0  z-20">
                      <div className=" w-full h-full flex items-center justify-center relative ">
                        <div
                          onClick={() =>
                            setModaProps({
                              music: false,
                              movie: false,
                              books: false,
                            })
                          }
                          className="bg-black absolute h-full top-0 left-0 right-0 bg-opacity-50"
                        ></div>
                        <div className=" w-[600px] h-auto bg-white rounded-[40px] px-[40px] py-[20px] z-10">
                          <img
                            src="/assets/Images/logo.png"
                            className="mx-auto mb-[20px]"
                            width="120"
                          />

                          <div
                            className="w-full h-[300px] bg-cover bg-top pt-[25px]"
                            style={{
                              backgroundImage: "url(/assets/Images/screen.png)",
                            }}
                          >
                            <img
                              className="w-full h-full bg-red-400"
                              src={indexContent?.scn2_item3_modal_gif}
                            />
                          </div>

                          <Link
                            to={"/register"}
                            className="block w-max mx-auto bg-[#18AD58] px-[40px] py-[10px] text-base rounded-xl text-white font-bold mt-[20px]"
                          >
                            START NOW
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <section
      className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
      style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
    >
      <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
    </section>
  );
};

export default Index;
