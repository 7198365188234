import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../Utils/app";

const Cookie = () => {
  let loadingToast;
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState(null);
  const [privacy, setPrivacy] = useState({});
  const [cookie, setCookie] = useState({});

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/getPolicy`);
    };

    getItems()
      .then((resp) => {
        setTerms(resp?.data?.data?.filter((item) => item?.type == "t&c")[0]);
        setPrivacy(
          resp?.data?.data?.filter((item) => item?.type == "privacy")[0],
        );
        setCookie(
          resp?.data?.data?.filter((item) => item?.type == "cookie")[0],
        );
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  return (
    <section
      className="min-h-screen bg-[#241C24] bg-cover pb-[100px]"
      style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
    >
      <div className="custom-container ">
        <div className="pt-[150px]">
          <h1 className="uppercase text-3xl font-bold text-white text-center mb-[40px]">
            COOKIES POLICY
          </h1>
          <p className="text-white text-lg text-center font-semibold  mb-7">
            Introduction
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            {" "}
            This Subscriber Agreement (hereinafter the “Agreement”) is entered
            into by and between You, a natural person identified upon
            registration (hereinafter referred to as “You”) and InfiniteGroove
            Limited, a private limited liability company organised and existent
            under the laws of the Republic of Cyprus, enrolled with the RegisIn
            this Cookies Policy references to “website” or “site” are references
            to OneHubPlay.com, and references to “We” or “Us” are references to
            the website and to InfiniteGroove Limited which manages the website.
          </p>
          <p className="text-white text-xs  text-justify mb-4 font-bold">
            Our Privacy Policy explains our principles when it comes to the
            collection, processing, and storage of your personal information.
            This Cookies Policy specifically explains how We deploy Cookies, as
            well as the options You have to control them.
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-7">
            {" "}
            We use Cookies to personalise content and ads, to provide social
            media features and to analyse our traffic. We also share information
            about your use of our site with our social media, advertising and
            analytics partners who may combine it with other information that
            You’ve provided to them or that they’ve collected from your use of
            their services.
          </p>
          <h3 className="text-base font-bold text-center text-white mb-4">
            YOU CONSENT TO OUR COOKIES IF YOU CONTINUE TO USE OUR WEBSITE.
          </h3>
          <h3 className="text-base font-bold text-center text-white mb-7">
            RECITALS
          </h3>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            <b>WHEREAS, </b> Cookies consist of portions of code installed in
            the browser that assist Us in providing You with the OneHubPlay
            Service according to the purposes described. Some of the purposes
            for which Cookies are installed may also require your consent;
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            <b>WHEREAS, </b> WHEREAS, the installation of Cookies is based on
            consent, such consent can be freely withdrawn at any time following
            the instructions provided in this Cookies Policy;
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            <b>WHEREAS, </b> this site uses different types of Cookies. Some
            Cookies may be placed by third-party services that might appear on
            our pages;
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            <b>WHEREAS, </b>, this Cookies Policy has been prepared based on
            provisions of multiple legislations, including articles 13 and 14 of
            Regulation (EU) 2016/679 of the European Parliament and of the
            Council of 27 April 2016 – General Data Protection Regulation – and
            Directive 2002/58/EC of the European Parliament and of the Council
            of 12 July 2002 – e-Privacy Directive.
          </p>
          <h3 className="text-base font-bold text-left text-white mb-4">
            1. DEFINITIONS
          </h3>
          {/* <table class="min-w-full border border-gray-200">
                        <thead>
                            <tr>
                            <th class="border border-gray-200 px-4 py-2">Header 1</th>
                            <th class="border border-gray-200 px-4 py-2">Header 2</th>
                            <th class="border border-gray-200 px-4 py-2">Header 3</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td class="border border-gray-200 px-4 py-2">Row 1, Cell 1</td>
                            <td class="border border-gray-200 px-4 py-2">Row 1, Cell 2</td>
                            <td class="border border-gray-200 px-4 py-2">Row 1, Cell 3</td>
                            </tr>
                            <tr>
                            <td class="border border-gray-200 px-4 py-2">Row 2, Cell 1</td>
                            <td class="border border-gray-200 px-4 py-2">Row 2, Cell 2</td>
                            <td class="border border-gray-200 px-4 py-2">Row 2, Cell 3</td>
                            </tr>
                            <tr>
                            <td class="border border-gray-200 px-4 py-2">Row 3, Cell 1</td>
                            <td class="border border-gray-200 px-4 py-2">Row 3, Cell 2</td>
                            <td class="border border-gray-200 px-4 py-2">Row 3, Cell 3</td>
                            </tr>
                        </tbody>
                    </table> */}

          <table class="min-w-full border border-gray-200 text-white mb-7">
            <tbody>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Authentication Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies, to identify the User once he has logged in, for the
                  duration of a session.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">“Cookie(s)”</td>
                <td class="border border-gray-200 px-4 py-2">
                  a Cookie is a small piece of data that a website asks your
                  browser to store on your computer or mobile device. The Cookie
                  allows the website to "remember" your actions or preferences
                  over time.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Controller” or “Data Controller”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  means the natural or legal person, public authority, agency or
                  other body which, alone or jointly with others, determines the
                  purposes and means of the processing of Personal Data; where
                  the purposes and means of such processing are determined by
                  Union or Member State law, the Controller or the specific
                  criteria for its nomination may be provided for by Union or
                  Member State law.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">“Data Subject”</td>
                <td class="border border-gray-200 px-4 py-2">
                  means the natural person to whom the Personal Data refers.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “European Union (or EU)”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  unless otherwise speciﬁed, all references made within this
                  document to the European Union include all current member
                  states to the European Union and the European Economic Area.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “First-Party Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  means Cookies that are set by the web server of the visited
                  page and share the same domain.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Load‐Balancing Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies, for the duration of the session.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Multimedia Player Session Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies which store technical data needed to play back audio
                  or video content, such as flash player cookies.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  Persistent Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies which remain on the User’s computer/device for a
                  pre-defined period of time.
                </td>
              </tr>

              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Personal Data (or Data)”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  means any information relating to an identified or
                  identifiable natural person (‘Data Subject’); an identifiable
                  natural person is one who can be identified, directly or
                  indirectly, in particular by reference to an identifier such
                  as a name, an identification number, location data, an online
                  identifier or to one or more factors specific to the physical,
                  physiological, genetic, mental, economic, cultural or social
                  identity of that natural person.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">“Processor”</td>
                <td class="border border-gray-200 px-4 py-2">
                  means a natural or legal person, public authority, agency or
                  other body that processes Personal Data on behalf of the
                  Controller.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “OneHubPlay Service”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  means the service provided by Us as described in the relative
                  Subscriber Agreement on this website.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Session Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies which are erased when the User closes the browser.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Third-Party Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  means Cookies stored by a different domain to the visited
                  page's domain. This can happen when the webpage references a
                  file, such as JavaScript, located outside its domain.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “Third‐Party Social Plug‐In Content‐Sharing Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies for logged‐in members of a social network.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">“Usage Data”</td>
                <td class="border border-gray-200 px-4 py-2">
                  means information collected automatically through the
                  OneHubPlay Service (or third-party services employed in the
                  OneHubPlay Service), which can include: the IP addresses or
                  domain names of the computers utilised by the Users who use
                  the OneHubPlay Service, the URI addresses (Uniform Resource
                  Identiﬁer), the time of the request, the method utilised to
                  submit the request to the server, the size of the ﬁle received
                  in response, the numerical code indicating the status of the
                  server's answer (successful outcome, error, etc.), the country
                  of origin, the features of the browser and the operating
                  system utilised by the User, the various time details per
                  visit (e.g., the time spent on each page within the OneHubPlay
                  Service) and the details about the path followed within the
                  OneHubPlay Service with special reference to the sequence of
                  pages visited, and other parameters about the device operating
                  system and/or the User's IT environment.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">“User”</td>
                <td class="border border-gray-200 px-4 py-2">
                  the individual using the OneHubPlay Service who, unless
                  otherwise speciﬁed, coincides with the Data Subject.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “User‐Centric Security Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies, used to detect authentication abuses, for a limited
                  persistent duration.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “User‐Input Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies (session-id) such as First‐Party Cookies to keep track
                  of the User's input when filling online forms, shopping carts,
                  etc., for the duration of a session or Persistent Cookies
                  limited to a few hours in some cases.
                </td>
              </tr>
              <tr>
                <td class="border border-gray-200 px-4 py-2">
                  “User-Interface Customisation Cookies”
                </td>
                <td class="border border-gray-200 px-4 py-2">
                  Cookies such as language or font preferences, for the duration
                  of a session (or slightly longer).{" "}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mb-7">
            <p className="text-bold text-base text-white"></p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className=""></li>
              <li className="">
                <p className="mb-4"></p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className=""></li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">2. COOKIES</p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                <p className="mb-4">2.1. Cookies We Use</p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    2.1.1. The OneHubPlay Service uses (a) User-Input Cookies;
                    (b) Authentication Cookies; (c) User-Centric Security
                    Cookies; (d) Load-Balancing Cookies; (e) User-Interface
                    Customisation Cookies; (f) Third-Party Social Plug-In
                    Content-Sharing Cookies; and (g) Multimedia Player Session
                    Cookies. According to the EU advisory body on data
                    protection WP29, these Cookies are exempt from Users’
                    consent.
                  </li>
                  <li className="">
                    2.1.2. The OneHubPlay Service uses Cookies to save browsing
                    preferences and to optimise Users’ browsing experience.
                    Among these Cookies are, for example, those used for the
                    setting of language and currency preferences or for the
                    management of first-party statistics employed directly by
                    the Controller.
                  </li>
                  <li className="">
                    2.1.3. Technical Cookies and Cookies serving aggregated
                    statistical purposes. The OneHubPlay Service uses Cookies to
                    save the User session and to carry out other activities that
                    are strictly necessary for the operation of the OneHubPlay
                    Service, for example, in relation to the distribution of
                    traffic.
                  </li>
                  <li className="">
                    <p className="mb-4">
                      2.1.4. Purpose, Description, Type and Duration:
                    </p>
                    <table class="min-w-full border border-gray-200mb-4 ml-4 ">
                      <thead>
                        <tr>
                          <th class="border border-gray-200 px-4 py-2">
                            Purpose
                          </th>
                          <th class="border border-gray-200 px-4 py-2">
                            Description
                          </th>
                          <th class="border border-gray-200 px-4 py-2">
                            Type and Duration
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border border-gray-200 px-4 py-2">
                            Performance
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            The OneHubPlay Service is built using common
                            internet platforms. These have built-in Cookies
                            which help compatibility issues (e.g., to identify
                            your browser type) and improve performance (e.g.,
                            quicker loading of content).
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            Session. Deleted upon closing browser.
                          </td>
                        </tr>
                        <tr>
                          <td class="border border-gray-200 px-4 py-2">
                            Security
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            If You register for access to a restricted area, our
                            Cookies ensure that your device is logged for the
                            duration of your visit. You will need your username
                            and password to access the restricted areas.
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            Session. Deleted upon closing browser.
                          </td>
                        </tr>
                        <tr>
                          <td class="border border-gray-200 px-4 py-2">
                            Site Preferences
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            Our Cookies may also remember your site preferences
                            (e.g. language) or seek to enhance your experience
                            (e.g. by personalizing a greeting or content). This
                            will apply to areas where You have registered
                            specifically for access or create an account.
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            Session. Deleted upon closing browser.
                          </td>
                        </tr>
                        <tr>
                          <td class="border border-gray-200 px-4 py-2">
                            Analytical
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            We use several third-party analytics tools to help
                            Us understand how Users use the OneHubPlay Service.
                            This allows Us to improve the quality and content
                            for You. The aggregated statistical data cover items
                            such as total visits or page views, and referrers to
                            the OneHubPlay Service.
                          </td>
                          <td class="border border-gray-200 px-4 py-2">
                            Persistent, but will be deleted automatically after
                            two years if You no longer use the OneHubPlay
                            Service.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </li>
              <li className="">
                2.2. The Cookies will not be used for any purpose other than the
                one herein stated.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              3. THIRD PARTIES THAT INSTALL COOKIES
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                3.1. Some of the services listed below collect statistics in an
                anonymised and aggregated form and may not require User’s
                consent or may be managed directly by the Controller - depending
                on how they are described - without the help of third parties.
              </li>
              <li className="">
                <p className="mb-4">
                  If any services operated by third parties are listed among the
                  tools below, these may be used to track your browsing habits –
                  in addition to the information speciﬁed herein and without the
                  Controller’s knowledge. Please refer to the privacy policy of
                  the listed services for detailed information.
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    <b>(i) Analytics.</b> The services in this section enable Us
                    to monitor and analyze web traffic and can be used to keep
                    track of User behavior.
                  </li>
                  <li className="">(a) Google Analytics (Google INC.)</li>
                  <li className="">
                    Google Analytics is a web analysis service provided by
                    Google Inc. (“Google”). Google utilises the data collected
                    to track and examine the use of the OneHubPlay Service, to
                    prepare reports on its activities and share them with other
                    Google services. Google may use the data collected to
                    contextualise and personalise the ads of its own advertising
                    network.
                  </li>
                  <li className="">
                    Personal Data collected: Cookies and Usage Data.
                  </li>
                  <li className="">Place of processing: US</li>
                  <li className="">
                    You can Opt-Out of Google Analytics by visiting{" "}
                    <a href="www.google.com/settings/ads">
                      www.google.com/settings/ads
                    </a>{" "}
                    or{" "}
                    <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                      https://tools.google.com/dlpage/gaoptout?hl=en
                    </a>{" "}
                  </li>
                  <li className="">
                    Privacy Policy -{" "}
                    <a href="https://www.google.com/intl/en/policies/privacy/">
                      https://www.google.com/intl/en/policies/privacy/
                    </a>{" "}
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              4. HOW TO PROVIDE OR WITHDRAW CONSENT TO THE INSTALLATION OF
              COOKIES
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                4.1. IN ADDITION TO WHAT IS SPECIFIED IN THIS COOKIES POLICY,
                YOU CAN MANAGE PREFERENCES FOR COOKIES DIRECTLY FROM WITHIN YOUR
                OWN BROWSER AND PREVENT – FOR EXAMPLE – THIRD PARTIES FROM
                INSTALLING COOKIES.
              </li>
              <li className="">
                4.2. Through browser preferences, it is also possible to delete
                Cookies installed in the past, including the Cookies that may
                have saved the initial consent for the installation of Cookies
                by this website.
              </li>
              <li className="">
                4.3. You can control and/or delete Cookies, see details on
                aboutcookies.org. You can delete all Cookies that are already on
                your computer, and You can set most browsers to prevent them
                from being placed. If You do this, however, You may have to
                manually adjust some preferences every time You visit a site and
                some services and functionalities may not work.
              </li>
              <li className="">
                4.4. With regard to Cookies installed by third parties, You can
                manage your preferences and withdrawal of your consent by
                clicking the related opt-out link (if provided), by using the
                means provided in the third party's privacy policy, or by
                contacting the third party.
              </li>
              <li className="">
                4.5. Since the installation of Third-Party Cookies and other
                tracking systems used through the OneHubPlay Service cannot be
                technically controlled by the Controller, any speciﬁc references
                to Cookies and tracking systems installed by third parties are
                to be considered indicative. In order to obtain complete
                information, the User is kindly requested to consult the privacy
                policy for the respective third-party services listed in this
                Cookies Policy.
              </li>
              <li className="">
                4.6. Due to the complexity surrounding the identification of
                technologies based on Cookies, Users are encouraged to contact
                the Data Controller should they wish to receive any further
                information on the use of Cookies.
              </li>
              <li className="">
                <p className="mb-4"></p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className=""></li>
                </ul>
              </li>
            </ul>
          </div>

          <p className="text-white text-xs font-semibold text-justify mb-4">
            InfiniteGroove Limited
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            Pipinou, 6, 2320, Nicosia, Cyprus
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            E-mail: legal@onehubplay.com
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-4">
            Cookies Policy last updated on 29.1.2024.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Cookie;
