import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { baseUrl } from "../Utils/app";
import axios from "axios";

const ProtectedLayout = ({ unAuthRoute }) => {
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  // let prevLocation = "";
  // let screenTimeId = null;

  const effectRan = useRef(false);
  const previousPage = useRef(null);
  const screenTimeId = useRef(null);
  useEffect(() => {
    // if (effectRan.current) return;

    const startScreenTime = async (value) => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/track-screen-time`,
          { screen_name: value },
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          },
        );
        screenTimeId.current = response.data.screen_time_id;
      } catch (error) {
        console.error("Error tracking screen time:", error);
      }
    };

    const endScreenTime = async () => {
      if (screenTimeId.current) {
        try {
          await axios.post(
            `${baseUrl}/api/update-screen-time`,
            { screen_time_id: screenTimeId },
            {
              headers: {
                Authorization: `Bearer ${state?.token}`,
              },
            },
          );
          screenTimeId.current = null;
        } catch (error) {
          console.error("Error updating screen time:", error);
        }
      }
    };


    console.log("on Load Previous page is " + previousPage.current);
    if (previousPage.current == null) {
      console.log(
        "Previous page is null and is setting value of " + location.pathname,
      );
      // startScreenTime(location.pathname);
      previousPage.current = location.pathname;
    } else if (
      previousPage.current != null &&
      previousPage.current != location.pathname
    ) {
      console.log(
        "Previous page is " +
          previousPage.current +
          " and is setting value of " +
          location.pathname,
      );
      // endScreenTime();
      // startScreenTime(location.pathname);
      previousPage.current = location.pathname;
    }
  }, [state, location, prevLocation]);

  return (
    <>
      <Outlet></Outlet>
    </>
  );
};

export default ProtectedLayout;
