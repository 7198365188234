  import React, { useState, useEffect,useRef } from "react";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import toast from "react-hot-toast";
  import { useAuth } from "../AuthContext";
  import { Swiper, SwiperSlide } from "swiper/react";
  import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
  import { baseUrl } from "../Utils/app";
  import ItemCard from "./Components/ItemCard";

  const Musics = () => {
    let loadingToast;
    const effectRan = useRef(false);
    const { state, dispatch } = useAuth();
    const [loading, setLoading] = useState(false);
    const [musics, setMusics] = useState([]);
    const [musics2, setMusics2] = useState([]);
    const [requestData, setReqeustData] = useState([]);
    const [includeds, setIncludeds] = useState([]);
    const [links, setLinks] = useState([]);
    const [featured, setFeatured] = useState();
    const [moreLoading, setMoreLoading] = useState(false);
    const [featuredArtist, setFeaturedArtist] = useState();

    useEffect(() => {
      setLoading(true);
      loadingToast = toast.loading("Loading");

      // const getItems = async () => {
      //    return await axios.get(`${baseUrl}/api/musics`)
      // }

      // getItems().then((resp) => {
      //     setMusics(resp.data.data.musics)
      //     toast.dismiss(loadingToast)
      //     setFeatured(resp.data.data.featured)

      // }).catch((error)=>{
      //     console.log(error)
      //     toast.dismiss(loadingToast)
      //     toast.error(error?.message);
      // });

      getFeaturedMusic(17044);

      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
          Authorization:
            "Token QxYWoTb4uUS27V6hRvrj2ip3VWSVgP1WISyN4Ps2PPnYkzG59vjl7Z2GY0uQpD3h",
        },
      };

      fetch("https://api.soundstripe.com/v1/songs", options)
        .then((response) => response.json())
        .then((response) => {
          setReqeustData(response);
          setMusics2(response?.data);
          setIncludeds(response?.included);
          setLinks(response?.links);
          setLoading(false);
          toast.dismiss(loadingToast);
          console.log(response);
        })
        .catch((err) => {
          toast.dismiss(loadingToast);
          toast.error(err?.message);
          console.error(err);
        });
    }, []);

    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use 'auto' for an instant scroll
        });
      };
      scrollToTop();
    }, []);


    useEffect(() => {
      if (effectRan.current) return; 
      if(!state?.token) return ;
      loadingToast = toast.loading("Loading");

      const trackVisit = async () => {
        axios.defaults.withCredentials = true;
        return axios.post(`${baseUrl}/api/track-music-visit`, {}, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
      }

      trackVisit()
        .then((resp) => {
          toast.dismiss(loadingToast);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });

      effectRan.current = true; // Mark effect as run
    },[state?.token])


    const getFeaturedMusic = (id) => {
      setLoading(true);
      loadingToast = toast.loading("Loading", { duration: 10000 });
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
          Authorization:
            "Token QxYWoTb4uUS27V6hRvrj2ip3VWSVgP1WISyN4Ps2PPnYkzG59vjl7Z2GY0uQpD3h",
        },
      };

      fetch("https://api.soundstripe.com/v1/songs/" + id, options)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setFeatured(response.data);
          console.log(
            response?.included?.filter((item) => item?.type == "artists"),
          );
          setFeaturedArtist(
            response?.included?.filter((item) => item?.type == "artists")[0],
          );
          toast.dismiss(loadingToast);
          setLoading(false);
        })
        .catch((err) => {
          toast.dismiss(loadingToast);
          toast.error(err?.message);
          console.error(err);
        });
    };

    const onShowMorePress = () => {
      if (links?.next) {
        setMoreLoading(true);
        const options = {
          method: "GET",
          headers: {
            accept: "application/json",
            "Content-Type": "application/vnd.api+json",
            Accept: "application/vnd.api+json",
            Authorization:
              "Token QxYWoTb4uUS27V6hRvrj2ip3VWSVgP1WISyN4Ps2PPnYkzG59vjl7Z2GY0uQpD3h",
          },
        };

        fetch(links?.next, options)
          .then((response) => response.json())
          .then((response) => {
            setReqeustData(response);
            setMusics2([...musics2, ...response?.data]);
            setIncludeds([...includeds, ...response?.included]);
            setLinks(response?.links);
            setLoading(false);
            toast.dismiss(loadingToast);
            console.log(response);

            setMoreLoading(false);
          })
          .catch((err) => {
            toast.dismiss(loadingToast);
            toast.error(err?.message);
            console.error(err);
            setMoreLoading(false);
          });
      }
    };

    const getMusicImage = (musicId) => {
      let image = includeds?.find((artist) => artist?.id == musicId)?.attributes
        ?.image;
      let imageString = image?.split("?");
      return imageString?.length > 1 ? imageString[0] + "?w=500&h=500" : "";
    };

    return (
      <>
        {!loading ? (
          <>
            {/* <Swiper
                              modules={[Navigation, Pagination, A11y]}
                              spaceBetween={0}
                              slidesPerView={1}
                              navigation
                              pagination={{ clickable: true }}
                              onSwiper={(swiper) => console.log(swiper)}
                              onSlideChange={() => console.log('slide change')}
                              >
                                  {
                                      featured.length > 0 && featured.map((item) =>  <SwiperSlide>
                                              <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: "url(./assets/Images/musicBg.png)"}}>
                                                  <div className="custom-container flex items-center h-full">
                                                      <div className="w-[50%] min-w-[500px]">
                                                          <h2 className="text-2xl tracking-[15px] text-white ">Music</h2>
                                                          <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/musicPlayer/"+item.id}>{item?.title}</Link> </h1>
                                                          <p className="text-white text-xl mb-7">{item?.author}</p>
                                                          <Link to={"/musicPlayer/"+item.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">LISTEN NOW</Link>
                                                      </div>
                                                  </div>
                                              </section>
                                          </SwiperSlide>
                                      )
                                  }
                          </Swiper> */}
            {/* <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: `url(${featured?.cover_image_large})`}}>
                              <div className="custom-container flex items-center h-full">
                                  <div className="md:w-[50%] md:min-w-[500px]">
                                      <h2 className="text-2xl tracking-[15px] text-white uppercase">Music</h2>
                                      <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/musicPlayer/"+featured?.id}>{featured?.title}</Link> </h1>
                                      <p className="text-white text-xl mb-7">{featured?.author}</p>
                                      <Link to={"/musicPlayer/"+featured?.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">LISTEN NOW</Link>
                                  </div>
                              </div>
                          </section> */}
            <section
              className="hidden lg:block h-auto lg:h-screen bg-[#241C24] bg-cover bg-right max-h-[800px]"
              style={{ backgroundImage: `url(assets/Images/music2.jpg)` }}
            >
              <div className="custom-container flex items-center h-full">
                <div className="md:w-[50%] md:min-w-[500px]">
                  <h2 className="text-2xl tracking-[15px] text-white uppercase">
                    Music
                  </h2>
                  <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">
                    {" "}
                    <Link to={"/musicPlayer/" + featured?.id}>
                      {featured?.attributes?.title}
                    </Link>{" "}
                  </h1>
                  <p className="text-white text-xl mb-7">
                    {featuredArtist?.attributes?.name}
                  </p>
                  <Link
                    to={"/musicPlayer/" + featured?.id}
                    className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                  >
                    LISTEN NOW
                  </Link>
                </div>
              </div>
            </section>
            <section
              className=" lg:hidden h-auto lg:h-screen w-full bg-cover max-h-[800px] bg-[#241C24] bg-opacity-60 bg-right"
              style={{ backgroundImage: `url(assets/Images/music2.jpg)` }}
            >
              <div className="pt-[150px] lg:pt-[150px] lg:pb-0 pb-[50px]  lg:bg-transparent">
                <div className="custom-container flex items-center h-full">
                  <div className="md:w-[50%] md:min-w-[500px]">
                    <h2 className="text-2xl tracking-[15px] text-white uppercase">
                      Music
                    </h2>
                    <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">
                      {" "}
                      <Link to={"/musicPlayer/" + featured?.id}>
                        {featured?.attributes?.title}
                      </Link>{" "}
                    </h1>
                    <p className="text-white text-xl mb-7">
                      {featuredArtist?.attributes?.name}
                    </p>
                    <Link
                      to={"/musicPlayer/" + featured?.id}
                      className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                    >
                      LISTEN NOW
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="pt-[100px] pb-[50px] bg-[#241C24] mt-[-2px]"
              style={{ backgroundImage: "url(/assets/Images/sec3bg.png)" }}
            >
              <div className="custom-container">
                <div className=" mx-auto">
                  <div className="flex justify-between mb-[44px]">
                    <h2 className=" text-white text-3xl font-bold uppercase">
                      Music
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-x-[30px] gap-y-[40px]">
                    {/* {
                                              musics?.length > 0 && musics?.map(
                                                  (item) => <ItemCard routeName={"/musicPlayer/"} item={item}></ItemCard>
                                              )
                                          } */}
                    {musics2?.length > 0 &&
                      musics2?.map((item) => (
                        <Link
                          to={"/musicPlayer/" + item.id}
                          className="block h-[350px] relative group"
                        >
                          <img
                            className="h-[350px] object-cover bg-gray-600 w-full"
                            src={getMusicImage(
                              item?.relationships?.artists?.data[0]?.id,
                            )}
                          />
                          <div className=" bg-[#241C24] opacity-90 p-3 group-hover:p-5 group-hover:h-full absolute bottom-0 left-0 right-0 z-10">
                            <p className="text-white uppercase group-hover:hidden">
                              {item?.attributes?.title}{" "}
                              {item?.relationships?.artists?.data[0]?.id}{" "}
                            </p>
                            <div className="border-2 border-white h-full pt-[57px] text-center p-3 transition-all hidden group-hover:block ease-in-out delay-150 ">
                              <p className="text-white font-bold text-center text-xl uppercase mb-2">
                                {item?.attributes?.title}
                              </p>
                              <svg
                                className=" mx-auto"
                                width="46"
                                height="46"
                                fill="none"
                                stroke="#fff"
                                stroke-width="1"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z"></path>
                                <path
                                  fill="#fff"
                                  stroke="none"
                                  d="m10.14 15.676 5.365-3.241a.511.511 0 0 0 0-.872L10.14 8.322a.505.505 0 0 0-.765.436v6.481a.505.505 0 0 0 .765.437Z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                  <div className="mt-[80px]">
                    {!moreLoading ? (
                      <button
                        onClick={() => onShowMorePress()}
                        className=" block bg-[#18AD58] px-[40px] py-[5px] text-[16px] rounded-md text-white font-semibold uppercase mx-auto"
                      >
                        Show More
                      </button>
                    ) : (
                      <button className=" block bg-[#18AD58] px-[40px] py-[5px] text-[16px] rounded-md text-white font-semibold uppercase mx-auto">
                        Loading...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <section
            className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
            style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
          >
            <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
          </section>
        )}
      </>
    );
  };

  export default Musics;