import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { state, dispatch } = useAuth();
  const location = useLocation();

  return (
    <>
      {state.isAuth ? (
        <section class="py-[20px] bg-[#241C24]">
          <div class="custom-container md:flex justify-between items-center">
            <div>
              <p class=" text-sm text-[#fff] flex space-x-3 mb-2 md:mb-0">
                <Link to={"/terms-condition"} class="block underline">
                  Terms and Conditions
                </Link>{" "}
                <Link to={"/privacy"} class="block underline">
                  Privacy Policy
                </Link>{" "}
                <Link to={"/cookie"} class="block underline">
                  Cookie Policy
                </Link>{" "}
                <Link to={"/FAQ"} class="block underline">
                  FAQ
                </Link>
              </p>
              <p class=" text-sm text-[#fff] space-x-3 mb-2 md:mb-0">
                Copyright © 2024{" "}
                <span className="text-nowrap">
                  InfiniteGroove Limited/onehubplay.com.
                </span>{" "}
                All Rights Reserved.
              </p>
              <p class=" text-sm text-[#fff] flex space-x-3 mb-2 md:mb-0">
                Pipinou, 6, 2320, Nicosia, Cyprus.
              </p>
              <p class=" text-sm text-[#fff] flex space-x-3 mb-2 md:mb-0">
                <a href="mailto:support@onehubplay.com" className=" underline">
                  support@onehubplay.com
                </a>
              </p>
            </div>
            <div>
              <img width="150" src="/assets/Images/logo.png" />
            </div>
          </div>
        </section>
      ) : (
        <>
          {(location.pathname != "/subscribe" &&
            location.pathname != "/lp1" &&
            location.pathname != "/lp3" &&
            location.pathname != "/lp3",
          location.pathname != "/verify") && (
            <section class="py-[20px] bg-white">
              <div class="custom-container md:flex justify-between items-center">
                <div>
                  <p class=" text-sm text-[#241C24] flex space-x-3 mb-2 md:mb-0">
                    <Link to={"/terms-condition"} class="block underline">
                      Terms and Conditions
                    </Link>{" "}
                    <Link to={"/privacy"} class="block underline">
                      Privacy Policy
                    </Link>{" "}
                    <Link to={"/cookie"} class="block underline">
                      Cookie Policy
                    </Link>{" "}
                    <Link to={"/FAQ"} class="block underline">
                      FAQ
                    </Link>
                  </p>
                  <p class=" text-sm text-[#241C24] space-x-3 mb-2 md:mb-0">
                    Copyright © 2024{" "}
                    <span className="text-nowrap">
                      InfiniteGroove Limited/onehubplay.com.
                    </span>{" "}
                    All Rights Reserved.
                  </p>
                  <p class=" text-sm text-[#241C24] flex space-x-3 mb-2 md:mb-0">
                    Pipinou, 6, 2320, Nicosia, Cyprus.
                  </p>
                  <p class=" text-sm text-[#241C24] flex space-x-3 mb-2 md:mb-0">
                    <a
                      href="mailto:support@onehubplay.com"
                      className=" underline"
                    >
                      support@onehubplay.com
                    </a>
                  </p>
                </div>
                <div>
                  <img width="150" src="/assets/Images/logo.png" />
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default Footer;
