import React, { useState, useEffect } from "react";
import { baseUrl } from "../Utils/app";
import toast from "react-hot-toast";
import axios from "axios";
import { db } from "../Utils/firebase";
import { useAuth } from "../AuthContext";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const { state } = useAuth();
  const [searchParams] = useSearchParams();
  const [path, setPath] = useState();
  const [formControls, setFormControls] = useState({
    password: "",
    password_confirmation: "",
    token: "",
    email: "",
  });

  const [loading, setLoading] = useState(false);
  let loadingToast;

  const navigate = useNavigate();

  useEffect(() => {
    let token = searchParams.get("token");
    let email = searchParams.get("email");
    if (token && email) {
      console.log(token, email);
      setFormControls({ ...formControls, email: email, token: token });
      setPath(`${baseUrl}/api/password/reset`);
    } else {
      setFormControls({ ...formControls, email: state?.user?.email });
      setPath(`${baseUrl}/api/reset-password`);
    }
  }, [searchParams, state]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(path);
    if (formControls?.password != formControls?.password_confirmation) {
      setLoading(false);
      return toast.error("Passwords do not match!");
    }
    console.log(state?.user);
    let postData = {
      ...formControls,
    };
    setLoading(true);
    await axios
      .post(path, postData, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.dismiss(loadingToast);
        setFormControls({
          password: "",
          password_confirmation: "",
          token: "",
          email: "",
        });
        toast.success("Password Reset Successfully");
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  return (
    <div className=" h-screen bg-[#BFBEBE] flex items-center justify-center">
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="w-[500px] bg-white rounded-lg p-[50px]"
      >
        <h1 className="mb-[20px] text-gray-500 text-3xl text-center font-semibold">
          {" "}
          Reset Password
        </h1>
        <div className="mb-[20px]">
          <label className="mb-[5px] text-gray-500 block font-semibold">
            New Password
          </label>
          <input
            type="password"
            required
            value={formControls.password}
            onChange={(e) =>
              setFormControls({ ...formControls, password: e.target.value })
            }
            className="p-2 border border-gray-500 block rounded-md w-full mb-[10px]"
          />
        </div>
        <div className="mb-[20px]">
          <label className="mb-[5px] text-gray-500 block font-semibold">
            Retype Password
          </label>
          <input
            type="password"
            required
            value={formControls.password_confirmation}
            onChange={(e) =>
              setFormControls({
                ...formControls,
                password_confirmation: e.target.value,
              })
            }
            className="p-2 border border-gray-500 block rounded-md w-full mb-[10px]"
          />
        </div>
        {!loading && (
          <button
            type="submit"
            className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase "
          >
            Submit
          </button>
        )}
        {loading && (
          <button
            type="button"
            className=" bg-[#18AD58] px-[50px] py-[10px] text-2xl rounded-full text-white font-bold uppercase block mx-auto"
          >
            <img width={25} src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
          </button>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
