  import React, { useState, useEffect } from "react";
  import axios from "axios";
  import toast from "react-hot-toast";
  import { baseUrl } from "../Utils/app";

  const TC = () => {
    let loadingToast;
    const [loading, setLoading] = useState(true);
    const [terms, setTerms] = useState(null);
    const [privacy, setPrivacy] = useState({});
    const [cookie, setCookie] = useState({});

    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use 'auto' for an instant scroll
        });
      };
      scrollToTop();
    }, []);

    useEffect(() => {
      loadingToast = toast.loading("Loading");

      const getItems = async () => {
        axios.defaults.withCredentials = true;
        return await axios.get(`${baseUrl}/api/getPolicy`);
      };

      getItems()
        .then((resp) => {
          setTerms(resp?.data?.data?.filter((item) => item?.type == "t&c")[0]);
          setPrivacy(
            resp?.data?.data?.filter((item) => item?.type == "privacy")[0],
          );
          setCookie(
            resp?.data?.data?.filter((item) => item?.type == "cookie")[0],
          );
          toast.dismiss(loadingToast);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });
    }, []);

    return (
      <>
        <section
          className="min-h-screen bg-[#241C24] bg-cover pb-[100px]"
          style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
        >
          <div className="custom-container ">
            <div className="pt-[150px]">
              <h1 className="uppercase text-3xl font-bold text-white text-center mb-[40px]">
                ONE HUB PLAY SUBSCRIBER AGREEMENT
              </h1>
              <p className="text-white text-lg text-center font-semibold  mb-7">
                Introduction
              </p>
              <p className="text-white text-xs font-semibold text-justify mb-7">
                {" "}
                This Subscriber Agreement (hereinafter the “Agreement”) is entered
                into by and between You, a natural person identified upon
                registration (hereinafter referred to as “You”) and InfiniteGroove
                Limited, a private limited liability company organised and
                existent under the laws of the Republic of Cyprus, enrolled with
                the Registrar of Companies under number HE 454437 and having its
                registered office at Pipinou, 6, 2320, Nicosia, Cyprus
                (hereinafter referred to as “We”, “Us” or the “Company”, as the
                case may be), sole owner of OneHubPlay™ and proprietor of the
                systems, trademarks, domain and platform used in connection with
                the provision of digital content (hereinafter, with all
                OneHubPlay™’s websites, mobile applications, and other digital
                and interactive services that link to this Agreement, collectively
                referred to as the “OneHubPlay Service”), and governs your access
                to and use of such service.
              </p>
              <p className="text-white text-xs font-semibold text-justify mb-7">
                This Agreement constitutes a legal agreement made and entered into
                by and between You and the Company and has the purpose to set
                forth the terms and conditions that shall govern the Company’s
                rendering of, as well as your access to and use of the OneHubPlay
                Service.
              </p>
              <p className="text-white text-xs font-semibold text-justify mb-7">
                By registering for the OneHubPlay Service, You, on your own
                account, as well as by your successors and assignees, acknowledge
                and represent to have read, understood and expressly consign to be
                bound by and uphold this Agreement. Notwithstanding the foregoing,
                You hereby grant the Company your full and unconstrained consent,
                whilst using the OneHubPlay Service, to be subjected to and remain
                compliant with all applicable Company’s policies, as it may enact
                and amend from time to time, whether or not incorporated hereto by
                reference or otherwise, especially, but not limited to, the
                Company’s Privacy Policy – which describes how We collect, use and
                disclose your data and your consent to such collection, use and
                disclosure – (hereinafter collectively and indistinctly referred
                to as “Policies”).
              </p>
              <p className="text-white text-xs font-semibold text-justify mb-7">
                Should You object to, reject or otherwise have any reservations
                whatsoever regarding any of the terms in this Agreement, You must
                refrain from accessing or using the OneHubPlay Service. Any access
                to or use of the OneHubPlay Service under such circumstances shall
                be construed as an unauthorised access or use, treated as strictly
                prohibited according to the terms herein. In the event You may
                have previously entered into any written agreement with the
                Company, that either incorporates this Agreement by reference
                therein or govern over the same subject matter, unless explicitly
                stated thereunder, this Agreement is to be deemed likewise
                enforceable and binding upon You, whether in the event of express
                consent by You or the lack thereof
              </p>
              <p className="text-white text-xs text-justify mb-7 font-light">
                <em>
                  * Additional terms may apply to your use of the OneHubPlay
                  Service. We will provide any such additional terms to You or
                  post them on the OneHubPlay Service in connection with the
                  features to which they may apply; notwithstanding, all such
                  additional terms are incorporated herein by reference.
                </em>
              </p>
              <p className="text-white text-xs text-justify mb-7 font-light">
                <em>
                  *“You” and “We”, “Us” or “Company” hereinafter jointly referred
                  to as “Parties”, and individually, each as a “Party”.
                </em>
              </p>

              <h4 className=" text-white text-base text-justify mb-4 font-medium">
                List of Sections:
              </h4>

              <ul className="text-white text-xs mb-6">
                <li>- Eligibility and Registration;</li>
                <li>- Subscription;</li>
                <li>- Payment and Billing Terms;</li>
                <li>- Amendments;</li>
                <li>- Termination and Suspension;</li>
                <li>- Usage Terms;</li>
                <li>- Proprietary Rights;</li>
                <li>- Warranties and Representations;</li>
                <li>- Form of Notice;</li>
                <li>- Limitation of Liability;</li>
                <li>- Indemnity;</li>
                <li>- Governing Law and Dispute Resolution;</li>
                <li>- General Terms.</li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                1. Eligibility and Registration
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  1.1 In order to register for and use the OneHubPlay Service, You
                  must be and You hereby represent and warrant to be eighteen (18)
                  years of age. By using the OneHubPlay Service on behalf of any
                  third party, You are herein representing to be an authorised
                  representative of that third party and that your use of the
                  OneHubPlay Service constitutes that third party’s acceptance of
                  this Agreement. If You have been previously prohibited from
                  accessing the OneHubPlay Service by Us, You are not permitted to
                  access the OneHubPlay Service.
                </li>
                <li>
                  1.2 Only individuals that register for a OneHubPlay Service
                  account and agree to the OneHubPlay Subscriber Agreement are
                  eligible to use the OneHubPlay Service.
                  <ul className="text-white space-y-4 text-xs my-4 pl-4">
                    <li>
                      1.2.1 During registration, We may require information from
                      You (“Registration Data”), to create your account. Failure
                      to provide all Registration Data in an accurate and truthful
                      manner may prevent your access to the OneHubPlay Service.
                      Failure to maintain the Registration Data updated, accurate
                      and complete, shall be deemed a breach of this Agreement,
                      which may result, at our discretion, in the suspension or
                      termination of your access to the OneHubPlay Service.
                      Subject to our Privacy Policy – available at
                      https://www.onehubplay.com/privacy –, You hereby expressly
                      authorise Us to use and reuse all Registration Data
                      including, without limitation, personally identifiable
                      information. We shall use commercially reasonable efforts to
                      protect and safeguard the Registration Data in accordance
                      with the applicable laws, rules and regulations relating to
                      the processing and use of personal data. You may edit or
                      update your personally identifiable Registration Data at any
                      time through the OneHubPlay Service by visiting your profile
                      page.
                    </li>
                    <li>
                      1.2.2 In order to create an account to use the OneHubPlay
                      Service, You shall be requested, during the registration
                      process, to provide a username and password. The password
                      You provide, which must be secure and unique, will be
                      required to further access your account and the OneHubPlay
                      Service. You are solely responsible for protecting and
                      safeguarding the provided username and password from
                      unwarranted disclosure or unauthorised use, and You will be
                      held fully liable for all activities that occur under your
                      username and password.
                    </li>
                    <li>
                      1.2.3 You undertake to keep Us informed, at all times, of
                      any event of unauthorised use of your username or password,
                      whether suspected or material, or otherwise breach of
                      security related to your password or the OneHubPlay Service.
                      We shall not be held liable for any loss or damage resulting
                      from your failure to protect and safeguard your account from
                      unauthorised or unwarranted access or use, or for your
                      failure to maintain the password confidential and secure. By
                      completing your account registration, You agree that You
                      shall prevent others from using your account and to be held
                      fully responsible for all activities performed under your
                      username and password. Any and all communications We receive
                      under your account shall be deemed to have been made by You.
                      Under no circumstance shall We be held liable for any loss,
                      damages, liability, expenses or attorneys’ fees that You may
                      incur as a result of someone else using your username or
                      password, either with or without your knowledge, and
                      regardless of whether We should expect such unauthorised
                      use. You shall be held liable for losses, damages,
                      liability, expenses and attorneys’ fees incurred by Us or an
                      eventual third party due to someone else using your account.
                    </li>
                  </ul>
                </li>
                <li>
                  1.3 Subject to your compliance with this Agreement, We grant You
                  a limited, non-exclusive, non-sublicensable, non-transferable
                  and revocable right to access and use the OneHubPlay Service for
                  your own personal benefit, provided that such use is strictly
                  non-commercial in nature.
                </li>
                <li>
                  1.4 We reserve the right, at our sole discretion, to deny your
                  registration. Should your registration be denied, any and all
                  access to or use of the OneHubPlay Service by You shall be
                  construed as an unauthorised access or use.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                2. Subscription
              </h4>
              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  2.1 <b>General.</b> Barring the possibility of being granted a trial version as per the below, your access to and use of the OneHubPlay Service is subscription-based and thus subject to an ongoing/recurring payment plan (the “Paid Subscription”).
                </li>
                <li className="">
                  2.2 <b>Free Trial and Paid Subscription.</b> We offer an optional free trial version of our Paid Subscription (the “Free Trial”). A Free Trial is only available to those who have not previously used the OneHubPlay Service. If You do not cancel your Free Trial before the end of the stipulated Free Trial period in accordance with the instructions for cancellation below, the Paid Subscription will thereafter automatically commence, and a first payment will be charged to your preferred method of payment indicated at the time of registration. Your Paid Subscription will be automatically renewed on a monthly basis and payment will be charged accordingly at the beginning of each similar period, unless cancelled in accordance with the instructions for cancellation below.
                </li>
                <li className="">
                  2.3 <b>Cancellation.</b>  If the OneHubPlay Service is not to your liking or find that the service isn't for You, You can cancel the Free Trial or Paid Subscription, as the case may be, for free by visiting your account section and modifying the membership settings accordingly or by contacting Us on support@onehubplay.com. You can cancel the Paid Subscription at any time; cancelling stops the ongoing/recurring payment plan. If You cancel the Paid Subscription, payments are not refunded retroactively, and previously charged subscription fees are not prorated based on the cancellation date. Cancellation will only take place at the end of the current billing period or at the end of the Free Trial, if any, and until that time You may still access or use the OneHubPlay Service.
                </li>

                <li className="">
                  2.4 <b>Right of Withdrawal.</b> If You are located in a European Union Member State or laws of a European Union Member State apply, You shall have a period of 14 days, starting from the date of your registration to the OneHubPlay Service, to withdraw from this Agreement. You can do so by either using the model withdrawal form as set out in Annex A to this Agreement or any other unequivocal statement clearly expressing your intent to withdraw from the Agreement, and sending the same to support@onehubplay.com or the postal address mentioned above. In the event of withdrawal from the Agreement, You are prevented from any further access to or use of the OneHubPlay Service, and to this end We reserve the right to make the OneHubPlay Service inaccessible to You or disable your OneHubPlay Service account, as the case may be. You expressly consent to Us providing You with the OneHubPlay Service under the Paid Subscription immediately after the end of the Free Trial or, if no Free Trial applies, immediately following your purchase of a Paid Subscription, that You lose your right of withdrawal, and authorise Us to charge You automatically on a monthly basis until You cancel.

                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                3. Payment and Billing Terms
              </h4>
              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  3.1 <b>​Payment Details.</b> Any payment(s) in connection with
                  this Agreement will be charged to the payment method provided by
                  You upon registration. If You decide to restart your Paid
                  Subscription following cancellation or termination thereof, You
                  agree that We may charge the payment method We have on file for
                  You.
                </li>
                <li>
                  3.2 <b>Failed Payment. </b> If payment for the Paid Subscription
                  cannot be successfully processed upon its commencement or
                  renewal, your access to and use of the OneHubPlay Service will
                  be suspended temporarily until the payment is processed. You
                  acknowledge and agree that We reserve the right to charge an
                  administrative fee for each attempt to retry processing the
                  payment. You will be notified of the failed payment and the
                  applicable administrative fee, and both amounts must be settled
                  in order for You to regain access to and use of the OneHubPlay
                  Service.
                </li>
                <li className="">
                  3.3 <b>Taxes.</b> The Paid Subscription may be subject to tax in
                  accordance with domestic tax legislation. Unless otherwise
                  provided, any applicable VAT or GST tax is included in the
                  advertised price
                </li>
                <li className="">
                  3.4 <b>​Price Changes.</b> We reserve the right to change the price of the Paid Subscription. In the event of a price change, We will inform You in advance of such change by means of posting it on the website or sending You an e-mail to the e-mail address provided through the registration process. The price change will take effect at the end of your next billing period or Free Trial, as the case may be, and You authorise Us to charge your payment method at the new price accordingly. If You do not accept such change, You may cancel your Paid Subscription or Free Trial in accordance with the instructions for cancellation hereinabove.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                4. Amendments
              </h4>
              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  4.1 We reserve the right, at our sole discretion, to update,
                  amend or otherwise affect any changes to this Agreement, the
                  Policies and the OneHubPlay Service, from time to time, without
                  notice in advance (to the extent permissible at law), including,
                  however not limited to, features, specifications, capabilities,
                  functions, licensing terms and general availability. If We make
                  changes to the Agreement that may affect the ongoing contractual
                  relationship between You and Us, We undertake to keep You duly
                  informed with reasonable notice by means of posting it on the
                  website or sending You e-mails to your e-mail address provided
                  through the registration process. By continuing to access or use
                  the OneHubPlay Service, after an update, amendment or otherwise
                  change hereof takes effect, regardless of any objections or
                  reservations You may have raised, whether or not timely, written
                  or otherwise, You explicitly agree and grant your full and
                  unreserved consent to become subjected to and bound by those
                  alterations, waiving any right to enjoy or claim, judicially or
                  otherwise, any compensation or indemnification arising therefrom
                  or in connection thereto. Other than as provided in Section 3.4
                  of this Agreement, any changes hereto shall take effect
                  immediately on the effective date stipulated therein and will
                  apply on a going-forward basis, unless otherwise instructed in
                  the respective notification to You. If You oppose to, reject or
                  have otherwise any reservations whatsoever regarding an eventual
                  alteration under this Section, You undertake herein to refrain
                  from accessing or using the OneHubPlay Service during any time
                  following that alteration’s effective date. Any access to or use
                  of the OneHubPlay Service under such circumstances shall be
                  construed as an unauthorised access or use, treated as strictly
                  prohibited according to the terms herein.
                </li>
                <li className="">
                  4.2 Without prejudice to the foregoing, should You choose to
                  raise any objections or reservations pursuant to an amendment,
                  update or otherwise alteration in accordance with the foregoing,
                  You may do so within the fifteen (15) days immediately following
                  that alteration’s effective date, provided that You cease and
                  refrain from all further access to and use of the OneHubPlay
                  Service as of the date when such objection or reservation is
                  raised until your receipt of our written reply thereof.
                </li>
                <li className="">
                  4.3 In the event You continue to or otherwise resume with the
                  use of the OneHubPlay Service after raising an objection or
                  reservation, and prior to receiving our written reply, You shall
                  then be deemed to have expressly agreed with and granted your
                  full and unconstrained consent to becoming subject to and bound
                  by those alterations, having waived any right to enjoy or claim,
                  judicially or otherwise, any compensation or indemnification
                  thereof, in accordance with that provided hereinabove.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                5. Termination and Suspension
              </h4>
              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  5.1 This Agreement is made effective as of the date of your registration on the OneHubPlay Service and shall remain so until the Free Trial or Paid Subscription is cancelled in accordance with Section 2 above or terminated by the Parties in accordance with that provided hereunder.

                </li>
                <li className="">
                  5.2 We may immediately terminate this Agreement and/or suspend
                  your access to the OneHubPlay Service:
                  <ul className="text-white space-y-7 text-xs mb-4 pl-4 mt-4">
                    <li className="">
                      (a) should You fail to comply with any provision under this
                      Agreement or use the OneHubPlay Service in any manner other
                      than for its intended purpose;
                    </li>
                    <li className="">
                      (b) for any conduct that We may reasonably deem to be
                      directly or indirectly harmful to others;
                    </li>
                    <li className="">
                      (c) for any conduct that We may reasonably deem to be a
                      violation of third-party rights such as copyright, trademark
                      and rights of privacy and publicity;
                    </li>
                    <li className="">
                      (d) should You interfere with the proper functionality of
                      the OneHubPlay Service, in whole or in part; or
                    </li>
                    <li className="">
                      (e) for any conduct that violates any local, state, federal,
                      or foreign laws or regulations.
                    </li>
                  </ul>
                </li>
                <li className="">
                  5.3 Without prejudice to the foregoing, We may, in our sole and
                  absolute discretion, temporarily or permanently deny You access
                  to all or part of the OneHubPlay Service, at any given time,
                  regardless of reason or notice to You (to the extent permissible
                  at law), which may result from, however not limited to, (i) our
                  obligation to comply with applicable law and requests by law
                  enforcement or government agencies; (ii) discontinuance or
                  material modification of the OneHubPlay Service (or any part
                  thereof); (iii) unexpected technical or security issues or
                  problems; (iv) activities related to protecting the rights,
                  property or safety of users of the OneHubPlay Service and the
                  public; or (v) You providing Us with information, including
                  Registration Data, that is false, inaccurate, out-of-date, or
                  incomplete. If You are permanently denied access to all of the
                  OneHubPlay Service, the Agreement shall be deemed to have been
                  terminated accordingly.
                </li>
                <li className="">
                  5.4 You may terminate the Agreement, other than a cancellation of your Free Trial or Paid Subscription in accordance with Section 2.3, by contacting Us on support@onehubplay.com. Should You terminate the Agreement, You shall remain liable under the terms found herein for any Free Trial or Paid Subscription occurring prior to such termination. Upon termination of the Agreement at your request, You will immediately lose the right to access or use the OneHubPlay Service.    

                </li>
                <li className="">
                  5.5 Without prejudice to any of the above and unless explicitly
                  stated, all covenants, agreements, representations, warranties
                  and undertakings that are reasonably required, shall survive the
                  termination of the Agreement, however so arising, until their
                  complete or otherwise sufficient fulfilment, or upon due and
                  lawful release of such.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                6. Usage Terms
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  6.1 The use of the OneHubPlay Service and/or the availability of
                  certain Content is limited to specific locations due to
                  technical and other constraints imposed by content providers.
                  You are prohibited from employing any technology or method to
                  hide or obscure your location.
                </li>
                <li>
                  6.2 The OneHubPlay Service is designed to be accessible on all
                  devices and browsers. While We strive to provide a seamless
                  experience, We cannot guarantee compatibility with all devices
                  and browsers.
                </li>
                <li>
                  6.3 Concurrent streaming, defined as the simultaneous access and
                  playback of Content on multiple devices or sessions under the
                  same account, is strictly prohibited. We reserve the right to
                  monitor your account activity and employ measures to detect and
                  prevent concurrent streaming.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                7. Proprietary Rights
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  {" "}
                  <b className="font-bold">
                    7.1 Intellectual Property Rights.
                  </b>{" "}
                  We and/or our affiliates own and hold all rights, title and
                  interest in and to the OneHubPlay Service as well as to any
                  digital content made available to You through the OneHubPlay
                  Service (the “Content”), whether in whole or in part
                  (collectively, our “Proprietary Rights”). Our Proprietary Rights
                  are protected under the U.S. Copyright Act, as well as under
                  international treaty provisions, trademarks, service marks
                  copyright, trade secret, patent and other applicable domestic
                  and international laws. You acknowledge that the OneHubPlay
                  Service has been developed, compiled, prepared, revised,
                  selected, and arranged by Us and others through the application
                  of methods and standards of judgment developed and applied
                  through the employment of substantial time, effort and money,
                  and that any and all intellectual property and trademark rights
                  stemming therefrom are owned by, and any goodwill associated
                  with the use of such shall inure to the benefit of, the Company.
                  You agree to protect ours and other relevant third parties’
                  Proprietary Rights during and after this Agreement’s term and to
                  comply with all reasonable written requests made by Us, our
                  suppliers and licensors of content (collectively referred to
                  herein as “Suppliers”), or otherwise to protect their and our
                  contractual, statutory, and common law rights in the OneHubPlay
                  Service and/or the Content. Under no circumstance shall the
                  OneHubPlay Service and the Content be deemed sold or assigned to
                  You. Any and all rights not expressly granted by Us are reserved
                  to the Company and its affiliates or Suppliers, as the case may
                  be. You agree to abide by all applicable copyright laws,
                  treaties and regulations, as well as any additional copyright
                  notices or restrictions contained in the OneHubPlay Service. You
                  agree to promptly inform Us whenever becoming aware of any
                  unauthorised access or use of the OneHubPlay Service by any
                  individual or entity or of any claim that the OneHubPlay Service
                  infringes upon any copyright, trademark, or other contractual,
                  statutory, or common law rights. All present and future rights
                  in and to trade secrets, patents, copyrights, trademarks,
                  service marks, know-how, and other proprietary rights of any
                  type under the laws of any governmental authority, domestic or
                  foreign, including without limitation, rights in and to all
                  applications and registrations relating to the OneHubPlay
                  Service shall, as between You and Us at all times, be and remain
                  the sole and exclusive property of the Company.
                </li>
                <li>
                  7.2 <b>Copyrights and Trademarks.</b> You may not use any of our
                  trademarks, trade names, service marks, copyrights, or logos in
                  any manner which creates the impression that such items belong
                  to or are associated with You or, except as otherwise provided
                  herein, are used with our consent, and You acknowledge that You
                  have no ownership rights in or to any such items.
                </li>
                <li>
                  7.3{" "}
                  <span>
                    WE ARE UNDER NO OBLIGATION TO ACCEPT UNSOLICITED FEEDBACK.
                  </span>{" "}
                  However, should You provide any feedback about the OneHubPlay
                  Service to Us, We shall own all rights in and to such feedback
                  and any derivative technologies and compilations based on or
                  developed through or by using such feedback. You hereby
                  undertake to perform all reasonably necessary actions to affect
                  the Company’s rights in and to such effect hereof.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                8. Warranties and Representations
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  8.1 You expressly understand and agree that:
                  <ul className="text-white space-y-7 text-xs mb-4 pl-4 mt-4">
                    <li className="">
                      (a) should You fail to comply with any provision under this
                      Agreement or use the OneHubPlay Service in any manner other
                      than for its intended purpose;(a) Your use of the OneHubPlay
                      Service is at your sole risk. We make no representation,
                      warranty, or guarantee of the OneHubPlay Service’s
                      suitability for your purposes or the Content, nor that the
                      access to and use of the OneHubPlay Service shall be secure,
                      uninterrupted or error-free, or that the OneHubPlay Service
                      shall function properly in combination with any third-party
                      technology, hardware, software, systems or data. The
                      OneHubPlay Service and the Content are provided “as is” and
                      all conditions, representations and warranties, whether
                      express, implied, statutory or otherwise, including, without
                      limitation, any implied warranty of merchantability, title,
                      fitness for a particular purpose, or non-infringement are
                      hereby disclaimed to the maximum extent permitted under
                      applicable law. The OneHubPlay Service may be subject to
                      interruption, limitations, delays, and other problems
                      inherent in the use of internet applications and electronic
                      communications, and We are not responsible for any such
                      delays, mis-delivery, untimely delivery, delivery failures,
                      or any other damage resulting from events beyond our
                      reasonable control. You further understand and agree that
                      the Content or other material downloaded, obtained or
                      otherwise accessed through the use of the OneHubPlay Service
                      is done at your own discretion and risk, and that You are
                      solely responsible for any damages to your computer system,
                      mobile device or other technological devices, or loss of
                      data that results when downloading, obtaining or otherwise
                      accessing such content or other related material.
                    </li>
                    <li className="">
                      (b) No advice or information, whether oral or written,
                      obtained by You from Us or through or from the OneHubPlay
                      Service shall create any warranty.
                    </li>
                    <li className="">
                      (c) We make no representation, warranty, guarantee or
                      promise that the OneHubPlay Service or the Content will meet
                      your requirements or achieve any particular results.
                    </li>
                  </ul>
                </li>
                <li></li>
                <li className="">
                  <p className="mb-4">
                    {" "}
                    8.2 By using the OneHubPlay Service, You agree to be exposed
                    to, interact or otherwise be provided with (i) information and
                    content provided by third parties; (ii) links to third-party
                    websites or resources, such as sellers of goods and services;
                    and (iii) third-party products and services for sale directly
                    to You (hereinafter, collectively “Partner Content”). Under
                    any such circumstances, such Partner Content may contain
                    affiliate marketing links encoded by third-party partners,
                    through which actions executed by You, i.e., clicking on or
                    making final purchases via that Partner Content, may generate
                    revenue to the Company as monetary commission. You further
                    acknowledge and consign to have such Partner Content utilising
                    cookies for the purpose of assessing your use of the
                    OneHubPlay Service.
                  </p>
                  <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                    <li className="">
                      8.2.1 Notwithstanding, under no circumstance shall We be
                      held responsible for the availability of such Partner
                      Content and We do not endorse nor shall We be held liable or
                      undertake any responsibility for (i) any content,
                      advertising, products, or other material on or available
                      from Partner Content; (ii) any errors or omissions in
                      Partner Content; or (iii) any information handling practices
                      or other business practices of the operators/owners of
                      Partner Content. You further acknowledge and agree that We
                      shall not be responsible or liable, directly or indirectly,
                      for any damage or loss caused or alleged to be caused by or
                      in connection with the use of or reliance on any Partner
                      Content. Any commercial transactions or interactions with
                      third parties discovered on or through the OneHubPlay
                      Service are solely between You and the respective third
                      party, and such shall be governed by the relevant third
                      parties’ own terms of service and privacy policies.
                    </li>
                  </ul>
                </li>
                <li>
                  8.3 You further warrant to currently be, and undertake to
                  remain, in compliance with all applicable laws and regulations,
                  including without limitation, laws and regulations governing
                  privacy and data protection.
                </li>
                <li>
                  8.4 <b>Use Outside the Republic of Cyprus.</b> The OneHubPlay
                  Service is controlled and offered by Us, from the Republic of
                  Cyprus. We make no representations that the OneHubPlay Service
                  is appropriate for use in other locations. Those who access or
                  use the OneHubPlay Service from other locations do so at their
                  own risk and are responsible for compliance with local law.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                9. Form of Notice
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  {" "}
                  9.1 Any notice to be sent by Us to You under this Agreement may
                  be done so by means of e-mail – at the address furnished on
                  registration with your Registration Data – posting a notice
                  through the OneHubPlay Service or on the website, and shall be
                  deemed duly served upon dispatch.
                </li>
                <li>
                  9.2 Should any notice be sent by You under this Agreement, such
                  notice shall be deemed duly served upon receipt, at the
                  addresses designated on the OneHubPlay Service, the website, or
                  otherwise at the address listed hereinbelow.
                </li>
                <li>
                  9.3 Notwithstanding, should You require any further
                  clarification regarding this Agreement, You may contact Us at
                  the e-mail address support@onehubplay.com, or physical address
                  at InfiniteGroove Limited, Pipinou, 6, 2320, Nicosia, Cyprus.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                10. Limitation of Liability
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  10.1 We hereby disclaim any liability, and You expressly
                  understand and agree that, under no circumstances may the
                  Company, or its affiliates, be deemed liable for your or any
                  user’s use or misuse of or reliance on the OneHubPlay Service or
                  any Content. To the extent permitted by law, the Company shall
                  in no event be deemed liable for any consequential, incidental,
                  direct, indirect, special, punitive, or other damages
                  whatsoever, including, without limitation, damages for loss of
                  business profits, business interruption, loss of business
                  information, or other loss of pecuniary nature, arising out of
                  or in connection with this Agreement or the use of or inability
                  to access or use the OneHubPlay Service, irrespective of the
                  Company’s eventual prior knowledge of the possibility of such
                  damages, whether in action, contract, tort, strict liability,
                  violation of statute or otherwise.
                </li>
                <li>
                  10.2 Without prejudice to the foregoing, your exclusive remedy
                  and the Company’s total aggregate liability relating to, arising
                  out of, in connection with, or incidental to the OneHubPlay
                  Service or this Agreement, regardless of cause or reason, shall
                  be restricted to the actual direct damages incurred by You, up
                  to the amount of subscription fees paid by You directly to the
                  Company in consideration for use of the OneHubPlay Service
                  during the twelve (12) month period preceding the event giving
                  rise to the action or claim. The limitation set forth herein
                  shall apply on all actions or claims, in aggregate, irrespective
                  of cause of action. Notwithstanding, that provided herein shall
                  not be construed or operate as a waiver of, or otherwise limit,
                  the Company’s ability to obtain injunctive or other equitable
                  relief for breach of this Agreement.
                </li>
                <li>
                  10.3 Such limitation shall also apply with respect to damages
                  incurred by reason of other services or goods received through
                  or advertised on the OneHubPlay Service or received through any
                  links provided in the OneHubPlay Service, as well as by reason
                  of any information or advice received through or advertised on
                  the OneHubPlay Service or received through any links provided in
                  or through the OneHubPlay Service. We do not warrant, endorse,
                  guarantee or assume the responsibility for any product or
                  service advertised or offered by a third party through the
                  OneHubPlay Service or any website featured or linked to or
                  through the OneHubPlay Service, and We will not be a party to,
                  or in any way be responsible for monitoring, any transaction
                  between You and third-party providers of products or services.
                  We shall not be deemed liable for the offensive or illegal
                  conduct of any third party. You voluntarily assume the risk of
                  harm or damage arising from the foregoing. The foregoing
                  limitations shall apply regardless of eventual failure of a
                  remedy in its essential purpose and to the fullest extent
                  permitted by law.{" "}
                </li>
                <li>
                  10.4 If You are a California resident, You hereby waive §1542 of
                  the Civil Code of California, which says: “A general release
                  does not extend to claims that the creditor or releasing party
                  does not know or suspect to exist in his or her favor at the
                  time of executing the release and that, if known by him or her,
                  would have materially affected his or her settlement with the
                  debtor or released party.” This release includes the criminal
                  acts of others.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                11. Indemnity
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  11.1 You agree to indemnify, defend, keep and hold the Company
                  and its affiliates, officers, directors, employees, members,
                  shareholders and representatives (and all successors and
                  assignees of any of the foregoing), harmless and indemnified
                  from and against any claim or demand, including without
                  limitation, reasonable attorneys’ fees, litigation expenses,
                  burden of loss, and any disbursements made by any third party in
                  connection with or arising out of your use of the OneHubPlay
                  Service, your connection to the OneHubPlay Service, your
                  violation of this Agreement or the Policies, your violation of
                  any applicable law including, without limitation, any claims,
                  lawsuits or proceedings for libel, slander, copyright and
                  trademark violation and your violation of any rights of other
                  persons. We reserve the right, at our own expense, to assume the
                  exclusive defence and control of such disputes and, in any
                  event, You undertake to cooperate with Us in asserting any
                  available defences.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                12. Governing Law and Dispute Resolution
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  12.1 This Agreement is subject to the laws of the Republic of
                  Cyprus. Disputes arising under or in connection with this
                  Agreement shall be settled by negotiation between the Parties.
                  If any dispute arising under or in connection with this
                  Agreement cannot be settled by negotiation between the Parties,
                  the dispute shall be settled by Arbitration.
                </li>
              </ul>

              <h4 className=" text-white text-lg text-justify mb-4 font-medium pb-1 border-b border-white">
                13. General Terms
              </h4>

              <ul className="text-white space-y-4 text-xs mb-4">
                <li className="">
                  13.1 <b>Force Majeure.</b> Under no circumstances We or our
                  Suppliers, may be held liable for failure to perform, or delay
                  in performing, any of the obligations contracted under this
                  Agreement, to the extent that such failure or delay is due to an
                  event of force majeure – namely an event arising from cause or
                  causes beyond our reasonable control, which by its nature could
                  not have been foreseen or if it could have been foreseen, was
                  unavoidable including, without limitation, acts of God, act of
                  governmental sovereignty, war or armed hostilities, fire, flood,
                  explosion, civil commotion, industrial dispute of a third party,
                  act of terrorism, revolution, blockade, embargo, strike,
                  lock-out, sit-in, industrial or trade dispute, adverse weather,
                  disease, accident to or breakdown of facilities, shortage of any
                  material, labour, transport, electricity or other supply, or
                  regulatory intervention.
                </li>
                <li>
                  13.2 <b>Forbearance.</b> No failure, whether by Us or by You, to
                  enforce any right or provision under this Agreement shall be
                  construed as or constitute a waiver of such right or provision.
                </li>
                <li>
                  13.3 <b>Severability.</b> If any provision under this Agreement
                  is held unenforceable for any reason, such provision shall be
                  reformed only to the extent necessary to make it enforceable and
                  such decision shall not affect the enforceability of such
                  provision under other circumstances, or of the remaining
                  provisions hereof under any circumstances.
                </li>
                <li>
                  13.4 <b>Third-Party Beneficiaries.</b> You agree that, except as
                  otherwise expressly provided in this Agreement, there shall be
                  no third-party beneficiaries to this Agreement. You may not
                  transfer or assign this Agreement or any rights or obligations
                  hereto (to the extent permitted under applicable law), whether
                  directly or indirectly, by operation of law or otherwise,
                  without the Company’s prior written consent. We reserve the
                  right to unrestrictedly assign, sublicense, delegate or
                  otherwise transfer any of the rights or obligations under this
                  Agreement, whether in whole or in part, to any third party,
                  regardless of your prior written consent.
                </li>
                <li>
                  13.5 <b>Miscellaneous.</b> This Agreement (and all terms and
                  conditions incorporated herein) constitute the entire agreement
                  between You and the Company with respect to the subject matter
                  hereof and supersede any prior agreements between You and the
                  Company with respect to the said subject matter. No agency,
                  partnership, joint venture or employee-employer relationship is
                  intended or created by this Agreement. You agree that any
                  agreements made by and between You and the Company in electronic
                  form are as legally binding as if made in physical written form.
                  This Agreement will not be construed against the drafter. Unless
                  the express context otherwise requires (i) The words “hereof”,
                  “herein” and “hereunder” and words of similar import, when used
                  in this Agreement, shall refer to this Agreement as a whole and
                  not to any particular provision of this Agreement; (ii) The
                  terms defined in the singular shall have a comparable meaning
                  when used in the plural, and vice versa; (iii) References herein
                  to a specific Section, Subsection, Annex, Schedule or Exhibit
                  shall refer, respectively, to Sections, Subsections, Annexes,
                  Schedules and Exhibits of this Agreement; (iv) Wherever the word
                  “include”, “includes” or “including” is used in this Agreement,
                  it shall be deemed to be followed by the words “without
                  limitation”; (v) Wherever the word “promptly” or “as promptly as
                  practicable” is used in this Agreement, it shall mean without
                  undue delay; (vi) References herein to any gender include each
                  other gender; and (vii) Heading references in this Agreement and
                  the table of contents are for convenience purposes only and
                  shall not be deemed to limit or affect any of the provisions
                  hereof. If You are using the OneHubPlay Service for or on behalf
                  of the U.S. government, your license rights do not exceed those
                  granted to non-government consumers.
                </li>
              </ul>

              <p className="text-white text-xs text-justify mb-7 font-light">
                <em>** Last Updated On 26.08.2024. **.</em>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  };

  export default TC;