import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../Utils/app";

const FAQ = () => {
  let loadingToast;
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/getFaq`);
    };

    getItems()
      .then((resp) => {
        setFaqs(resp.data.data);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  return !loading ? (
    <section
      className="pb-[100px] bg-[#241C24] bg-cover"
      style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
    >
      <div className="custom-container ">
        <div className=" w-[80%] mx-auto pt-[150px]">
          <h1 className="uppercase text-3xl font-bold text-white text-left mb-[40px]">
            FREQUENTLY ASKED QUESTIONS
          </h1>

          {faqs?.map((item) => (
            <div key={item?.id} className="mb-5">
              <p className="text-white text-lg text-left font-bold">
                {item?.title}
              </p>
              <p className="text-white text-lg text-left ">
                {item?.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : (
    <section
      className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
      style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
    >
      <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
    </section>
  );
};

export default FAQ;
