
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { baseUrl } from "../Utils/app";
import { useAuth } from "../AuthContext";


const GamePlaceholder = () => {

  const gamePlayIdRef = useRef(null);
  const location = useLocation();  
  const { state, dispatch } = useAuth();
  const [gameLink, setGameLink] = useState();
  const [game, setGame] = useState(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  let loadingToast;
  const [remainingTime, setRemainingTime] = useState(10 * 60); // 10 minutes in seconds
  const navigate = useNavigate();

  useEffect(()=>{
    console.log(location)
    if(location?.state?.game)setGame(location?.state?.game);setLoading(false);
  },[location])

  useEffect(() => {
    let link = searchParams.get("game");

    // if (link) {
    //   setGameLink(link);
    // }
  }, [searchParams]);


  useEffect(() => {
    let id = searchParams.get("game");
    if(id && location?.state?.game?.id) return;
    loadingToast = toast.loading("Loading");

    const getItem = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/get-game/${id}`, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      } );
    };

    getItem()
      .then((resp) => {
        setGame(resp.data.data.game);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });

  }, [searchParams,location]);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(prevTime => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);
  
  useEffect(() => {
    if (remainingTime <= 0) {
      alert("Your free access has ended. Please register to continue playing.");
      navigate('/register');
    }
  }, [remainingTime, navigate]);
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsPart = seconds % 60;
    return `${minutes}:${secondsPart < 10 ? '0' : ''}${secondsPart}`;
  };

  return (
    <div>
      <section
        className=" bg-[#241C24] bg-cover mt-[-3] "
        style={{ backgroundImage: "url(/assets/Images/sec3bg.png)" }}
      >
        <div className="custom-container ">

        </div>

        {
          (!loading) ? (
            <div className="w-[100vw`] h-[100vh]">
              <iframe
                src={game?.url}
                className=" pt-[100px]"
                title="Onehubplay Payment"
                width='100%' height='100%' allowfullscreen frameborder='0'
              ></iframe>
            </div>
          ):(
              <section
                className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
                style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
              >
                <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
              </section>
            )
        }

      </section>

      <div className=" bg-[#241C24] absolute w-full h-full top-0 left-0 z-[10]">
        {
          (!loading) ? (
            <div className="w-[100vw`] h-[100vh]">
              <iframe
                src={game?.url}
                title="Onehubplay Payment"
                width='100%' height='100%' allowfullscreen frameborder='0'
              ></iframe>
            </div>
          ):(
              <section
                className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
                style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
              >
                <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
              </section>
            )
        }
      </div>
    </div>
  );
};

export default GamePlaceholder;
