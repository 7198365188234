import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { baseUrl } from "../Utils/app";
import toast from "react-hot-toast";
import axios from "axios";

const CancelSubscription = ({ setCancelModal }) => {
  const { state, dispatch } = useAuth();

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(null);
  const [unsubscribeLink , setUnsubscribeLink] = useState(null);
  let loadingToast;

  useEffect(() => {
    const getCard = async () => {
      await axios
        .get(`${baseUrl}/api/get-card`, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          setCard(response?.data?.data?.card);  
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    const getUnsubscribeLink = async () => {
      await axios
        .get(`${baseUrl}/api/cancel-rocketgate-rebilling/${state.user?.id}`, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          setUnsubscribeLink(response?.data?.data?.link);
          // setCard(response?.data?.data?.card);  
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    getCard();

    getUnsubscribeLink()
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  const cancelMembershipRequest = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseUrl}/api/cancel-membership`,
        {},
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        },
      )
      .then((response) => {
        console.log(response?.data);
        dispatch({ type: "LOGOUT" });
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  const getNextSubscription = (dateString) => {
    if (!dateString) return;
    // Convert the string to a Date object
    const date = new Date(dateString);

    // Add a month to the date
    date.setMonth(date.getMonth() + 1);

    // Format the new date as desired
    const newDateString = date.toISOString().split("T")[0]; // Get DD-MMMM-YYYY format

    return newDateString;
  };

  return (
    <div className=" bg-[#BFBEBE] flex items-center justify-center absolute w-full h-full top-0 left-0 bg-opacity-60">
      <div className="w-[500px] bg-white rounded-lg p-[50px]">
        {/* <h1 className='mb-[20px] text-[#817F80] text-[30px] text-center font-bold'>Billing Details</h1>
                <div className='border border-[#817F80] p-3 rounded-md  mb-5'>
                    <p className='text-[#817F80]'>{card?.card_name}</p>
                    <div className='flex gap-x-2 items-center'>  
                        <h1 className='font-bold text-2xl text-[#817F80]'>xxxx xxxx xxxx {card?.card_number}</h1>
                    </div>
                    <p className='font-medium text-[#817F80]'> Your next billing date is {getNextSubscription(card?.tr_date)}</p>
                </div> */}
        <h1 className="mb-[20px] text-[#817F80] text-[30px] text-center font-bold">
          Cancel your membership
        </h1>
        <p className="text-base text-[#817F80] font-semibold text-center">
          Hope to see you back soon!
        </p>

        {/* <p className="text-base text-[#817F80] font-semibold text-center">{unsubscribeLink}</p> */}
        <a
          href={unsubscribeLink}
          onClick={() => {
            // cancelMembershipRequest();
            // setCancelModal(false);
          }}
          className=" block bg-[#817F80] px-[40px] py-[10px] text-[16px] rounded-full mx-auto text-white font-semibold uppercase my-[20px] w-max text-center"
        >
          FINISH CANCELLATION
        </a>
        <button
          onClick={() => {
            setCancelModal(false);
          }}
          className=" block border-2 border-[#817F80] bg-white px-[40px] py-[10px] text-[16px] rounded-full mx-auto text-[#817F80] font-semibold uppercase my-[20px] "
        >
          Close
        </button>

        {/* <p className='text-base text-[#817F80] font-bold text-center'>How about a limited-time deal: Continue your subscription now and enjoy one month on us, free of charge!</p> */}
        {/* <button onClick={() => setCancelModal(false)}  className=" block bg-[#817F80] px-[40px] py-[10px] text-[16px] rounded-full mx-auto text-white font-semibold uppercase my-[20px] ">CLAIM YOUR FREE MONTH</button> */}
      </div>
    </div>
  );
};

export default CancelSubscription;
