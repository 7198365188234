  import React, { useState, useEffect, useRef } from "react";
  import { Link } from "react-router-dom";
  import { useAuth } from "../AuthContext";
  import CancelSubscription from "./CancelSubscription";
  import { baseUrl } from "../Utils/app";
  import toast from "react-hot-toast";
  import axios from "axios";
  import storage from "../Utils/firebase";
  import { useSearchParams } from "react-router-dom";


  const MyAccount = () => {
    const { state, dispatch } = useAuth();
    const [rating, setRating] = useState(0);
    const [cancelModal, setCancelModal] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showAvatar, setAvatar] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    // const [pageViewCalls, setPageViewCalls] = useState(0)

    const avatarRef = useRef();

    let loadingToast;

    let pageViewCalls = 0;

    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use 'auto' for an instant scroll
        });
      };
      scrollToTop();
    }, []);

    useEffect(() => {
      getRating();
    }, []);

    useEffect(() => {
      let to = searchParams.get("to");
      console.log(searchParams.get("to"))
      if (to == "cancel-rebill") {
          setCancelModal(true);
      }
      // let id = searchParams.get("id");
    }, [searchParams]);

    // useEffect(() => {
    //     let screenTimeId;
    //     const startScreenTime = async () => {
    //         pageViewCalls++
    //         try {
    //             const response = await axios.post(
    //                 `${baseUrl}/api/track-screen-time`,
    //                 { screen_name: "My Account" },
    //                 {
    //                     headers: {
    //                         'Authorization': `Bearer ${state?.token}`,
    //                     },
    //                 }
    //             );
    //             screenTimeId = response.data.screen_time_id;
    //         } catch (error) {
    //             console.error('Error tracking screen time:', error);
    //         }
    //     };

    //     const endScreenTime = async () => {

    //         if(screenTimeId){
    //             try {
    //                 await axios.post(
    //                     `${baseUrl}/api/update-screen-time`,
    //                     { screen_time_id: screenTimeId },
    //                     {
    //                         headers: {
    //                             'Authorization': `Bearer ${state?.token}`,
    //                         },
    //                     }
    //                 );
    //             } catch (error) {
    //                 console.error('Error updating screen time:', error);
    //             }
    //         }
    //     };

    //     if(pageViewCalls == 0){
    //         console.log("pageViewCalls = " +pageViewCalls )
    //         startScreenTime();
    //     }
    //     return () => {
    //         endScreenTime();
    //     };
    // }, []);

    const getRating = async () => {
      setLoading(true);

      await axios
        .get(`${baseUrl}/api/get-rating`, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          setRating(response?.data?.data?.rating?.rating);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    const handleLogout = () => {
      dispatch({ type: "LOGOUT" });
    };

    const changePassword = async (e) => {
      e?.preventDefault();
      setLoading(true);
      if (newPassword != retypePassword) {
        setLoading(false);
        return toast.error("Passwords do not match!");
      }
      let postData = {
        password: newPassword,
      };
      await axios
        .post(`${baseUrl}/api/resetPassword`, postData, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setNewPassword("");
          setRetypePassword("");
          setLoading(false);
          toast.dismiss(loadingToast);
          toast.success("Password Reset Successfully");
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    const saveRating = async () => {
      setLoading(true);
      let postData = {
        rating: rating,
      };

      await axios
        .post(`${baseUrl}/api/save-rating`, postData, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          toast.dismiss(loadingToast);
          toast.success("Rating saved Successfully");
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const uploadAvatar = async (e) => {
      e?.preventDefault();

      fileUploadPromise(avatarRef.current.files[0]).then(async (result) => {
        let postData = {
          avatar_url: result,
        };

        axios.defaults.withCredentials = true;
        await axios
          .post(`${baseUrl}/api/upload-avatar`, postData, {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          })
          .then((response) => {
            setLoading(false);
            dispatch({ type: "UPDATE-USER", payload: response?.data?.data });
            toast.dismiss(loadingToast);
            toast.success("Avatar saved Successfully");
          })
          .catch((error) => {
            console.log(error);
            toast.dismiss(loadingToast);
            toast.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : error?.message,
            );
            setLoading(false);
          });
      });
    };

    const fileUploadPromise = (imageFile) => {
      return new Promise(async (resolve, reject) => {
        if (imageFile) {
          let uuid = require("uuid");
          try {
            let uploadTask = storage
              .ref(
                `/fileUploads/${uuid.v4() + "." + imageFile.name.split(".")[1]}`,
              )
              .put(imageFile);
            uploadTask
              .then((uploadSnapshot) => {
                return uploadSnapshot.ref.getDownloadURL();
              })
              .then((url) => {
                resolve(url);
              })
              .catch((err) => {
                reject(err);
              });
          } catch (e) {
            console.log(e);
            reject(e);
          }
        } else {
          resolve("");
        }
      });
    };

    return (
      <section
        className=" bg-[#241C24] bg-cover pb-[100px] relative"
        style={{ backgroundImage: "url(/assets/Images/bgsec1.png)" }}
      >
        <div className="custom-container ">
          <div className="pt-[100px]">
            <h1 className="uppercase text-3xl font-bold text-white text-center mb-[30px]">
              Account
            </h1>

            <div className="mb-[40px]">
              <p className="text-white text-xl text-center font-medium mb-2">
                Rate Us:
              </p>
              <div className="flex justify-center mb-[10px]">
                <svg
                  onClick={() => setRating(1)}
                  width="30"
                  height="30"
                  fill={rating >= 1 ? "#18AD58" : "none"}
                  stroke="#18AD58"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                </svg>
                <svg
                  onClick={() => setRating(2)}
                  width="30"
                  height="30"
                  fill={rating >= 2 ? "#18AD58" : "none"}
                  stroke="#18AD58"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                </svg>
                <svg
                  onClick={() => setRating(3)}
                  width="30"
                  height="30"
                  fill={rating >= 3 ? "#18AD58" : "none"}
                  stroke="#18AD58"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                </svg>
                <svg
                  onClick={() => setRating(4)}
                  width="30"
                  height="30"
                  fill={rating >= 4 ? "#18AD58" : "none"}
                  stroke="#18AD58"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                </svg>
                <svg
                  onClick={() => setRating(5)}
                  width="30"
                  height="30"
                  fill={rating >= 5 ? "#18AD58" : "none"}
                  stroke="#18AD58"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
                </svg>
              </div>
              {rating > 0 && (
                <button
                  type="submit"
                  className="text-sm bg-[#18AD58] px-[30px] py-[3px] text-white uppercase text-center block mx-auto rounded-md"
                  onClick={() => saveRating()}
                >
                  Submit
                </button>
              )}
            </div>

            <div className="md:w-[600px] h-auto mx-auto border-2 border-[#1B141A] mb-[50px]">
              <div className="px-[30px] py-[15px] bg-[#1B141A]">
                <p className="text-lg text-white">Account Details</p>
              </div>
              <div className="px-[30px] py-[15px] border-b-2 border-[#1B141A] ">
                <div className="flex justify-between">
                  <p className="text-base text-white opacity-50">Avatar</p>

                  <button onClick={() => setAvatar(!showAvatar)}>
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 20h9"></path>
                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                    </svg>
                  </button>
                </div>

                {showAvatar && (
                  <form onSubmit={(e) => uploadAvatar(e)} className="mt-[15px]">
                    <img
                      src={selectedImage}
                      alt=" Avatar"
                      className="w-[100px] h-[100px] block mx-auto my-3 bg-white rounded-full"
                    />
                    <input
                      ref={avatarRef}
                      required
                      className="block w-min mx-auto h-[40px] bg-transparent px-2 rounded text-white placeholder:text-white mb-4"
                      onChange={handleImageChange}
                      placeholder="New Password"
                      type="file"
                    />
                    <button
                      type="submit"
                      className="text-sm bg-[#18AD58] px-[40px] py-[5px] text-white uppercase text-center block mx-auto rounded-md"
                    >
                      Submit
                    </button>
                  </form>
                )}
              </div>
              <div className="px-[30px] py-[15px] border-b-2 border-[#1B141A] flex justify-between">
                <p className="text-base text-white opacity-50">Email</p>
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 20h9"></path>
                  <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                </svg>
              </div>
              <div className="px-[30px] py-[15px] border-b-2 border-[#1B141A] ">
                <div className="flex justify-between">
                  <p className="text-base text-white opacity-50">Password</p>
                  <button
                    onClick={() => setShowResetPassword(!showResetPassword)}
                  >
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 20h9"></path>
                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                    </svg>
                  </button>
                </div>

                {showResetPassword && (
                  <form onSubmit={(e) => changePassword(e)} className="mt-[15px]">
                    <input
                      required
                      className="block w-full h-[40px] bg-transparent border border-white px-2 rounded text-white placeholder:text-white mb-4"
                      value={newPassword}
                      onChange={(newText) => setNewPassword(newText.target.value)}
                      placeholder="New Password"
                      type="password"
                    />
                    <input
                      required
                      className="block w-full h-[40px] bg-transparent border border-white px-2 rounded text-white placeholder:text-white mb-4"
                      value={retypePassword}
                      onChange={(newText) =>
                        setRetypePassword(newText.target.value)
                      }
                      placeholder="Retype Password"
                      type="password"
                    />
                    {!loading && (
                      <button
                        type="submit"
                        className="text-sm bg-[#18AD58] px-[40px] py-[5px] text-white uppercase text-center block mx-auto rounded-md"
                      >
                        Submit
                      </button>
                    )}
                  </form>
                )}
              </div>
              {/* <div className="px-[30px] py-[15px] border-b-2 border-[#1B141A]">
                              <button onClick={()=>changePassword()} className="text-lg text-white uppercase text-center block mx-auto">Reset Password</button>
                          </div> */}
              <div className="px-[30px] py-[15px]">
                <button
                  onClick={() => handleLogout()}
                  className="text-lg text-white uppercase text-center block mx-auto"
                >
                  Log out
                </button>
              </div>
            </div>

            <div className="md:w-[600px] h-auto mx-auto border-2 border-[#1B141A] mb-[20px]">
              <div className="px-[30px] py-[15px] bg-[#1B141A]">
                <p className="text-lg text-white">Subscription</p>
              </div>

              <div className="px-[30px] py-[15px]">
                <button
                  onClick={() => setCancelModal(true)}
                  className="text-lg text-white uppercase text-center block mx-auto"
                >
                  MEMBERSHIP SETTINGS
                </button>
              </div>
            </div>

            <button className=" block bg-[#18AD58] px-[40px] py-[5px] text-base rounded-md text-white font-semibold mx-auto">
              Save
            </button>
          </div>
        </div>

        {cancelModal && (
          <CancelSubscription
            setCancelModal={setCancelModal}
          ></CancelSubscription>
        )}
      </section>
    );
  };

  export default MyAccount;