import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { baseUrl } from "../Utils/app";
import ItemCard from "./Components/ItemCard";
import { useAuth } from "../AuthContext";

const geoip2 = window.geoip2;

const Games = () => {
  let loadingToast;
  const effectRan = useRef(false);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useAuth();
  const [games, setGames] = useState([]);
  const [featured, setFeatured] = useState();
  const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    geoip2.country(
      (response1) => {
        setGeoData(response1);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  useEffect(() => {
    if (!state.isAuth) return;
    if (effectRan.current) return; // Return early if effect has already run once

    const loadingToast = toast.loading("Loading");

    const trackVisit = async () => {
      axios.defaults.withCredentials = true;
      return axios.post(
        `${baseUrl}/api/track-game-visit`,
        {
          ip_address: geoData?.traits?.ip_address
            ? geoData?.traits?.ip_address
            : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        },
      );
    };

    trackVisit()
      .then((resp) => {
        toast.dismiss(loadingToast);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });

    effectRan.current = true; // Mark effect as run
  }, [geoData]);

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/get-games`, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      });
    };

    getItems()
      .then((resp) => {
        setGames(resp.data.data.games);
        setFeatured(resp.data.data.games[0]);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          {/* <Swiper
                                              modules={[Navigation, Pagination, A11y]}
                                              spaceBetween={0}
                                              slidesPerView={1}
                                              navigation
                                              pagination={{ clickable: true }}
                                              // scrollbar={{ draggable: true }}
                                              onSwiper={(swiper) => console.log(swiper)}
                                              onSlideChange={() => console.log('slide change')}
                                              >
                                                  {
                                                      featured.length > 0 && featured.map((item) => <SwiperSlide key={item.id}>
                                                          <section className="h-screen bg-[#241C24] bg-cover" style={{backgroundImage: `url(${item?.cover_image_large})`}}>
                                                              <div className="custom-container flex items-center h-full">
                                                                  <div className="w-[50%] min-w-[500px]">
                                                                      <h2 className="text-2xl tracking-[15px] text-white ">MOVIES</h2>
                                                                      <h1 className="text-[#18AD58] text-[50px] font-bold uppercase"> <Link to={"/moviePlayer/"+item.id}>{item?.title}</Link></h1>
                                                                      <p className="text-white text-xl mb-6 font-semibold">{item?.rating}/10 • {item?.duration} . {item?.release_date}</p>
                                                                      <p className="text-white text-xl mb-7">
                                                                          {item?.description}
                                                                      </p>
                                                                      <Link to={"/moviePlayer/"+item.id} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">WATCH NOW</Link>
                                                                  </div>
                                                              </div>
                                                          </section>
                                                      </SwiperSlide>
                                                      )
                                                  }
                                          </Swiper> */}
          <section
            className="hidden lg:block h-auto lg:h-screen bg-[#241C24] bg-cover bg-right max-h-[800px]"
            style={{ backgroundImage: `url(/assets/Images/games-bg.jpg)` }}
          >
            <div className="custom-container flex items-center h-full">
              <div className="w-[50%] min-w-[500px]">
                <h2 className="text-2xl tracking-[15px] text-white ">GAMES</h2>
                <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">
                  {" "}
                  <Link to={"/moviePlayer/" + featured?.id}>Angry Heroes</Link>
                </h1>
                <p className="text-white text-xl mb-6 font-semibold">
                  6/10 • 2024
                </p>
                <p className="text-white text-xl mb-7">
                  {featured?.description}
                </p>
                <Link
                  to={"/games/" + featured?.id}
                  className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                >
                  Play NOW
                </Link>
              </div>
            </div>
          </section>
          <section
            className=" lg:hidden h-auto lg:h-screen w-full bg-cover max-h-[800px] bg-[#241C24] bg-opacity-60 bg-right"
            style={{ backgroundImage: `url(assets/Images/games-bg.jpg)` }}
          >
            <div className="pt-[150px] lg:pt-[150px] lg:pb-0 pb-[50px]  lg:bg-transparent">
              <div className="custom-container flex items-center h-full">
                <div className="md:w-[50%] md:min-w-[500px]">
                  <h2 className="text-2xl tracking-[15px] text-white ">
                    GAMES
                  </h2>
                  <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">
                    <Link to={"/moviePlayer/" + featured?.id}>
                      Angry Heroes
                    </Link>
                  </h1>
                  <p className="text-white text-xl mb-6 font-semibold">
                    6/10 • 2024
                  </p>
                  <p className="text-white text-xl mb-7">
                    {featured?.description}
                  </p>
                  <Link
                    to={"/games/" + featured?.id}
                    className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                  >
                    Play NOW
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section
            className="pt-[100px] pb-[50px] bg-[#241C24] mt-[-2px]"
            style={{ backgroundImage: "url(/assets/Images/sec3bg.png)" }}
          >
            <div className="custom-container">
              <div className=" mx-auto">
                <div className="flex justify-between mb-[44px]">
                  <h2 className=" text-white text-3xl font-bold ">Games</h2>
                </div>
                <div className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-[30px] gap-y-[40px]">
                  {/* {
                                                              movies?.length > 0 && movies?.map(
                                                                  (item) =>  <ItemCard routeName={"/moviePlayer/"} item={item}></ItemCard>

                                                              )
                                                          } */}

                  {games?.length > 0 &&
                    games?.map((item) => (
                      <>
                        <Link
                          to={"/games/" + item?.id}
                          state={{ game: item }}
                          className="block h-[310px] relative group"
                        >
                          <img
                            className="h-[290px] object-contain"
                            src={item?.image}
                          />
                          <div className=" bg-[#241C24] opacity-90 p-3 group-hover:p-5 group-hover:h-full absolute bottom-0 left-0 right-0 z-10">
                            <p className="text-white uppercase group-hover:hidden">
                              {item?.name}
                            </p>
                            <div className="border-2 border-white h-full pt-[57px] text-center p-3 transition-all hidden group-hover:block ease-in-out delay-150 ">
                              <p className="text-white font-bold text-center text-xl uppercase mb-2">
                                {item?.name}
                              </p>
                              <svg
                                className=" mx-auto"
                                width="46"
                                height="46"
                                fill="none"
                                stroke="#fff"
                                stroke-width="1"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z"></path>
                                <path
                                  fill="#fff"
                                  stroke="none"
                                  d="m10.14 15.676 5.365-3.241a.511.511 0 0 0 0-.872L10.14 8.322a.505.505 0 0 0-.765.436v6.481a.505.505 0 0 0 .765.437Z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </Link>

                        {/* <Link
                                                  to={"/games/2"}
                                                  className="block h-[310px] relative group"
                                                >
                                                  <img
                                                    className="h-[290px] object-contain"
                                                    src={"/assets/Images/Zoo_memory_game_icon.png"}
                                                  />
                                                  <div className=" bg-[#241C24] opacity-90 p-3 group-hover:p-5 group-hover:h-full absolute bottom-0 left-0 right-0 z-10">
                                                    <p className="text-white uppercase group-hover:hidden">
                                                      Zoo Memory
                                                    </p>
                                                    <div className="border-2 border-white h-full pt-[57px] text-center p-3 transition-all hidden group-hover:block ease-in-out delay-150 ">
                                                      <p className="text-white font-bold text-center text-xl uppercase mb-2">
                                                        Zoo Memory
                                                      </p>
                                                      <svg
                                                        className=" mx-auto"
                                                        width="46"
                                                        height="46"
                                                        fill="none"
                                                        stroke="#fff"
                                                        stroke-width="1"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z"></path>
                                                        <path
                                                          fill="#fff"
                                                          stroke="none"
                                                          d="m10.14 15.676 5.365-3.241a.511.511 0 0 0 0-.872L10.14 8.322a.505.505 0 0 0-.765.436v6.481a.505.505 0 0 0 .765.437Z"
                                                        ></path>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                </Link> */}
                      </>
                    ))}
                </div>
                <div className="mt-[80px]">
                  <button className=" block bg-[#18AD58] px-[40px] py-[5px] text-[16px] rounded-md text-white font-semibold uppercase mx-auto">
                    Show More
                  </button>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section
          className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
          style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
        >
          <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
        </section>
      )}
    </>
  );
};

export default Games;
