import React, { useState, useEffect } from "react";
import { baseUrl } from "../Utils/app";
import toast from "react-hot-toast";
import axios from "axios";
import { db } from "../Utils/firebase";
import { useAuth } from "../AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { publicEmailServices } from "../Utils/ArrayData";
import { eu_countries } from "../Utils/ArrayData";

const geoip2 = window.geoip2;

const isValidEmail = async (email) => {
  // Basic email format validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return false;
  }

  // Extract the domain part of the email
  // const emailDomain = email.split('@')[1];

  // Check if the domain is in the allowed domains list
  // return publicEmailServices.includes(emailDomain);
  let emailValidity = false;

  axios.defaults.withCredentials = false;
  await axios
    .get(
      "https://emailvalidation.abstractapi.com/v1/?api_key=fb718f22f2eb4a89b7a2e8997ab92050&email=" +
        email,
    )
    .then((response) => {
      if (
        response.data?.deliverability == "DELIVERABLE" &&
        response.data?.is_valid_format?.value &&
        !response.data?.is_disposable_email?.value
      ) {
        emailValidity = true;
      } else {
        emailValidity = false;
      }
    })
    .catch((error) => {
      emailValidity = false;
    });

  return emailValidity;
};

const Register = () => {
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(false);
  const [ipCurrency, setIpCurrency] = useState();
  const [formControls, setFormControls] = useState({
    email: "",
    password: "",
    agree: false,
  });

  const [loading, setLoading] = useState(false);
  let loadingToast;

  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state?.isLogin) {
      setIsLogin(true);
    }
    if (location?.pathname == "/login") {
      setIsLogin(true);
    }
    if (location?.pathname == "/register") {
      TagManager.dataLayer({
        dataLayer: {
          event: "registration_screen",
          page: location.pathname + location.search,
        },
      });
      setIsLogin(false);
    }
  }, [location]);

  useEffect(() => {
    const data = {
      country: "",
      city: "",
      timeZone: "",
      zipcode: "",
    };
    let currency = "USD";

    geoip2.country(
      (response1) => {
        data.country = response1.country.names?.en;
        if (data.country) {
          if (eu_countries.includes(data.country)) {
            currency = "EUR";
          } else if (data.country == "United Kingdom") {
            currency = "GBP";
          } else {
            currency = "USD";
          }
        }

        setIpCurrency(currency);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLogin && !formControls?.agree) {
      toast.error("You have to agree to Terms and Conditions.");
      return;
    }

    let checkValidity = await isValidEmail(formControls.email);
    if (!checkValidity) {
      toast.error("Your email address is invalid.");
      return;
    }

    let postData = {
      ...formControls,
    };
    setLoading(true);

    await axios
      .post(`${baseUrl}/api/${isLogin ? "login" : "register"}`, postData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.dismiss(loadingToast);
        setFormControls({
          email: "",
          password: "",
        });
        if (response?.data?.data?.isLogin) {
          dispatch({ type: "LOGIN-USER", payload: response?.data?.data });
          navigate("/musics");
        } else {
          navigate("/subscribe39", {
            state: { dbUser: response?.data?.data, currency: ipCurrency },
          });
          toast.success("Account Created Successfully");
        }
        // dispatch({ type: "LOGIN-USER", payload: response?.data?.data });
        // navigate("/musics");
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  return (
    <div className=" h-screen bg-[#BFBEBE] flex items-center justify-center">
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="w-[500px] bg-white rounded-lg p-[50px]"
      >
        <h1 className="mb-[20px] text-gray-500 text-3xl text-center font-semibold">
          {" "}
          {isLogin ? "Login to your" : "Create your "} account
        </h1>
        <div className="mb-[10px]">
          <label className="mb-[5px] text-gray-500 block font-semibold">
            Enter your email
          </label>
          <input
            required
            value={formControls.email}
            onChange={(e) =>
              setFormControls({ ...formControls, email: e.target.value })
            }
            className="p-2 border border-gray-500 block rounded-md w-full mb-[5px]"
          />
          <p className="text-xs text-gray-500">
            By clicking the continue button, you confirm your email address is
            valid.
          </p>
        </div>
        <div className="mb-[20px]">
          <label className="mb-[5px] text-gray-500 block font-semibold">
            Password
          </label>
          <input
            type="password"
            required
            value={formControls.password}
            onChange={(e) =>
              setFormControls({ ...formControls, password: e.target.value })
            }
            className="p-2 border border-gray-500 block rounded-md w-full mb-[10px]"
          />
          <p className="text-sm text-right mb-2 text-gray-500">
            <Link to={"/forget-password"} className="underline">
              Forgot Password
            </Link>
          </p>
          {isLogin ? (
            <p className="text-sm text-gray-500 mb-3">
              Don't have an account?{" "}
              <Link to={"/register"} className="underline">
                Click here
              </Link>{" "}
              to register.
            </p>
          ) : (
            <p className="text-sm text-gray-500 mb-3">
              Already have an account?{" "}
              <Link to={"/login"} className="underline">
                Click here
              </Link>{" "}
              to login.
            </p>
          )}
          {!isLogin && (
            <label className="text-xs text-gray-500 flex gap-x-2 items-start">
              <input
                onChange={() =>
                  setFormControls({
                    ...formControls,
                    agree: !formControls?.agree,
                  })
                }
                checked={formControls.agree}
                className="mt-[5px]"
                type="checkbox"
              />
              <span className="block">
                I agree to the{" "}
                <Link to={"/terms-condition"} class=" underline">
                  Terms and Conditions
                </Link>
                ,{" "}
                <Link to={"/privacy"} class=" underline">
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link to={"/cookie"} class=" underline">
                  Cookie Policy
                </Link>
                . I confirm I am 18 years or older and agree to receive emails
                from onehubplay.com.
              </span>
            </label>
          )}
        </div>

        {!loading && (
          <button
            type="submit"
            className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase "
          >
            Continue
          </button>
        )}
        {loading && (
          <button
            type="button"
            className=" bg-[#18AD58] px-[50px] py-[10px] text-2xl rounded-full text-white font-bold uppercase block mx-auto"
          >
            <img width={25} src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
          </button>
        )}
      </form>
    </div>
  );
};

export default Register;
