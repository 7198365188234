import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyBECFn3bDX0UC1YQC9ST2bijNCNezRTDVg",
  authDomain: "eclat-test.firebaseapp.com",
  projectId: "eclat-test",
  storageBucket: "eclat-test.appspot.com",
  messagingSenderId: "1092589634255",
  appId: "1:1092589634255:web:70b6f6c79ec719655eedba",
  // measurementId: "G-XL42TQC33E"
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
// export const firestore = firebase.firestore()
export const db = firebase.database();
export default storage;
