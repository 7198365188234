 import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { baseUrl } from "../Utils/app";

const Privacy = () => {
  let loadingToast;
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState(null);
  const [privacy, setPrivacy] = useState({});
  const [cookie, setCookie] = useState({});

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    const getItems = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/getPolicy`);
    };

    getItems()
      .then((resp) => {
        setTerms(resp?.data?.data?.filter((item) => item?.type == "t&c")[0]);
        setPrivacy(
          resp?.data?.data?.filter((item) => item?.type == "privacy")[0],
        );
        setCookie(
          resp?.data?.data?.filter((item) => item?.type == "cookie")[0],
        );
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });
  }, []);

  return (
    <section
      className="min-h-screen bg-[#241C24] bg-cover pb-[100px]"
      style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
    >
      <div className="custom-container ">
        <div className="pt-[150px]">
          <h1 className="uppercase text-3xl font-bold text-white text-center mb-[40px]">
            PRIVACY POLICY
          </h1>
          <p className="text-white text-xs font-semibold text-justify mb-7">
            {" "}
            OneHubPlay.com™ (“We”, “Us”) respects your privacy. Please read the
            following to learn more about our Privacy Policy. This Privacy
            Policy applies to the OneHubPlay Service provided by Us. This
            Privacy Policy describes how We process your personal data.
          </p>
          <p className="text-white text-xs font-semibold text-justify mb-7">
            {" "}
            This Privacy Policy describes:
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            1.Who We Are
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            2. Information We Collect
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            3. How We Use Your Information?
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            4. Sharing of Personal Data
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            5. Legal Basis
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            6. Personal data Protection
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            7. Disclosure of Information to Third Party
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            8. Third-Party Links
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            9. Where Do We Store the Information
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            10. Access, Data Portability, Migration, and Transfer Back
            Assistance
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            11. Request for Rectification, Restriction or Erasure of the
            Personal Data
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            12. Data Retention
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            13. Cooperation
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            14. Terms of Service
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            15. Your Consent
          </p>
          <p className="text-white text-xs font-bold text-justify mb-4 text-bold">
            16. Changes to Our Privacy Policy
          </p>
          <p className="text-white text-xs font-bold text-justify mb-7 text-bold">
            17. Complaint
          </p>

          <p className="text-white text-xs text-justify mb-4 font-light">
            <em>
              This Privacy Policy complies with all rules and principles
              provided in the Regulation (EU) 2016/679 of the European
              Parliament and of the Council of April 27th, 2016, on the
              protection of natural Persons with regard to the processing of
              personal data and on the free movement of such data, and repealing
              Directive 95/46/EC (hereinafter referred to as the “GDPR”) and the
              California Consumer Privacy Act of 2018 (hereinafter the “CCPA”),
              as applicable. 
            </em>
          </p>

          <p className="text-white text-xs text-justify mb-4 font-light">
            <em>
              Capitalised terms used but not defined herein, shall have the
              respective meanings given to them in the Subscriber Agreement.
            </em>
          </p>

          <div className="mb-7">
            <p className="text-bold text-base text-white"></p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className=""></li>
              <li className="">
                <p className="mb-4"></p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className=""></li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">1. Who We Are</p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                <p className="mb-4">
                  1.1. If there are any questions regarding this Privacy Policy
                  You may contact Us using the information below:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    InfiniteGroove Limited, a private limited liability company
                    organised and existent under the laws of the Republic of
                    Cyprus, enrolled with the Registrar of Companies under
                    number HE 454437 and having its registered office at
                    Pipinou, 6, 2320, Nicosia, Cyprus.
                  </li>
                </ul>
              </li>
              <li className="">
                1.2. Our customers may submit inquiries regarding personal data
                protection, privacy and security matters to
                legal@onehubplay.com.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              2. Information We Collect
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                2.1. We collect data from You, whether when directly provided by
                You, through our interactions with You, or by means of your
                interactions, use and experiences with the OneHubPlay Service.
              </li>
              <li className="">
                2.2. Nonetheless, the collection of data relies on the
                circumstances under which You interact with Us and on your
                choices.
              </li>
              <li className="">
                <p className="mb-4">
                  2.3. To access and use the OneHubPlay Service, You must
                  register for a OneHubPlay Service account where You will be
                  requested to provide personal data, which You are entitled to
                  decline. Should You choose to carry-on with the said
                  registration, these are the categories of data We will collect
                  and that will be processed on your behalf:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    a) When You are registering to access and use the OneHubPlay
                    Service, We collect personal data, such as name, email
                    address, account details and credentials;
                  </li>
                  <li className="">
                    b) Should You require our assistance or support through
                    customer services, We will collect device and usage data and
                    error reports, if any, in order to diagnose and resolve any
                    pending issue;
                  </li>
                  <li className="">

                    c) When You choose to claim a Free Trial (if applicable) or subscribe for a Paid Subscription, We collect financial information, such as billing information and credit card details. Financial institutions or payment processors may provide Us with updates regarding your payment information or payment methods related to your account or paid subscription and services;
                  </li>
                  <li className="">
                    d) Your data will also be collected when We communicate with
                    You, so We may personalise the content of such
                    communications.
                  </li>
                </ul>
              </li>
              <li className="">
                2.4. Some information is automatically collected when You visit
                or use the OneHubPlay Service, such as your IP address and
                browser user-agent. We may also install cookies and analytic
                tools. For more information on cookies, please read our Cookies
                Policy – https://www.onehubplay.com/cookie.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              3. How We Use Your Information?
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                <p className="mb-4">
                  3.1. We use the data, whether collected by Us or provided by
                  You, to provide You with a personalised experience, improved
                  website, and periodic e-mails. We use such data especially to:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    a) Provide the OneHubPlay Service and maintain the
                    relationship between You and Us;
                  </li>
                  <li className="">
                    b) Create and manage your account, provide You with customer
                    support and complete your transactions;
                  </li>
                  <li className="">
                    c) Communicate with You and administrate your profile;
                  </li>
                  <li className="">
                    d) Improve, update, secure and troubleshoot the OneHubPlay
                    Service and provide You with relevant support;
                  </li>
                  <li className="">e) Improve our marketing efforts;</li>
                  <li className="">f) Personalise the OneHubPlay Service;</li>
                  <li className="">
                    g) Provide advertisement and marketing experience directly
                    relevant to You, including, without limitations, targeted
                    advertisement and promotional communications;
                  </li>
                  <li className="">
                    h) To prevent, detect and fight fraud or other illegal or
                    unauthorised activities.
                  </li>
                </ul>
              </li>
              <li className="">
                3.2. In addition to the foregoing, We may also eventually use
                the data for conducting certain analysis of our business and
                researches to make informed business decisions, to meet our
                legal obligations, and for other legitimate purposes
              </li>
              <li className="">
                3.3. To carry out the use of information for these purposes, We
                employ automated as well as manual processing methods.
              </li>
              <li className="">
                3.4. If at any time You would like to unsubscribe from receiving
                future e-mails, You can do so through the appropriate channel.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white"></p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                4.1. We will share your personal data in order to complete
                transactions and provide the OneHubPlay Service to You. This
                sharing may also be extended, as necessary, to our affiliates,
                subsidiaries and to agents or representatives working on our
                behalf.
              </li>
              <li className="">
                4.2. We will also share your personal data if required by law,
                to comply with legal process, to protect our customers/users, to
                protect lives, to ensure the security of the OneHubPlay Service
                and to protect ours and our customers’ rights.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white"></p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                <p className="mb-4">
                  <b>
                    5.1. Legal bases for processing (under the GDPR provisions):{" "}
                  </b>{" "}
                  If You are an individual in the European Economic Area (EEA),
                  We collect and process information about You only where We
                  have legal bases for doing so under applicable EU laws. The
                  legal bases depend on the services You use and how You use
                  them. This means We collect and use your information only
                  where:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    a) We need it to provide You the OneHubPlay Service,
                    including to operate the OneHubPlay Service, provide
                    customer support and personalised features and to protect
                    the safety and security of the OneHubPlay Service;
                  </li>
                  <li className="">
                    b) It satisfies a legitimate interest, which is not
                    overridden by your data protection interests, such as for
                    research and development, to market and promote the
                    OneHubPlay Service, and to protect our legal rights and
                    interests;
                  </li>
                  <li className="">
                    c) You give Us consent to do so for a specific purpose; or
                  </li>
                  <li className="">
                    d) We need to process your data to comply with legal
                    obligations.
                  </li>
                </ul>
              </li>
              <li className="">
                <b>
                  5.2. Children’s Online Privacy Protection Act Compliance.{" "}
                </b>{" "}
                We will not intentionally collect any information from anyone
                under eighteen (18) years of age. The OneHubPlay Service is
                directed at people who are at least eighteen (18) years old.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              6. Personal Data Protection
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                6.1. We implement the following technical, physical and
                organisational measures to maintain the safety of your personal
                data against accidental or unlawful destruction or accidental
                loss, alteration, unauthorised use, unauthorised modification,
                disclosure or access and against all other unlawful forms of
                processing.
              </li>
              <li className="">
                6.2. We regularly monitor our systems for possible
                vulnerabilities and attacks and review your information, storage
                and processing practices to update our technical, physical and
                organizational measures.
              </li>
              <li className="">
                6.3. In order to ensure that our systems and your information
                are protected the following measures are involved: storage of
                confidential data such as credentials and credit cards details
                in encrypted form.
              </li>
              <li className="">
                6.4. <b>Confidentiality.</b> All personnel are subject to full
                confidentiality and any subcontractors and sub-processors are
                required to sign a confidentiality agreement if not full
                confidentiality is part of the main agreement between the
                parties.
              </li>
              <li className="">
                <p className="mb-4">
                  <b>6.5. Transparency.</b> We will at all times keep You
                  informed about changes to the processes to protect data
                  privacy and security, including practices and policies. You
                  may at any time request information on where and how data is
                  stored, secured and used.
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    6.5.1. Without prejudice to the foregoing, should You be a
                    natural person residing in the State of California, United
                    States of America, You shall, at all times, be covered by
                    the CCPA, which ensures that any information that
                    identifies, relates to, describes, is capable of being
                    associated with, or could reasonably be linked, directly or
                    indirectly, with a particular consumer or household shall
                    have the same treatment as personal information, excluding
                    however publicly available, deidentified, or aggregate
                    consumer information and personal information from job
                    applicants. The CCPA requires Us to disclose additional
                    information regarding the categories of personal information
                    We collect, the sources where We obtain that information,
                    the purposes for collecting that information, who We share
                    that information with, as well as whether We sell that
                    information. In this sense, if You are a California
                    resident, other than a job applicant, You have the right to
                    request from Us such disclosure, as well as the deletion of
                    your information.
                  </li>
                  <li className="">
                    6.5.2. Should You, a California resident other than a job
                    applicant, choose to exercise your rights under the CCPA,
                    You may send Us an e-mail, either on your own behalf or
                    through a duly appointed agent (provided with a notarised
                    power of attorney with special powers), to the designated
                    address hereunder, providing Us with enough information to
                    verify your identity and instructing such exercise. Any
                    information provided in that e-mail shall be solely used to
                    verify your request. Notwithstanding, We may request further
                    information to complete such verification including, without
                    limitation, your driver’s license and a recent utility or
                    credit card bill.
                  </li>
                </ul>
              </li>
              <li className="">
                <p className="mb-4">
                  <b>6.6. The ability to intervene. </b> We enable your rights
                  of access, rectification, erasure, blocking and objection by
                  offering You the option to send instructions to the following
                  e-mail address legal@onehubplay.com.
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    {" "}
                    6.6.1. This means that You are allowed to make choices with
                    respect to the data We collect from You and how We may use
                    it, such as controlling the use of your data for
                    interest-based advertising (by means of “opting out”),
                    choosing which promotional communications and in which
                    manner to receive it, and clearing some of your collected
                    data.
                  </li>
                  <li className="">
                    {" "}
                    6.6.2. Without prejudice to the foregoing, in certain
                    circumstances We may restrict your exercise of rights to
                    control and access your personal data, provided that when
                    doing so We shall be complying with a legal requirement or
                    otherwise acting solely to the extent permitted by law.
                  </li>
                  <li className="">
                    {" "}
                    6.6.3. Also, kindly note that although not all personal data
                    that We process may be accessed or controlled directly by
                    You, You may request and instruct Us as provided above.
                  </li>
                </ul>
              </li>
              <li className="">
                <p className="mb-4">
                  <b>6.7. Monitoring. </b> We use security reports to monitor
                  access patterns and to proactively identify and mitigate
                  potential threats. Administrative operations, including system
                  access, are logged to provide an audit trail if unauthorised
                  or accidental changes are made.
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    6.7.1. System performance and availability is monitored from
                    both internal and external monitoring services.
                  </li>
                </ul>
              </li>

              <li className="">
                <p className="mb-4">
                  <b>6.8. Cookies and similar technologies.</b> Cookies are
                  small text files placed on your device to store data that can
                  be recalled by a web server in the domain that placed the
                  cookie. We use cookies and similar technologies for storing
                  and honouring your preferences and settings, enabling You to
                  sign in, providing interest-based advertising, combating
                  fraud, analysing how our products perform and fulfilling other
                  legitimate purposes. Other similar technologies may be used by
                  Us for the purpose of gathering usage and performance data,
                  including trusted third parties’ technologies.
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    6.8.1. In order to control the data collected by cookies and
                    such similar technologies, You may use the variety of tools
                    provided in your web-browser of preference, limiting how
                    websites visited by You may use cookies, as well as clearing
                    or blocking cookies, which shall be construed as a
                    withdrawal of your consent.
                  </li>
                </ul>
              </li>

              <li className="">
                <p className="mb-4">
                  <b>6.9. Personal data breach notification. </b> In the event
                  that your data is compromised, We will notify You and the
                  competent Supervisory Authority(ies) within seventy two (72)
                  hours by e-mail with information about the extent of the
                  breach, affected data, any impact on the OneHubPlay Service,
                  as well as our action plan for measures to secure the data and
                  limit any possible detrimental effect on the data subjects.
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    "Personal data breach" means a breach of security leading to
                    the accidental or unlawful destruction, loss, alteration,
                    unauthorised disclosure of, or access to, personal data
                    transmitted, stored or otherwise processed in connection
                    with the provision of the OneHubPlay Service.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              7. Disclosure of Information to Third Party
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                7.1. We do not sell, trade or otherwise transfer to outside
                parties any personally identifiable information.
              </li>
              <li className="">
                7.2. This does not include trusted third parties or
                subcontractors who assist Us in operating the OneHubPlay
                Service, conducting our business, or servicing You. Such trusted
                parties may have access to personally identifiable information
                on a need-to-know basis and will be contractually obliged to
                keep your information confidential.
              </li>
              <li className="">
                7.3. We may also release your information when We believe
                release is appropriate to comply with the law, enforce our
                Policies, or protect our or others’ rights, property, or safety.
              </li>
              <li className="">
                7.4. We will monitor subcontractors’ and sub-processors’
                maintenance of these standards and audits to ensure that data
                protection requirements are fulfilled.
              </li>
              <li className="">
                <b>7.5. Legally required disclosure. </b> We will not disclose
                the customer’s data to law enforcement except when instructed by
                You or where it is required by law. When governments make a
                lawful demand for customer data from Us, We strive to limit the
                disclosure. We will only release specific data mandated by the
                relevant legal demand.
              </li>
              <li className="">
                7.6. If compelled to disclose your data, We will promptly notify
                You and provide a copy of the demand unless legally prohibited
                from doing so.
              </li>
              <li className="">
                <b>7.7. Disclosure of data to non-EEA countries. </b>. We may
                share personal data with third parties based in non-EEA
                countries other than the United Kingdom or Switzerland. Where
                personal data is transferred from the EU to non-EEA countries,
                the provisions included in Chapter V of the GDPR will be
                applied, in order to ensure the level of protection of natural
                persons guaranteed by the GDPR is not undermined. In this case,
                We ensure that the recipient has an adequate level of data
                protection according to the adequacy decision by the European
                Commission for the respective country. In the absence of such
                adequacy decision, We may transfer personal data only if the
                recipient has provided appropriate safeguards, such as a legally
                binding and enforceable instrument between public authorities or
                bodies; a binding corporate rule; or, the compliance with
                standard data protection clauses approved by the Commission.
                Where personal data is transferred from the EU to non-EEA, We
                will separately sign an addendum with such third parties,
                according to the standard data protection clauses approved by
                the Commission, namely Standard Contractual Clauses for the
                transfer of personal data to processors established in third
                countries or/and Standard Contractual Clauses for the transfer
                of personal data from the Community to third countries
                (controller to controller transfers), about such transfer which
                shall form an integral part of this Privacy Policy.
              </li>
              <li className="">
                <b>7.8. Residents of California. </b> If You are a California
                resident, You can request a notice disclosing the categories of
                personal information about You that We have shared with third
                parties for their direct marketing purposes during the preceding
                calendar year.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              8. Third-Party Links
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                8.1. Occasionally, at our discretion, We may include or offer
                third-party products or services on the OneHubPlay Service.
                These third-party sites have separate independent privacy
                policies. We therefore have no responsibility or liability for
                the content and activities of these linked websites.
                Nonetheless, We seek to protect the integrity of the OneHubPlay
                Service and welcome any feedback about these websites.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              9. Where Do We Store the Information
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                9.1. <b>Personal data location.</b> All data is stored in
                databases and file repositories hosted in the United States.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              10. Access, Data Portability, Migration and Transfer Back
              Assistance
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                10.1. You may at any time obtain confirmation from Us as to
                whether or not personal data concerning You is being processed.
              </li>
              <li className="">
                10.2. You may at any time order a complete personal data copy,
                which You may transmit to another controller of the personal
                data. Your personal data will be delivered by Us within thirty
                [30] working days, and free of any charges.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              11. Request for Rectification, Restriction or Erasure of the
              Personal Data
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                <b>11.1. Rectification. </b> You may at any time obtain without
                undue delay rectification of inaccurate personal data concerning
                You as per Section 6.6.
              </li>
              <li className="">
                <p className="mb-4">
                  <b>11.2. Restriction of processing personal data.</b> You may
                  at any time request Us to restrict the processing of personal
                  data when one of the following applies:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    a) if You contest the accuracy of the personal data, for a
                    period enabling Us to verify the accuracy of the personal
                    data;
                  </li>
                  <li className="">
                    b) if the processing is unlawful and You oppose the erasure
                    of the personal data and request the restriction of their
                    use instead; or
                  </li>
                  <li className="">
                    c) if We no longer need the personal data for the purposes
                    of the processing, but it is required by You for the
                    establishment, exercise or defence of legal claims.
                  </li>
                </ul>
              </li>
              <li className="">
                <p className="mb-4">
                  <b>11.3. Erasure.</b> You may without undue delay request the
                  erasure of personal data concerning You, and We shall erase
                  the personal data without undue delay when one of the
                  following applies:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    a) if the personal data is no longer necessary in relation
                    to the purposes for which it was collected or otherwise
                    processed;
                  </li>
                  <li className="">
                    b) if You withdraw your consent on which the processing is
                    based, and where there is no other legal ground for the
                    processing;
                  </li>
                  <li className="">
                    c) if You object to the processing in case the processing is
                    for direct marketing purposes;
                  </li>
                  <li className="">
                    d) if the personal data has been unlawfully processed; or
                  </li>
                  <li className="">
                    e) if the personal data has to be erased for compliance with
                    a legal obligation in EU or national law.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">12. Data Retention</p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                <b>12.1. Data retention </b> We store your data as long as is
                necessary to provide the OneHubPlay Service or We have a
                legitimate interest in further storage. We will keep data that
                We must keep in order to meet contractual or statutory retention
                periods (tax, penal, commercial, and invoices). Because of that,
                your data will due to tax regulations be retained for up to ten
                (10) full fiscal years (this period may vary from one
                jurisdiction to another) from the date of cancellation or
                termination.
              </li>
              <li className="">
                <b>
                  12.2. Data retention for compliance with legal requirements.{" "}
                </b>{" "}
                You cannot require Us to change any of the default retention
                periods, except for the reasons for erasure pursuant to Section
                11.3.
              </li>
              <li className="">
                <p className="mb-4">
                  <b>12.3. Data restitution and/or deletion </b> When We have no
                  ongoing legitimate interest, We anonymise your information,
                  unless We must keep it to evidence our compliance with
                  applicable law. For instance:
                </p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className="">
                    a) We will keep your data in the event of an outstanding
                    issue, claim, or dispute requiring Us to keep the relevant
                    information until it is resolved; or
                  </li>
                  <li className="">
                    b) The information must be kept for our legitimate business
                    interests, such as fraud prevention, and enhancing users’
                    safety and security.
                  </li>
                </ul>
                <p className="mb-4">
                  12.3.1. You must not cancel the OneHubPlay Service account
                  until the data copy has been delivered, as We otherwise will
                  not be able to deliver the data copy.
                </p>
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">13. Cooperation</p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                13.1. We will cooperate with You in order to ensure compliance
                with applicable data protection provisions, e.g. to enable You
                to effectively guarantee the exercise of data subjects’ rights
                (right of access, rectification, erasure, blocking, opposition),
                to manage incidents including forensic analysis in case of
                security breach.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              14. Terms of Service
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                14.1. Please also visit our Subscriber Agreement section
                establishing the use, disclaimers, and limitations of liability
                governing the use of the OneHubPlay Service.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">15. Your Consent</p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                15.1. By accessing and using the OneHubPlay Service, You consent
                to this Privacy Policy and to our Cookies Policy.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">
              16. Changes to Our Privacy Policy
            </p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                16.1. If We decide to change our Privacy Policy, We will post
                those changes on this page, and/or update the Privacy Policy
                modification date below.
              </li>
              <li className="">
                16.2. This Privacy Policy was last modified on 29.1.2024.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white">17. Complaint</p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className="">
                17.1. You may at any time lodge a complaint with a supervisory
                authority regarding our collection and processing of your
                personal data.
              </li>
            </ul>
          </div>

          <div className="mb-7">
            <p className="text-bold text-base text-white"></p>
            <ul className="text-white space-y-4 text-xs mb-4">
              <li className=""></li>
              <li className="">
                <p className="mb-4"></p>
                <ul className="text-white space-y-4 text-xs mb-4 ml-4">
                  <li className=""></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
