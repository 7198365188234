   // export const baseUrl = "http://localhost:8000";
// export const baseUrl = "https://ee679c97-681f-4241-adbe-2dc32b94857e-00-t7n4ab8t61si.spock.replit.dev:8000";

// export const baseUrl = "https://api-onehubplay.thekreativestack.com";
export const baseUrl = process.env.REACT_APP_API_URL;
// export const baseUrl = "https://api-onehubplay.thekreativestack.com";
// export const baseUrl ="https://onehubplay.com:8000";
// export const baseUrl = "https://test-frontend.onehubplay.com:8000";
// export const baseUrl = "http://54.205.209.22";
// export const baseUrl = "http://52.21.205.20";
