import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Utils/app";
import axios from "axios";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

const geoip2 = window.geoip2;

export default function PaymentSuccessful() {
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let loadingToast;
  const [searchParams] = useSearchParams();
  const [locationData, setLocationData] = useState({

    country_name: "",  
    country: "",
    city: "",
  });

  useEffect(() => {
    const loadGeoIPScript = async () => {
      try {
        let data = {
          country: "",
          city: "",
        };

        // Call the geoip2.country function
        // geoip2.country(
        //   (response1) => {
        //     console.log(response1.country.iso_code);
        //     geoip2.city(
        //         (response) => {
        //           console.log(response.city.names.en);
        //           data = {
        //               city:response.city.names.en,
        //               country: response1.country.iso_code
        //           }
        //           setLocationData({
        //             city:response.city.names.en,
        //             country: response1.country.iso_code
        //         })

        //         },
        //         (error) => {console.log(error)}
        //       );

        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );
      } catch (e) {
        console.error("Error loading GeoIP script:", e);
      }
    };

    loadGeoIPScript();
  }, []);

  useEffect(() => {
    loadingToast = toast.loading("Loading");
    let id = searchParams.get("id");
    let invoiceID = searchParams.get("invoiceID");
    let hash = searchParams.get("hash");

    if (id && invoiceID) {
      TagManager.dataLayer({
        dataLayer: {
          event: "payment_successful",
          page: location.pathname + location.search,
        },
      });

      savePaymentDetails(id, invoiceID);
    }
  }, [searchParams]);

  const savePaymentDetails = async (id, invoiceID) => {
    const data = {
      country: "",
      city: "",
      timeZone: "",
      zipcode: "",
    };

    const loadGeoIPScript = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          geoip2.country(
            (response1) => {
              data.country = response1.country.iso_code;
              geoip2.city(
                (response) => {
                  data.city = response.city.names.en;
                  data.country = response.country.iso_code;
                  data.timeZone = response.location.time_zone;
                  data.zipcode = response.postal?.code;
                  resolve();
                },
                (error) => {
                  console.log(error);
                  reject(error);
                },
              );
            },
            (error) => {
              console.log(error);
              reject(error);
            },
          );

          // http://localhost:3000/payment-success?flag=18fc86b9a1c&id=3&invoiceID=1717054394&hash=MYuRLV7Px%2BZWSY9ztYBC9OvxCkY%3D
        } catch (e) {
          console.error("Error loading GeoIP script:", e);
          reject(e);
        }
      });
    };

    try {
      await loadGeoIPScript();
      const postData = {
        id: id,
        invoiceID: invoiceID,
        countryCode: data.country,
        city: data.city,
        timezone: data.timeZone,
        zipcode: data?.zipcode,
      };

      const response = await axios.post(
        `${baseUrl}/api/rocketGate-savePayment`,
        postData,
      );
      let card = response?.data?.data?.card;
      if (card?.transaction) {
        switch (card?.transaction?.tr_amount) {
          case 9.99:
            TagManager.dataLayer({
              dataLayer: {
                event: "payment_successful_9_99",
                page: location.pathname + location.search,
              },
            });

            break;

          case 19.99:
            TagManager.dataLayer({
              dataLayer: {
                event: "payment_successful_19_99",
                page: location.pathname + location.search,
              },
            });
            break;

          case 29.99:
            TagManager.dataLayer({
              dataLayer: {
                event: "payment_successful_29_99",
                page: location.pathname + location.search,
              },
            });
            break;

          default:
            TagManager.dataLayer({
              dataLayer: {
                event: " payment_successful_9_99",
                page: location.pathname + location.search,
              },
            });
            break;
        }
      }
      console.log(response?.data?.data?.card);
      setLoading(false);
      toast.dismiss(loadingToast);
      // toast.success("Card Added Successfully");
      dispatch({ type: "LOGIN-USER", payload: response?.data?.data });
      navigate("/musics");
    } catch (error) {
      console.log(error);
      toast.dismiss(loadingToast);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      );
      setLoading(false);
    }
  };

  return (
    <div className=" pb-[100px] bg-[#fff] pt-[50px] lg:h-[80vh]">
      <img
        src="./assets/Images/invoice.png"
        className="w-[150px] block mb-[20px] mx-auto"
      />
      <h1 className="text-gray-500 font-bold text-[40px] text-center mb-[20px]">
        Loading...
      </h1>
      {/* <div className='text-center'>
            <Link to={"/eBookReader/"} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">Go Home</Link>
        </div> */}
    </div>
  );
}
