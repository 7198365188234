import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../Utils/app";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";

const AccountVerified = () => {
  let loadingToast;
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState("/login");
  const [userData, setUserData] = useState({});
  const [secondsLeft, setSecondsLeft] = useState(5);

  useEffect(() => {
    loadingToast = toast.loading("Loading");
    let token = searchParams.get("token");

    if (token) {
      const verifyAccount = async () => {
        axios.defaults.withCredentials = true;
        return await axios.post(`${baseUrl}/api/verify-account/${token}`);
      };

      verifyAccount()
        .then((response) => {
          toast.dismiss(loadingToast);
          setLoading(false);
          if (response?.data?.data?.isLogin) {
            toast.success("Account Verified Successfully");
            dispatch({ type: "LOGIN-USER", payload: response?.data?.data });
            setRedirectTo("/musics");
            // navigate('/musics')
            setSecondsLeft(15);
          } else {
            toast.success("Account Verified Successfully");
            navigate("/subscribe", { state: { dbUser: response?.data?.data } });
            setUserData(response?.data?.data);
            setRedirectTo("/subscribe");
            setSecondsLeft(15);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setError(true);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });
    }
  }, [searchParams]);

  useEffect(() => {
    let timer;
    if (redirectTo && redirectTo != "/login") {
      timer = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);

      if (secondsLeft === 0) {
        clearInterval(timer);
        navigate(redirectTo, { state: { dbUser: userData } });
      }
    }

    return () => clearInterval(timer);
  }, [secondsLeft, redirectTo]);

  return (
    <div className=" bg-[#BFBEBE] flex items-center justify-center absolute w-full h-full top-0 left-0 bg-opacity-90">
      {!loading && !error && (
        <div className="w-[500px] bg-white rounded-lg p-[50px]">
          <h1 className="mb-[20px] text-[#817F80] text-[30px] text-center font-bold">
            Account Verified
          </h1>
          <p className="text-base text-[#817F80] font-semibold text-center">
            Your Account has been verified! Redirecting in {secondsLeft}{" "}
            seconds...
            {/* {

                        redirectTo == "/musics"?  <Link to={redirectTo} className='text-[#18AD58] font-bold'> View Musics</Link>:
                        <Link to={"/subscribe"} state={{dbUser:userData}} className='text-[#18AD58] font-bold'> Subscribe</Link>
                    } */}
          </p>
        </div>
      )}
      {!loading && error && (
        <div className="w-[500px] bg-white rounded-lg p-[50px]">
          <h1 className="mb-[20px] text-[#817F80] text-[30px] text-center font-bold">
            An Error Occured
          </h1>
          {/* <p className='text-base text-[#817F80] font-semibold text-center'>Your Account has been verified! <Link to={"/login"} className='text-[#18AD58] font-bold'>Login</Link></p> */}
        </div>
      )}
    </div>
  );
};

export default AccountVerified;
