export const eu_countries = [
  "Albania",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Kosovo",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "The Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "Vatican City",
];

export const publicEmailServices = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "gmx.com",
  "protonmail.com",
  "zoho.com",
  "yandex.com",
  "tutanota.com",
  "fastmail.com",
  "hushmail.com",
  "mail.ru",
  "rediffmail.com",
  "inbox.com",
  "lycos.com",
  "lavabit.com",
  "runbox.com",
  "bigpond.com",
  "op.pl",
  "o2.pl",
  "web.de",
  "ukr.net",
  "mail.bg",
  "abv.bg",
  "freemail.hu",
  "centrum.cz",
  "seznam.cz",
  "orange.fr",
  "laposte.net",
  "sfr.fr",
  "libero.it",
  "virgilio.it",
  "tiscali.it",
  "alice.it",
  "tin.it",
  "wanadoo.fr",
  "neuf.fr",
  "freenet.de",
  "posteo.de",
  "qq.com",
  "163.com",
  "126.com",
  "yeah.net",
  "sina.com",
  "sogou.com",
  "vip.163.com",
  "foxmail.com",
  "tom.com",
  "21cn.com",
  "sohu.com",
];
