import React, { useState, useEffect } from "react";
import { baseUrl } from "../Utils/app";
import toast from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { state } = useAuth();
  let loadingToast;
  const [loading, setLoading] = useState(false);
  const [formControls, setFormControls] = useState({
    password: "",
    password_confirmation: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData = { email: email };
    loadingToast = toast.loading("Loading...");

    await axios
      .post(`${baseUrl}/api/password/email`, postData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.dismiss(loadingToast);
        setEmail("");
        toast.success(
          "Please check your email for password reset instructions",
        );
        // navigate("/myAccount")
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  return (
    <div className=" h-screen bg-[#BFBEBE] flex items-center justify-center">
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="w-[500px] bg-white rounded-lg p-[50px]"
      >
        <h1 className="mb-[20px] text-gray-500 text-3xl text-center font-semibold">
          {" "}
          Forgot Password
        </h1>
        <div className="mb-[20px]">
          <label className="mb-[5px] text-gray-500 block font-semibold">
            Email
          </label>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 border border-gray-500 block rounded-md w-full mb-[10px]"
          />
        </div>
        {!loading && (
          <button
            type="submit"
            className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase "
          >
            Submit
          </button>
        )}
        {loading && (
          <button
            type="button"
            className=" bg-[#18AD58] px-[50px] py-[10px] text-2xl rounded-full text-white font-bold uppercase block mx-auto"
          >
            <img width={25} src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
          </button>
        )}
      </form>
    </div>
  );
};

export default ForgetPassword;
