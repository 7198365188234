import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { baseUrl } from "../Utils/app";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useAuth } from "../AuthContext";

const MusicPlayer = () => {
  let { id } = useParams();

  let loadingToast;
  const musicPlayIdRef = useRef(null);
  const effectRan = useRef(false);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useAuth();
  const [music, setMusic] = useState([]);
  const [music2, setMusic2] = useState([]);
  const [artist, setArtist] = useState();
  const [audioFiles, setAudioFiles] = useState();

  useEffect(() => {
    console.log(artist?.attributes?.tags);
  }, [artist]);

  useEffect(() => {
    setLoading(true);
    loadingToast = toast.loading("Loading", { duration: 10000 });

    if (id) {
      // const getItems = async () => {
      //     return await axios.get(`${baseUrl}/api/item/${id}`)
      //  }

      //  getItems().then((resp) => {
      //     setMusic(resp.data.data.item)
      //     toast.dismiss(loadingToast)
      //     setLoading(false);
      //  }).catch((error)=>{
      //      console.log(error)
      //      toast.dismiss(loadingToast)
      //      toast.error(error?.message);
      //  });

      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
          Authorization:
            "Token QxYWoTb4uUS27V6hRvrj2ip3VWSVgP1WISyN4Ps2PPnYkzG59vjl7Z2GY0uQpD3h",
        },
      };

      fetch("https://api.soundstripe.com/v1/songs/" + id, options)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setMusic2(response.data);
          console.log(
            response?.included?.filter((item) => item?.type == "artists"),
          );
          setArtist(
            response?.included?.filter((item) => item?.type == "artists")[0],
          );
          setAudioFiles(
            response?.included?.filter(
              (item) => item?.type == "audio_files",
            )[0],
          );
          toast.dismiss(loadingToast);
          setLoading(false);
        })
        .catch((err) => {
          toast.dismiss(loadingToast);
          toast.error(err?.message);
          console.error(err);
        });
    }
  }, [id]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    // Function to start tracking
    const startTracking = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/start-music-play`,
          { music: id, music_name: music2?.attributes?.title },
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          },
        );
        console.log(response.data);
        musicPlayIdRef.current = response.data?.play_id; // Store the game_play_id
        console.log("Music Play ID:", musicPlayIdRef.current);
        effectRan.current = true;
      } catch (error) {
        console.log("Error starting game play tracking:", error);
        toast.error("Failed to start game play tracking");
      }
    };

    // Function to end tracking using sendBeacon
    const endTracking = () => {
      if (!musicPlayIdRef.current) return; // Do not end tracking if gamePlayId is null

      const url = `${baseUrl}/api/end-music-play`;
      const data = JSON.stringify({ play_id: musicPlayIdRef.current });

      // Create a blob and use sendBeacon for reliable sending
      const blob = new Blob([data], { type: "application/json" });
      let sendBeacon = navigator.sendBeacon(url, blob);

      console.log("Beacon Sent:", sendBeacon);

      // Optionally, set gamePlayId to null if needed
      musicPlayIdRef.current = null;
    };

    if (id && music2?.attributes?.title && !effectRan.current) {
      startTracking();
    }

    // Event listener callbacks
    const handleEndTracking = () => endTracking();

    // Attach event listeners
    window.addEventListener("beforeunload", handleEndTracking);
    window.addEventListener("unload", handleEndTracking);

    return () => {
      // Cleanup event listeners
      window.removeEventListener("beforeunload", handleEndTracking);
      window.removeEventListener("unload", handleEndTracking);

      // Ensure tracking is ended when component unmounts
      endTracking();
    };
  }, [id, music2, state?.token]);

  const getMusicImage = (image) => {
    // let image = includeds?.find(artist => artist?.id == musicId)?.attributes?.image
    let imageString = image?.split("?");
    return imageString?.length > 1 ? imageString[0] + "?w=700&h=700" : "";
  };

  return (
    <>
      {!loading ? (
        <section
          className="pb-[100px] bg-[#241C24] bg-cover "
          style={{ backgroundImage: "url(/assets/Images/bgsec1.png)" }}
        >
          <div className="custom-container ">
            <div className=" lg:flex pt-[150px] gap-[50px] items-center">
              <div className=" lg:flex-1  bg-red-50">
                <img
                  className="w-full object-cover h-[450px]"
                  src={getMusicImage(artist?.attributes?.image)}
                />
              </div>
              <div className="lg:flex-[2]">
                <h1 className="text-[#18AD58] text-[50px] font-bold uppercase">
                  {music2?.attributes?.title}
                </h1>
                <h2 className="text-white mb-6 font-semibold text-3xl">
                  {artist?.attributes?.name}
                </h2>
                <h3 className="text-white mb-8 font-semibold text-xl uppercase">
                  {music2?.attributes?.tags?.genre?.map((item, index) => {
                    return (
                      item +
                      (index != music2?.attributes?.tags?.genre?.length - 1
                        ? ", "
                        : "")
                    );
                  })}
                  {/* - {music?.release_date} */}
                </h3>
                <p className="text-white text-base mb-5">
                  {music2?.attributes?.description}
                </p>
              </div>
            </div>

            {/* <div className='w-[80%] mx-auto mt-[100px]'>
                                                            <ReactAudioPlayer className='w-full' src={audioFiles?.attributes?.versions?.mp3} controls volume />
                                                        </div> */}
            <div className="w-[80%] mx-auto mt-[100px]">
              <AudioPlayer
                showJumpControls={true}
                showDownloadProgress={true}
                src={audioFiles?.attributes?.versions?.mp3}
                onPlay={(e) => console.log("onPlay")}
                // other props here
              />
            </div>
          </div>
        </section>
      ) : (
        <section
          className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
          style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
        >
          <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
        </section>
      )}
    </>
  );
};

export default MusicPlayer;
