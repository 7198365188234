import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Nav = ({ unAuthRoute }) => {
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [toggleMenu, setToggleMe] = useState(false);

  useEffect(() => {
    console.log(state);
    if (!unAuthRoute.includes(location.pathname) && state.isAuth == false) {
      navigate("/login");
    }
  }, [state, location]);
  return (
    <>
      {state.isAuth &&
      location.pathname != "/lp1" &&
      location.pathname != "/lp3"&&
        location.pathname != "/PrimePlayPromo" ? (
        <header class=" w-full bg-[#241C24] lg:bg-transparent py-[20px] lg:absolute z-10">
          <div class="custom-container relative">
            <div class=" flex justify-between items-center">
              <div class=" flex space-x-[100px]">
                <div>
                  <Link to={"/"}>
                    <img width="150" src="/assets/Images/logo.png" />
                  </Link>
                </div>
                <div class="hidden lg:flex items-centebr ">
                  <ul class="uppercase flex space-x-[35px]">
                    {/* <li class={location.pathname == "/home"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/home">Home</Link></li> */}
                    <li
                      class={
                        location.pathname == "/about"
                          ? "text-[#18AD58] border-b border-[#18AD58] block"
                          : "text-white block"
                      }
                    >
                      <Link to="/about">About</Link>
                    </li>
                    {/* <li class={location.pathname == "/movies"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/movies">Movies</Link> </li> */}
                    <li
                      class={
                        location.pathname == "/musics"
                          ? "text-[#18AD58] border-b border-[#18AD58] block"
                          : "text-white block"
                      }
                    >
                      <Link to="/musics">Music</Link>
                    </li>

                    <li class={location.pathname == "/games"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/games">
                      Games</Link>
                    </li>
                    {/* <li class={location.pathname == "/audioBooks"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/audioBooks">Audio Books</Link></li> */}
                    <li
                      class={
                        location.pathname == "/FAQ"
                          ? "text-[#18AD58] border-b border-[#18AD58] block"
                          : "text-white block"
                      }
                    >
                      <Link to="/FAQ">Help</Link>
                    </li>
                    <li
                      class={
                        location.pathname == "/myAccount"
                          ? "text-[#18AD58] border-b border-[#18AD58] block"
                          : "text-white block"
                      }
                    >
                      <Link to="/myAccount">My Account</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="flex space-x-4 items-center">
                {/* <button class="h-[35px] w-[35px] rounded-full bg-white relative">
                                    <span class="block absolute bg-red-500 border border-white rounded-full w-2 h-2 top-0 right-0 "></span>
                                </button> */}
                <div>
                  <img
                    class="h-[35px] w-[35px] rounded-full bg-white relative"
                    src={state?.user?.avatar}
                  />
                </div>
                <button onClick={() => setToggleMe(!toggleMenu)}>
                  <svg
                    class="text-white"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 20h9"></path>
                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                  </svg>
                </button>
              </div>
            </div>

            {toggleMenu && (
              <div className="absolute right-5 top-[45px] w-[240px] h-[auto] py-5 pl-3 bg-[#241C24] rounded-md shadow-xl">
                <ul class="uppercase flex-row space-y-[25px]">
                  {/* <li class={location.pathname == "/home"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/home">Home</Link></li> */}
                  <li
                    class={
                      location.pathname == "/about"
                        ? "text-[#18AD58] border-b border-[#18AD58] block"
                        : "text-white block"
                    }
                  >
                    <Link to="/about">About</Link>
                  </li>
                  {/* <li class={location.pathname == "/movies"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/movies">Movies</Link> </li> */}
                  <li
                    class={
                      location.pathname == "/musics"
                        ? "text-[#18AD58] border-b border-[#18AD58] block"
                        : "text-white block"
                    }
                  >
                    <Link to="/musics">Music</Link>
                  </li>

                  <li class={location.pathname == "/games"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/games">
                    Games</Link>
                  </li>
                  {/* <li class={location.pathname == "/ebooks"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/ebooks">E-Books</Link></li> */}
                  {/* <li class={location.pathname == "/audioBooks"? "text-[#18AD58] border-b border-[#18AD58] block":"text-white block"}><Link to="/audioBooks">Audio Books</Link></li> */}
                  <li
                    class={
                      location.pathname == "/FAQ"
                        ? "text-[#18AD58] border-b border-[#18AD58] block"
                        : "text-white block"
                    }
                  >
                    <Link to="/FAQ">Help</Link>
                  </li>
                  <li
                    class={
                      location.pathname == "/myAccount"
                        ? "text-[#18AD58] border-b border-[#18AD58] block"
                        : "text-white block"
                    }
                  >
                    <Link to="/myAccount">My Account</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </header>
      ) : (
        location.pathname != "/lp1" &&
        location.pathname != "/lp3" &&
        location.pathname != "/subscribe" &&
          location.pathname != "/subscribe39 " &&
        location.pathname != "/register"&&
          location.pathname != "/PrimePlayPromo"&& (
          <header class=" w-full bg-white py-[20px]">
            <div class="custom-container">
              <div class=" flex justify-between items-center">
                <div>
                  <img width="150" src="/assets/Images/logo.png" />
                </div>
                <div class="flex gap-x-4">
                  <Link
                    to={"/login"}
                    class=" block bg-[#18AD58] px-[10px] md:px-[40px] py-[5px] text-[14px] rounded-md text-white font-semibold"
                  >
                    LOGIN
                  </Link>
                  <Link
                    to={"/register"}
                    class=" block bg-[#18AD58] px-[10px] md:px-[40px] py-[5px] text-[14px] rounded-md text-white font-semibold"
                  >
                    SIGN UP
                  </Link>
                </div>
              </div>
            </div>
          </header>
        )
      )}
    </>
  );
};

export default Nav;
