  import React, { useState, useEffect, useRef } from "react";
  import axios from "axios";
  import toast from "react-hot-toast";
  import { useLocation, useParams } from "react-router-dom";
  import { baseUrl } from "../Utils/app";
  import { ReactReader } from "react-reader";
  import { useAuth } from "../AuthContext";
  import { Link } from "react-router-dom";



  const Game = () => {
    let loadingToast;
    const location = useLocation();
    const effectRan = useRef(false);
    const [loading, setLoading] = useState(true);
    const { state, dispatch } = useAuth();
    const [game, setGame] = useState(null);
    const [featured, setFeatured] = useState();
    let { id } = useParams();

    useEffect(()=>{
      if(location?.state?.game)setGame(location?.state?.game);setLoading(false);
    },[location])

    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use 'auto' for an instant scroll
        });
      };
      scrollToTop();
    }, []);


    useEffect(() => {

      if(id && location?.state?.game?.id) return;
      loadingToast = toast.loading("Loading");

      const getItem = async () => {
        axios.defaults.withCredentials = true;
        return await axios.get(`${baseUrl}/api/get-game/${id}`, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        } );
      };

      getItem()
        .then((resp) => {
          setGame(resp.data.data.game);
          toast.dismiss(loadingToast);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });

    }, [id,location]);

    useEffect(() => {
      if (game == null && !state.isAuth) return;
      if (effectRan.current) return; // Return early if effect has already run once

      const loadingToast = toast.loading("Loading");

      const trackVisit = async () => {
        axios.defaults.withCredentials = true;
        return axios.post(`${baseUrl}/api/track-game-detail-view`, { game: game?.id }, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
      }

      trackVisit()
        .then((resp) => {
          toast.dismiss(loadingToast);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });

      effectRan.current = true; // Mark effect as run
    }, [game]);

    return (
      <>
        {!loading ? (
          <>
            <section
              className="h-auto py-[100px] bg-[#241C24] bg-cover"
              style={{ backgroundImage: "url(/assets/Images/bgsec1.png)" }}
            >
              <div className="custom-container "></div>
            </section>

              <section
                className="pb-[50px] bg-[#241C24] bg-cover mt-[-3]"
                style={{ backgroundImage: "url(/assets/Images/sec3bg.png)" }}
              >
                <div className="custom-container">
                    <div className=" lg:flex pt-[50px] gap-[50px] items-center">
                    <div className=" flex-1 mb-5 lg:mb-0  ">
                      <img
                        className="w-full object-cover h-[450px]"
                        src={game?.image}
                      />
                    </div>
                    <div className="flex-[2]">  
                      <h1 className="text-[#18AD58] text-[50px] font-bold mb-6 uppercase">
                        {game?.name}
                      </h1>
                      <p className="text-white text-base mb-10">
                        {/* {eBookReader?.description} */}
                        {game?.description}
                      </p>
                      <div>
                        <Link
                          to={`/games/${id}/play?game=${game?.id}`}
                          state={{game:game}}
                          className="  bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold"
                        >
                          Play Game
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


          </>
        ) : (
          <section
            className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
            style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
          >
            <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
          </section>
        )}
      </>
    );
  };

  export default Game;