  import React, { useEffect, useState, useRef } from "react";
  import { useAuth } from "../AuthContext";
  import { useNavigate } from "react-router-dom";
  import { Link } from "react-router-dom";
  import { baseUrl } from "../Utils/app";
  import toast from "react-hot-toast";
  import axios from "axios";
  import { db } from "../Utils/firebase";
  import { useLocation } from "react-router-dom";
  import { useSearchParams } from "react-router-dom";
  import TagManager from "react-gtm-module";

  const geoip2 = window.geoip2;

  const Subscribe = () => {
    const { state, dispatch } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const effectRan = useRef(false);
    const [formControls, setFormControls] = useState({
      firstName: "",
      lastName: "",
      card: "",
      exp_date_m: "",
      exp_date_y: "",
      cvv: "",
      user_id: "",
      agree: false,
    });
    const [locationData, setLocationData] = useState({
      postal: "dsds",
      country_name: "",
      country: "",
      city: "",
      currency: "USD",
    });
    const [paymentLink, setPaymentLink] = useState("");

    const [searchParams] = useSearchParams();
    const [price, setPrice] = useState(null);
    let loadingToast;

    let currency = location?.state?.currency ? location?.state?.currency : "USD";

    useEffect(() => {
      let id = searchParams.get("directive");
      if (location?.state?.dbUser?.user?.id || id) {
        (async () => {
          let planValue = searchParams.get("plan");
          let planAmount = 9.99;
          let prodDId = 2;

          switch (planValue) {
            case "P1":
              planAmount = 9.99;
              prodDId = 2;
              TagManager.dataLayer({
                dataLayer: {
                  event: "subscribe_9_99",
                  page: location.pathname + location.search,
                },
              });
              break;

            case "P2":
              planAmount = 19.99;
              prodDId = 3;
              TagManager.dataLayer({
                dataLayer: {
                  event: "subscribe_19_99",
                  page: location.pathname + location.search,
                },
              });
              break;

            case "P3":
              planAmount = 29.99;
              prodDId = 4;
              TagManager.dataLayer({
                dataLayer: {
                  event: "subscribe_29_99",
                  page: location.pathname + location.search,
                },
              });
              break;

            default:
              planAmount = 9.99;
              prodDId = 2;
              TagManager.dataLayer({
                dataLayer: {
                  event: "subscribe_9_99",
                  page: location.pathname + location.search,
                },
              });
              break;
          }
          setPrice(planAmount);
          getRocketGateLink(
            planAmount,
            location?.state?.dbUser?.user?.id
              ? location?.state?.dbUser?.user?.id
              : id,
            prodDId,
            location?.state?.has_trial 
          );
        })();
      }
    }, [location, locationData, searchParams]);

    useEffect(() => {
      if (!price) return;
      // if(location?.state?.geoData == undefined) return;
      if (effectRan.current) return; // Return early if effect has already run once
      console.log(location?.state?.geoData);
      const loadingToast = toast.loading("Loading");

      const trackPage = async () => {
        axios.defaults.withCredentials = true;
        return await axios.post(`${baseUrl}/api/subscribe-page-visit/${price}`, {
          ip_address: location?.state?.geoData?.traits?.ip_address  ? location?.state?.geoData?.traits?.ip_address : '0000',
          campaign_name: location?.state?.campaignData.camp,
        });
      };

      trackPage()
        .then((resp) => {
          toast.dismiss(loadingToast);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });

      effectRan.current = true; // Mark effect as run
    }, [price, location]);

    // useEffect(() => {
    //     let id = searchParams.get('directive');
    //     if(location?.state?.dbUser?.user?.id || id){
    //         getRocketGateLink(price, location?.state?.dbUser?.user?.id ? location?.state?.dbUser?.user?.id : id)

    //     }
    // },[price])

    useEffect(() => {
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use 'auto' for an instant scroll
        });
      };
      scrollToTop();
    }, []);

    const handleLogin = () => {
      dispatch({ type: "LOGIN" });
      navigate("/home");
    };

    const getRocketGateLink = async (planAmount, id, prodId, trial) => {
      const postData = {
        amount: planAmount,
        zipCode: locationData.postal,
        address: "address",
        prodId: prodId,
        currency: currency,
        trial:trial,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/rocketGate-pay/${id}`,
        headers: {
          "Content-Type": "multipart/form-data",
          withCredentials: true,
        },
        data: postData,
        withCredentials: true,
      };

      await axios
        .request(config)
        .then((response) => {
          console.log(response);
          setPaymentLink(response?.data?.data?.link);
          setLoading(false);
          toast.dismiss(loadingToast);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!formControls?.agree) {
        toast.error("You have to agree to Terms and Conditions.");
        return;
      }
      setLoading(true);
      let postData = {
        ...formControls,
      };
      postData = {
        ...postData,
        first_name: formControls?.firstName,
        last_name: formControls?.lastName,
        card_number: formControls?.card,
        user_id: location?.state?.dbUser?.user?.id,
        currency: currency,
      };
      console.log(postData);

      await axios
        .post(`${baseUrl}/api/saveCard`, postData)
        .then((response) => {
          console.log(response);
          setLoading(false);
          toast.dismiss(loadingToast);
          toast.success("Card Added Successfully");
          dispatch({ type: "LOGIN-USER", payload: location?.state?.dbUser });
          setFormControls({
            user_id: "",
            firstName: "",
            lastName: "",
            card: "",
            exp_date_m: "",
            exp_date_y: "",
            cvv: "",
          });
          navigate("/musics");
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message,
          );
          setLoading(false);
        });
    };

    return (
      <div className=" pb-[100px] bg-[#fff] pt-[50px]">
        <img
          src="./assets/Images/logo.png"
          className="w-[200px] block mb-[10px] mx-auto"
        />
        <h1 className="text-gray-500 font-bold text-[27px] text-center mb-[20px]">
          100% UNLIMITED ACCESS
        </h1>
        {/* {paymentLink} */}
        <div className="xl:w-[60%] mx-auto px-2 xl:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px]">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="bg-[#F8F9F9] p-[20px]"
              style={{ backgroundImage: "url(/assets/Images/bg1.png)" }}
            >
              <h2 className="text-xl text-gray-600 font-semibold text-center">
                VERIFY YOUR ACCOUNT
              </h2>
              <p className="text-sm text-center mb-[15px]">
                100% {location?.state?.has_trial?" FREE ": ""} SECURE ACTIVATION -{" "}
                <span className="text-green-500 font-extrabold ">
                  {currency} {location?.state?.has_trial ? "0.00" : price } 
                  {/* {price} */}
                </span>
              </p>
              <div className="flex gap-x-[10px] justify-center mb-[15px]">
                <div className="flex gap-x-[10px]">
                  <svg
                    width="16"
                    height="16"
                    fill="#FFC200"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="#FFC200"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="#FFC200"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    fill="#FFC200"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.947 9.181a1 1 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.997.997 0 0 0-1.822-.001L8.622 8.052l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.204l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065Z"></path>
                  </svg>

                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    stroke="#FFC200"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.5 9.75h-8.063L12 2.25l-2.438 7.5H1.5l6.563 4.5-2.532 7.5L12 17.062l6.469 4.688-2.532-7.5 6.563-4.5Z"></path>
                    <path
                      fill="#FFC200"
                      stroke="none"
                      d="M12 2.25v14.813L5.531 21.75l2.532-7.5L1.5 9.75h8.063L12 2.25Z"
                    ></path>
                  </svg>
                </div>
                <p className="text-sm">264,235 USERS</p>
              </div>
              <p className="text-xs text-center mb-[20px]">
                We respect your privacy. You will be discreetly billed as
                IGFLIX.NET located in Cyprus.
              </p>
              {/* <div className='bg-black px-4 py-2'>
                              <div>
                                  <label className=' text-xs md:text-sm text-white flex gap-x-2 items-start mb-2'>
                                      <div className='flex-shrink-0 '>
                                          <input name='plans' onChange={()=> setPrice('9.99')} checked={price == '9.99'} className='mt-[5px] block' type='radio' /> 

                                      </div>
                                      <span className='block'>
                                          Basic Plan (9.99);
                                      </span>
                                  </label>
                                  <label className=' text-xs md:text-sm text-white flex gap-x-2 items-start mb-2'>
                                      <div className='flex-shrink-0 '>
                                          <input name='plans' onChange={()=> setPrice('19.99')} checked={price == '19.99'} className='mt-[5px] block' type='radio' /> 

                                      </div>
                                      <span className='block'>
                                          Standard Plan (19.99);
                                      </span>
                                  </label>
                                  <label className=' text-xs md:text-sm text-white flex gap-x-2 items-start mb-2'>
                                      <div className='flex-shrink-0 '>
                                          <input name='plans' onChange={()=> setPrice('29.99')} checked={price == '29.99'} className='mt-[5px] block' type='radio' /> 

                                      </div>
                                      <span className='block'>
                                          Premium Plan (29.99);
                                      </span>
                                  </label>
                              </div>
                          </div> */}
              {paymentLink && (
                <iframe
                  src={paymentLink}
                  title="Onehubplay Payment"
                  width="100%"
                  height="540px"
                  allowFullScreen
                ></iframe>
              )}

              {/* <div className='flex gap-x-[20px] justify-center mb-[30px]'>
                              <img width={70} className='' src='/assets/Images/norton.png' />
                              <img width={70} src='/assets/Images/privacy.png'/>
                              <img width={70} src='/assets/Images/secured.png'/>
                          </div> */}
              <div className="mb-[30px text-center">
                <img
                  className="w-[80%] block mx-auto"
                  src="/assets/Images/norton-group.png"
                />
              </div>
              <p className="text-xs text-center text-gray-500">
                {" "}
                Billing issues phone support number: +1 (325) 298-0325
              </p>
            </form>
            <div
              className="bg-[#F8F9F9] p-[20px]"
              style={{ backgroundImage: "url(/assets/Images/bg1.png)" }}
            >
              <h2 className="text-xl text-gray-600 font-semibold text-center">
                UNLOCK A WORLD OF PREMIUM DIGITAL CONTENT!
              </h2>
              <img className="my-[40px]" src="./assets/Images/laptop.png" />
              <p className="text-sm text-center font-semibold mb-[40px] text-gray-500">
                SECURING MEMBERSHIP GRANTS YOU ACCESS TO:
              </p>
              <div className="flex gap-x-[40px] justify-center my-[30px]">
                <div>
                  <img
                    className="h-[40px] block mx-auto"
                    src="/assets/Images/games-icon.png"
                  />
                  <p className="text-xs text-center text-gray-500">Games</p>
                </div>
                <div>
                  <img
                    className="h-[40px] block mx-auto"
                    src="/assets/Images/music.png"
                  />
                  <p className="text-xs text-gray-500">Music</p>
                </div>
                {/* <div>
                                  <img className='h-[40px] block mx-auto' src='/assets/Images/book.png'/>
                                  <p className='text-xs text-gray-500'>Books</p>
                              </div> */}
              </div>
              <p className="text-xs mb-[20px] text-justify text-gray-500">
                {location?.state?.has_trial ? `A credit card is used as a means to determine the user's geographical location so we can deliver licensed content in the correct language. We will not charge your card for your 2-day free membership. A pre-authorization amount will be applied upon registration. At the end of the free membership period, your free access will automatically convert to a paid subscription at ${currency} ${price} per month unless you cancel. The membership is automatically renewed every month at this rate until you cancel it.` : `A credit/debit card is used as a means to determine the user's geographical location, so we can deliver licensed content in the correct language. A pre-authorization amount will be applied upon registration. The subscription will begin immediately, charging ${currency} ${price} per month unless canceled. It is automatically renewed every month until you cancel it. You can view all your billing information in the members' section and in your welcome email.` } 

              </p>
            </div>
          </div>

          {/* <div className='mt-[100px] w-[90%] md:w-[50%] mx-auto text-center underline text-sm font-bold'>
                      <p>© 2024 InfiniteGroove Limited/onehubplay.com</p>
                      Pipinou, 6, 2320, Nicosia, Cyprus
                  </div> */}
        </div>
      </div>
    );
  };

  export default Subscribe;