import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Utils/app";
import axios from "axios";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

const geoip2 = window.geoip2;

export default function CancelSuccess() {
  const { state, dispatch } = useAuth();
  const [loading, setLoading] = useState(false);
  let loadingToast;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    loadingToast = toast.loading("Loading");
    let id = searchParams.get("id");
    let invoiceID = searchParams.get("invoiceID");
    let hash = searchParams.get("hash");

    if (id && invoiceID) {
      
        cancelMembershipRequest(id, invoiceID);
    }
  }, [searchParams]);


  const cancelMembershipRequest = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseUrl}/api/cancel-membership`,
        {},
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        },
      )
      .then((response) => {
        console.log(response?.data);
        dispatch({ type: "LOGOUT" });
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        );
        setLoading(false);
      });
  };

  return (
    <div className=" pb-[100px] bg-[#fff] pt-[50px] lg:h-[80vh]">
      <img
        src="./assets/Images/invoice.png"
        className="w-[150px] block mb-[20px] mx-auto"
      />
      <h1 className="text-gray-500 font-bold text-[40px] text-center mb-[20px]">
        Loading...
      </h1>
      {/* <div className='text-center'>
            <Link to={"/eBookReader/"} className=" bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold">Go Home</Link>
        </div> */}
    </div>
  );
}
