import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { baseUrl } from "../Utils/app";
import { ReactReader } from "react-reader";
import { useAuth } from "../AuthContext";

const EBookReader = () => {
  let { id } = useParams();

  let loadingToast;
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useAuth();
  const [eBookReader, setEBookReader] = useState([]);
  const [location, setLocation] = useState(0);

  useEffect(() => {
    loadingToast = toast.loading("Loading");

    if (id) {
      const getItems = async () => {
        return await axios.get(`${baseUrl}/api/item/${id}`);
      };

      getItems()
        .then((resp) => {
          setEBookReader(resp.data.data.item);
          toast.dismiss(loadingToast);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(loadingToast);
          toast.error(error?.message);
        });
    }
  }, [id]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' for an instant scroll
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    let screenTimeId;

    const startScreenTime = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/track-screen-time`,
          { screen_name: "E-book Reader Screen" },
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          },
        );
        screenTimeId = response.data.screen_time_id;
      } catch (error) {
        console.error("Error tracking screen time:", error);
      }
    };

    const endScreenTime = async () => {
      if (screenTimeId) {
        try {
          await axios.post(
            `${baseUrl}/api/update-screen-time`,
            { screen_time_id: screenTimeId },
            {
              headers: {
                Authorization: `Bearer ${state?.token}`,
              },
            },
          );
        } catch (error) {
          console.error("Error updating screen time:", error);
        }
      }
    };

    startScreenTime();

    return () => {
      endScreenTime();
    };
  }, [state]);

  return (
    <>
      {!loading ? (
        <>
          <section
            className="h-auto py-[100px] bg-[#241C24] bg-cover"
            style={{ backgroundImage: "url(/assets/Images/bgsec1.png)" }}
          >
            <div className="custom-container ">
              <div className="h-[600px] bg-white w-[70%]  mx-auto">
                <ReactReader
                  url={eBookReader?.file_link}
                  location={location}
                  locationChanged={(epubcfi) => setLocation(epubcfi)}
                />
              </div>
            </div>
          </section>
          <section
            className="pb-[50px] bg-[#241C24] bg-cover mt-[-3]"
            style={{ backgroundImage: "url(/assets/Images/sec3bg.png)" }}
          >
            <div className="custom-container">
              <div className=" flex pt-[50px] gap-x-[50px] items-center">
                <div className=" flex-1  bg-red-50">
                  <img
                    className="w-full object-cover h-[450px]"
                    src={eBookReader?.cover_image_small}
                  />
                </div>
                <div className="flex-[2]">
                  <h1 className="text-[#18AD58] text-[50px] font-bold mb-6 uppercase">
                    {eBookReader?.title}
                  </h1>
                  <p className="text-white text-base mb-10">
                    {eBookReader?.description}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section
          className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
          style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
        >
          <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
        </section>
      )}
    </>
  );
};

export default EBookReader;
