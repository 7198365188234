
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { baseUrl } from "../Utils/app";
import { useAuth } from "../AuthContext";


const GamePlaceholderFree = () => {

  const gamePlayIdRef = useRef(null);
  const location = useLocation();  
  const { state, dispatch } = useAuth();
  const [gameLink, setGameLink] = useState();
  const [game, setGame] = useState(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  let loadingToast;


  useEffect(()=>{
    console.log(location)
    if(location?.state?.game)setGame(location?.state?.game);setLoading(false);
  },[location])

  useEffect(() => {
    let link = searchParams.get("game");

    // if (link) {
    //   setGameLink(link);
    // }
  }, [searchParams]);


  useEffect(() => {
    let id = searchParams.get("game");
    if(id && location?.state?.game?.id) return;
    loadingToast = toast.loading("Loading");

    const getItem = async () => {
      axios.defaults.withCredentials = true;
      return await axios.get(`${baseUrl}/api/get-game/${id}`, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      } );
    };

    getItem()
      .then((resp) => {
        setGame(resp.data.data.game);
        toast.dismiss(loadingToast);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadingToast);
        toast.error(error?.message);
      });

  }, [searchParams,location]);

  
  // useEffect(() => {
  //   let gamePlayId = null;

  //   // Function to start tracking
  //   const startTracking = async () => {
  //     try {
  //       const response = await axios.post(`${baseUrl}/api/start-game-play`, {game: game?.id}, {
  //         headers: {
  //           Authorization: `Bearer ${state?.token}`,
  //         },
  //       });
  //       console.log(response.data)
  //       gamePlayId = response.data.data?.game_play_id; // Store the game_play_id
  //       console.log("Game Play ID:", gamePlayId);
  //     } catch (error) {
  //       console.log('Error starting game play tracking:', error);
  //       toast.error('Failed to start game play tracking');
  //     }
  //   };
  //   // Function to end tracking
  //   const endTracking = async () => {
  //     if (!gamePlayId) return; // Do not end tracking if gamePlayId is null
  //     try {
  //       const response = await axios.post(`${baseUrl}/api/end-game-play`, {game: game.id, game_play_id: gamePlayId}, {
  //         headers: {
  //           Authorization: `Bearer ${state?.token}`,
  //         },
  //       });
  //       console.log(response.data)
  //       gamePlayId = null;
  //     } catch (error) {
  //       console.log('Error ending game play tracking:', error);
  //       toast.error('Failed to end game play tracking');
  //     }
  //   };

  //   if(game?.id){
  //       startTracking();
  //   }

  //   if(gamePlayId){
  //     // Event listeners and initial tracking start
  //     window.addEventListener('beforeunload', endTracking);
  //     window.addEventListener('blur', endTracking);
  //   }
    
  //   return () => {
  //     if(gamePlayId){
  //       endTracking();
  //       window.removeEventListener('beforeunload', endTracking);
  //       window.removeEventListener('blur', endTracking);
  //     }
      
  //   };
  // }, [game]);


  // useEffect(() => {
  //   let gamePlayId = null;

  //   // Function to start tracking
  //   const startTracking = async () => {
  //     try {
  //       const response = await axios.post(`${baseUrl}/api/start-game-play`, { game: game?.id }, {
  //         headers: {
  //           Authorization: `Bearer ${state?.token}`,
  //         },
  //       });
  //       console.log(response.data);
  //       gamePlayId = response.data.data?.game_play_id; // Store the game_play_id
  //       console.log("Game Play ID:", gamePlayId);
  //     } catch (error) {
  //       console.log('Error starting game play tracking:', error);
  //       toast.error('Failed to start game play tracking');
  //     }
  //   };

  //   // Function to end tracking using sendBeacon
  //   const endTracking = () => {
  //     if (!gamePlayId) return; // Do not end tracking if gamePlayId is null

  //     const url = `${baseUrl}/api/end-game-play`;
  //     const data = JSON.stringify({ game_play_id: gamePlayId });

  //     // Create a blob and use sendBeacon for reliable sending
  //     const blob = new Blob([data], { type: 'application/json' });
  //     let sendBeacon = navigator.sendBeacon(url, blob);

  //     console.log(sendBeacon)

  //     // Optionally, set gamePlayId to null if needed
  //     gamePlayId = null;
  //   };

  //   if (game?.id) {
  //     startTracking();
  //   }

  //   // Event listeners for 'beforeunload' and 'blur'
  //   const handleEndTracking = () => endTracking();

  //   if (gamePlayId) {
  //     window.addEventListener('beforeunload', handleEndTracking);
  //     window.addEventListener('blur', handleEndTracking);
  //   }

  //   return () => {
  //     if (gamePlayId) {
  //       endTracking();
  //       window.removeEventListener('beforeunload', handleEndTracking);
  //       window.removeEventListener('blur', handleEndTracking);
  //     }
  //   };
  // }, [game, state?.token]);

  useEffect(() => {
    
    // Function to start tracking
    const startTracking = async () => {
      try {
        const response = await axios.post(`${baseUrl}/api/start-game-play`, { game: game?.id }, {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        });
        console.log(response.data);
        gamePlayIdRef.current = response.data.data?.game_play_id; // Store the game_play_id
        console.log("Game Play ID:", gamePlayIdRef.current);
      } catch (error) {
        console.log('Error starting game play tracking:', error);
        toast.error('Failed to start game play tracking');
      }
    };

    // Function to end tracking using sendBeacon
    const endTracking = () => {
      if (!gamePlayIdRef.current) return; // Do not end tracking if gamePlayId is null

      const url = `${baseUrl}/api/end-game-play`;
      const data = JSON.stringify({ game_play_id: gamePlayIdRef.current });

      // Create a blob and use sendBeacon for reliable sending
      const blob = new Blob([data], { type: 'application/json' });
      let sendBeacon = navigator.sendBeacon(url, blob);

      console.log('Beacon Sent:', sendBeacon);

      // Optionally, set gamePlayId to null if needed
      gamePlayIdRef.current = null;
    };

    if (game?.id) {
      startTracking();
    }

    // Event listener callbacks
    const handleEndTracking = () => endTracking();

    // Attach event listeners
    window.addEventListener('beforeunload', handleEndTracking);
    window.addEventListener('unload', handleEndTracking);

    return () => {
      // Cleanup event listeners
      window.removeEventListener('beforeunload', handleEndTracking);
      window.removeEventListener('unload', handleEndTracking);

      // Ensure tracking is ended when component unmounts
      endTracking();
    };
  }, [game, state?.token]);

  return (
    <div>
      <section
        className=" bg-[#241C24] bg-cover mt-[-3] "
        style={{ backgroundImage: "url(/assets/Images/sec3bg.png)" }}
      >
        <div className="custom-container ">
          
        </div>

        {
          (!loading) ? (
            <div className="w-[100vw`] h-[100vh]">
              <iframe
                src={game?.url}
                className=" pt-[100px]"
                title="Onehubplay Payment"
                width='100%' height='100%' allowfullscreen frameborder='0'
              ></iframe>
            </div>
          ):(
              <section
                className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
                style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
              >
                <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
              </section>
            )
        }
        
      </section>

      <div className=" bg-[#241C24] absolute w-full h-full top-0 left-0 z-[10]">
        {
          (!loading) ? (
            <div className="w-[100vw`] h-[100vh]">
              <iframe
                src={game?.url}
                title="Onehubplay Payment"
                width='100%' height='100%' allowfullscreen frameborder='0'
              ></iframe>
            </div>
          ):(
              <section
                className="h-screen bg-[#241C24] bg-cover flex items-center justify-center"
                style={{ backgroundImage: "url(./assets/Images/plainbg.png)" }}
              >
                <img src={"/assets/Images/Eclipse-1s-200px.svg"}></img>
              </section>
            )
        }
      </div>
    </div>
  );
};

export default GamePlaceholderFree;
 