// src/Pages/Support.js

import React, { useState } from "react";
import { baseUrl } from "../Utils/app"; // Adjust the import based on your project structure
import toast from "react-hot-toast";
import axios from "axios";

const Support = () => {
  let loadingToast;
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    loadingToast = toast?.loading("Loading");
    setLoading(true);

    const requestData = {
      email: email,
      description: description,
    };

    try {
      await axios
        .post(`${baseUrl}/api/submit-support`, requestData)
        .then((response) => {
          console.log(response);
          setMessage("Request submitted successfully!");
          setEmail("");
          setDescription("");
          setLoading(false);
        })
        .catch((error) => {
          setMessage("Failed to submit request.");
          toast?.error("Failed to submit request.");
          toast?.dismiss(loadingToast);
          setLoading(false);
        });
    } catch (error) {
      toast?.error("Error Occured! Please try again later.");
      toast?.dismiss(loadingToast);
      setMessage("An error occurred. Please try again later.");
      setLoading(false);
    }
  };

  return (
    <div className="py-[100px] px-4 md:px-[50px] lg:px-[90px] xl:px-[150px] bg-[#241C24]">
      <div
        className=" support-body bg-cover "
        style={{ backgroundImage: "url(/assets/Images/bg.webp)" }}
      >
        <div className="flex gap-x-[100px] mb-[30px] flex-wrap  gap-y-[50px]">
          <div className=" w-[100%] lg:w-[50%] flex-grow-0">
            <h1 className=" text-[#18AD58] text-4xl font-semibold mb-[40px]">
              Contact Us
            </h1>
            <p className="text-white text-xs text-justify">
              {" "}
              <span className="font-semibold ">
                InfiniteGroove Limited
              </span>{" "}
              provides secure and reliable website subscriptions, known for
              their trustworthiness and long-standing reputation. Any charges
              incurred will be listed as{" "}
              <span className="font-semibold ">igflix.net</span> on your billing
              statements. For any subscription-related queries or requests, you
              are welcome to contact us through our{" "}
              <span className="font-semibold ">
                Online Billing Support Form
              </span>{" "}
              or reach out to our billing department, which is available 24/7.
              The contact information for immediate assistance is listed below.
            </p>
          </div>
          <div className="flex-1">
            <div className=" w-full h-4 bg-[#51C162] mb-6 rounded-full"></div>
            <div className="text-right text-white">
              <p className="text-lg mb-4 font-bold text-gray-500">
                Billing support contact information:
              </p>
              <p className="text-xs text-white">
                International Phone: <b>+1 (325) 298-0325 </b>
                <br /> or using
              </p>
              <p className="text-sm text-gray-500">
                <b>Online Billing Support Form</b>
              </p>
            </div>
          </div>
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className="w-full">
          <button className="block bg-[#51C162] text-lg px-2  md:text-2xl font-bold text-white w-full md:w-[70%] py-1 rounded-full mx-auto uppercase mb-[30px]">
            Online Billing Support Form
          </button>

          <div className="md:w-[70%] mx-auto">
            <p className="text-white text-center text-sm px-4 mb-[30px]">
              We encourage you to provide detailed information and a clear
              description of your issue or question. This will help us to
              promptly and accurately address your concerns.
            </p>
            <div className="md:w-[60%] mx-auto">
              <div className="mb-[15px]">
                <label className="text-xs font-semibold text-white">
                  Email Address:
                </label>
                <input
                  name="email"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="border border-white rounded block bg-transparent w-full  h-[42px] p-[5px]"
                />
              </div>
              <div className="mb-[30px]">
                <label className="text-xs font-semibold text-white">
                  Description of Problem or Question:
                </label>
                <textarea
                  name="description"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="block border border-white rounded bg-transparent w-full p-[5px]"
                  rows={4}
                ></textarea>
              </div>
              {/* <ReCAPTCHA ref={recaptcha} sitekey={"6Les6XEpAAAAAKomXKWyAW_gUpYOb3IfapxLq97B"} /> */}
              {!loading ? (
                <button
                  type="submit"
                  className="block bg-[#51C162] text-base font-bold text-white px-4 py-1 rounded-full mx-auto uppercase mt-8"
                >
                  Submit Inquiry
                </button>
              ) : (
                <button
                  type="button"
                  className="block bg-gray-400 text-base font-bold text-white px-4 py-1 rounded-full mx-auto uppercase mt-8"
                >
                  Loading...
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Support;
