  import React, { useState, useEffect } from "react";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import { baseUrl } from "../Utils/app";
  import toast from "react-hot-toast";
  import { useSearchParams } from "react-router-dom";
  import { useNavigate } from "react-router-dom";

  export default function UnsubscribeNotification() {
    let loadingToast;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
      loadingToast = toast.loading("Loading");
      let id = searchParams.get("id");

      if (id) {
        const unSubscribeRequest = async () => {
          axios.defaults.withCredentials = true;
          return await axios.post(
            `${baseUrl}/api/unsubscribe-email-notification/${id}`,
          );
        };

        unSubscribeRequest()
          .then((response) => {
            toast.dismiss(loadingToast);
            setLoading(false);
            // navigate('/musics')
            // toast.success("Email Notification cancelled");
            toast.dismiss(loadingToast);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError(true);
            toast.dismiss(loadingToast);
            toast.error(error?.message);
          });
      }
    }, []);

    return (
      <div className=" bg-[#BFBEBE] flex items-center justify-center absolute w-full h-full top-0 left-0 bg-opacity-90">
        {!loading && !error && (
          <div className="w-[500px] bg-white rounded-lg p-[50px]">
            <h1 className="mb-[20px] text-[#817F80] text-[30px] text-center font-bold">
              Email Notification Cancelled
            </h1>
            <p className="text-base text-[#817F80] font-semibold text-center">
              You have successfully unsubscribed from our platform.
            </p>
          </div>
        )}
        {!loading && error && (
          <div className="w-[500px] bg-white rounded-lg p-[50px]">
            <h1 className="mb-[20px] text-[#817F80] text-[30px] text-center font-bold">
              An Error Occured
            </h1>
            {/* <p className='text-base text-[#817F80] font-semibold text-center'>Your Account has been verified! <Link to={"/login"} className='text-[#18AD58] font-bold'>Login</Link></p> */}
          </div>
        )}
      </div>
    );
  }