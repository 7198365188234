      import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import PrimePlayRegister from "./PrimePlayRegister";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { baseUrl } from "../Utils/app";

const geoip2 = window.geoip2;

const PrimePlayPromo = () => {
  const [cancelModal, setCancelModal] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [geoData, setGeoData] = useState(null);

  const [params, setParams] = useState({
    camp: '',
    unid: '',
    email: '',
  });
  const effectRan = useRef(false);
  let loadingToast;

  useEffect(() => {
    geoip2.country(
      (response1) => {
        setGeoData(response1);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  useEffect(() => {




  }, [location]);  

  useEffect(() => {
    if (geoData == null ) return;
    // if(params.camp == "" || params.camp == null) return;
    if (effectRan.current) return; // Return early if effect has already run once

    // Get the URLSearchParams object from the location search
    const urlParams = new URLSearchParams(location.search);

    // Extract the values from the URL
    const camp = urlParams.get('camp');
    const unid = urlParams.get('unid');
    const email = urlParams.get('email');

    let params = { camp, unid, email }
    setParams({...params });  

    // const hasValues = camp && unid && email;

    console.log(camp, unid, email);

    const loadingToast = toast.loading("Loading");
    let postData = {
      ip_address: geoData?.traits?.ip_address? geoData?.traits?.ip_address : '34834',  
       campaign_name: params?.camp,
       // unid:urlParams.get('unid');
       // email: urlParams.get('email');

    }

    const trackPage = async () => {
      axios.defaults.withCredentials = true;
      return await axios.post(`${baseUrl}/api/lp1-page-visit`, postData);
    };

   trackPage()
    .then((resp) => {
      console.log(resp)
      toast.dismiss(loadingToast);
    })
    .catch((error) => {
      console.log(error);
      toast.dismiss(loadingToast);
      toast.error(error?.message);
    });

    if(params?.camp){

      setCancelModal(true);
    }

    effectRan.current = true; // Mark effect as run

  }, [geoData,params]);
 
    const trackPage = async () => {
      axios.defaults.withCredentials = true;
      return await axios.post(`${baseUrl}/api/lp1-page-visit`, {
        ip_address: geoData?.traits?.ip_address,
      });
    };
  return (
    <div className={cancelModal ? "relative h-[120vh] overflow-hidden" : ""}>
      <header className="bg-white py-2">
        <div class="custom-container">
          {/* <div>
                        <div>
                            <img className='w-[100px] md:w-[150px]' src="/assets/Images/logo.png" />
                        </div>
                        <div>
                            <h2 className='text-[#6E6E6E] text-center font-bold text-lg'>Entertainment Unleashed</h2>
                        </div>
                        <div className='flex space-x-1 items-center justify-end lg:w-[150px]'>
                            <button onClick={() => setCancelModal(true)}>
                                <img width={15} src='/assets/Images/topbar1.png' />
                            </button>
                            <button>
                                <img width={15} src='/assets/Images/topbar2.png' />
                            </button >
                        </div>
                    </div> */}
          <div class=" flex justify-between items-center ">
            <div className=" flex-grow-0">
              <img
                className="w-[100px] md:w-[150px]"
                src="/assets/Images/logo.png"

              />
            </div>
            {/* <div className=' justify-self-center'> */}
            <h2 className=" text-[#6E6E6E] text-center font-bold text-lg text-wrap lg:text-balance hidden lg:block">
              Entertainment Unleashed
            </h2>
            {/* </div> */}
            <div class="flex space-x-1 items-center justify-end flex-grow-0 lg:w-[150px]">
              <button onClick={() => setCancelModal(true)}>
                <img width={15} src="/assets/Images/topbar1.png" />
              </button>
              <button>
                <img width={15} src="/assets/Images/topbar2.png" />
              </button>
            </div>
          </div>
          <h2 className=" text-[#6E6E6E] text-center font-bold text-lg text-wrap lg:text-balance mt-2 block lg:hidden">
            Entertainment Unleashed 
          </h2>
        </div>
      </header>

      <section
        className="py-[50px] bg-cover"
        style={{ backgroundImage: "url(/assets/Images/registrationBg1.png)" }}
      >
        <div className="flex gap-x-[40px] justify-between my-[30px] w-[95%] md:w-[60%] lg:w-[40%] mx-auto ">
          <div>
            <img
              className="h-[60px] block mx-auto"
              src="/assets/Images/sec1img1.png"
            />
            <p className="text-base  text-gray-400 text-center">Secure</p>
          </div>
          <div>
            <img
              className="h-[60px] block mx-auto"
              src="/assets/Images/sec1img2.png"
            />
            <p className="text-base text-gray-400 text-center">Premium</p>
          </div>
          <div>
            <img
              className="h-[60px] block mx-auto"
              src="/assets/Images/sec1img3.png"
            />
            <p className="text-base text-gray-400 text-center">All Devices</p>
          </div>
        </div>
      </section>

      <section
        className="py-[50px] bg-contain bg-no-repeat"
        style={{ backgroundImage: "url(/assets/Images/registrationBg2.png)" }}
      >
        <div className=" my-[30px] w-[95%] md:w-[40%] mx-auto text-center">
          <h1 className="font-normal text-lg text-gray-600 text-center mb-5">
            ONLINE REGISTRATION
          </h1>
          <button
            onClick={() => setCancelModal(true)}
            className="  bg-[#18AD58] px-[50px] py-[15px] text-[40px] rounded-xl text-white font-bold uppercase"
          >
            Continue
          </button>
          <div className="flex items-center justify-center space-x-[2px] mt-4 text-xs">
            <span className="w-2 h-2 rounded-full bg-[#18AD58] block"></span>
            <span className="block text-xs text-gray-600">
              Available on every browser
            </span>
          </div>
        </div>
      </section>


      {/* <section
        className="py-[50px] bg-cover"
        style={{ backgroundImage: "url(/assets/Images/registrationBg3.png)" }}
      >
        <div className="flex gap-x-[40px] justify-center my-[30px] w-[95%] md:w-[70%] mx-auto">
          <div className="w-[250px]">
            <img
              className="h-[60px] block mx-auto"
              src="/assets/Images/games-icon.png"
            />
            <p className="text-lg text-center text-gray-500">Games</p>
          </div>
          <div className="w-[250px] border-l border-gray-500">
            <img
              className="h-[60px] block mx-auto"
              src="/assets/Images/music.png"
            />
            <p className="text-lg text-gray-500 text-center">Music</p>
          </div>
          <div className="flex-1">
            <img className='h-[60px] block mx-auto' src='/assets/Images/book.png' />
                        <p className='text-lg text-gray-500 text-center'>Books</p>
          </div>
        </div>
      </section> */}

      <section className="pt-[100px] pb-[100px] bg-[#fff]">
        <div className="w-[95%] lg:w-[80%] mx-auto">
          <div className="flex flex-row gap-[40px] justify-center flex-wrap">
            <div className="w-[280px] bg-contain bg-no-repeat ">
              <div
                className="w-full h-[245px] flex items-center justify-center bg-contain rounded-2xl"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/eclat-test.appspot.com/o/fileUploads%2Feb4bdd74-b807-41e1-bae4-a0604b89d33c.jpg?alt=media&token=27502062-a4b7-4a2b-ad25-84b9f2e0bc94)",
                }}
              >
                {/* <img className='h-[80px]' src='/assets/Images/2.png'/> */}
                <div className="w-full h-full bg-green-400 opacity-50 rounded-2xl"></div>
              </div>
              <h3 className="text-lg text-gray-600 text-center font-bold my-1">
                NON-STOP MUSIC AND GAMES!
              </h3>
              <p className="text-center text-gray-500">
                Step into the ultimate entertainment hub with our platform!
                Enjoy an exceptional selection of music and thrilling games. Let
                us be your go-to source for fun and relaxation.
              </p>
            </div>
            <div className="w-[280px] bg-contain bg-no-repeat ">
              <div
                className="w-full h-[245px] flex items-center justify-center bg-cover rounded-2xl"
                style={{
                  backgroundImage: "url(/assets/Images/MusicController.jpg",
                }}
              >
                {/* <img className='h-[80px]' src='/assets/Images/1.png'/> */}
                <div className="w-full h-full bg-green-400 opacity-50 rounded-2xl"></div>
              </div>
              <h3 className="text-lg text-gray-600 text-center font-bold my-1">
                GAMING HAVEN!
              </h3>
              <p className="text-center text-gray-500">
                OneHubPlay takes you to new gaming heights. Dive into a diverse
                range of exhilarating games that keep your adrenaline pumping
                and your mind sharp. Experience the ultimate in gaming
                excitement with our top-notch selection.
              </p>
            </div>
            <div className="w-[280px] bg-contain bg-no-repeat">
              <div
                className="w-full h-[245px] flex items-center justify-center bg-contain rounded-2xl"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/eclat-test.appspot.com/o/fileUploads%2F334e12e5-3724-4b90-a2b1-181ab9db9703.jpg?alt=media&token=09d3e943-9d80-49e0-8787-d47d9bb77043)",
                }}
              >
                {/* <img className='h-[80px]' src='/assets/Images/3.png'/> */}
                <div className="w-full h-full bg-green-400 opacity-50 rounded-2xl"></div>
              </div>
              <h3 className="text-lg text-gray-600 text-center font-bold my-1">
                MUSIC ANYWHERE!
              </h3>
              <p className="text-center text-gray-500">
                Seamlessly integrate music into your busy life with our vast
                array of songs and albums. Whether you're seeking relaxation,
                entertainment, or captivating melodies, our digital music
                collection is always ready to enhance your experience.
              </p>
            </div>
          </div>
          <div className="text-center mt-[100px]">
            <button
              onClick={() => setCancelModal(true)}
              className="  bg-[#18AD58] px-[40px] py-[10px] text-[20px] rounded-xl text-white font-bold uppercase"
            >
              GET STARTED
            </button>
          </div>
        </div>
      </section>

      <section
        className="py-[50px] bg-cover"
        style={{ backgroundImage: "url(/assets/Images/registrationBg4.png)" }}
      >
        <div className="flex gap-x-[40px] justify-between my-[30px] w-[95%] gap-y-10 lg:w-[70%] mx-auto flex-wrap">
          <div className="w-full md:flex-1">
            <p className="text-lg text-center text-gray-500">Register</p>
            <img
              className="h-[220px] block mx-auto"
              src="/assets/Images/sec2img1.png"
            />
          </div>
          <div className="w-full md:flex-1 md:pt-[120px]">
            <img
              className="h-[220px] block mx-auto"
              src="/assets/Images/sec2img2.png"
            />
            <p className="text-lg text-gray-500 text-center">Verify</p>
          </div>
          <div className="w-full md:flex-1 flex-1">
            <p className="text-lg text-gray-500 text-center">Activate</p>
            <img
              className="h-[220px] block mx-auto"
              src="/assets/Images/sec2img3.png"
            />
          </div>
        </div>
      </section>

      <section className="py-[50px] lg:py-[50px] bg-cover bg-[#F8F9F9]">
        <div className="w-[95%] lg:w-[80%] mx-auto">
          <div className="grid md:grid-cols-2">
            <div className="">
              <img src="/assets/Images/aw.png" />
            </div>
            <div className=" px-5 flex items-center">
              <div>
                <h2 className=" text-2xl text-gray-600 font-bold mb-6">
                  ENTERTAINMENT UNLEASHED
                </h2>

                <p className="text-gray-500 font-medium">

                  Explore and enjoy a vast collection of music and games with
                  us. Dive into our curated catalogue and discover a world of
                  amazing music and exciting game titles at your fingertips.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-[50px] pb-[100px] bg-[#fff]">
        <div className="w-[95%] lg:w-[80%] mx-auto">
          <div className="flex flex-row gap-[40px] justify-center flex-wrap">
            <div
              className="h-[600px] w-[280px] bg-contain bg-no-repeat bg-red-50 pt-[150px] px-[15px]"
              style={{ backgroundImage: "url(/assets/Images/sec4img1.png)" }}
            >
              <h3 className="text-3xl text-gray-100 text-center mb-[100px]">
                SAFE
              </h3>
              <p className="text-center text-gray-500">
                A secure connection ensures your content access remains safe at
                all times. This safeguard provides peace of mind as you browse
                and enjoy the content.
              </p>
            </div>
            <div
              className="h-[600px] w-[280px] bg-contain bg-no-repeat bg-red-50 pt-[150px] px-[15px]"
              style={{ backgroundImage: "url(/assets/Images/sec4img1.png)" }}
            >
              <h3 className="text-3xl text-gray-100 text-center mb-[100px]">
                PREMIUM
              </h3>
              <p className="text-center text-gray-500">
                Unlock countless possibilities and immerse yourself in exclusive content. Subscribe today to enjoy continuous access with the freedom to cancel anytime.
              </p>
            </div>
            <div
              className="h-[600px] w-[280px] bg-contain bg-no-repeat bg-red-50 pt-[130px] px-[15px]"
              style={{ backgroundImage: "url(/assets/Images/sec4img1.png)" }}
            >
              <h3 className="text-3xl text-gray-100 text-center mb-[100px]">
                MULTIPLE <br /> DEVICES
              </h3>
              <p className="text-center text-gray-500">
                Seamlessly connect and stream your cherished content across all
                your devices, wherever you are, whenever you desire. This
                feature enhances flexibility, allowing your entertainment to
                travel with you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-[100px] bg-cover bg-[#F8F9F9]">
        <div className="w-[95%] lg:w-[80%] mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-6">
            <div className=" px-5 col-span-2">
              <div>
                {" "}
                <h2 className=" text-2xl text-gray-600 font-bold mb-4">
                  Why should you proceed with activation?
                </h2>
                <ul className="">
                  <li className="flex gap-x-1 items-center text-gray-500">
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      stroke="#18AD58"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 6 9 17l-5-5"></path>
                    </svg>{" "}
                    <span>Regularly updated with fresh content</span>{" "}
                  </li>
                  <li className="flex gap-x-1 items-center text-gray-500">
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      stroke="#18AD58"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 6 9 17l-5-5"></path>{" "}
                    </svg>{" "}
                    <span>Enjoyable on every device</span>{" "}
                  </li>
                  <li className="flex gap-x-1 items-center text-gray-500">
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      stroke="#18AD58"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 6 9 17l-5-5"></path>{" "}
                    </svg>{" "}
                    <span>Enhanced safety measures</span>{" "}
                  </li>
                  <li className="flex gap-x-1 items-center text-gray-500">
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      stroke="#18AD58"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 6 9 17l-5-5"></path>{" "}
                    </svg>{" "}
                    <span>Absolutely no ads</span>{" "}
                  </li>
                </ul>{" "}
              </div>{" "}
            </div>
            <div className=" px-5">
              <div>
                {" "}
                <h2 className=" text-2xl text-gray-600 font-bold mb-4">
                  How to Register:
                </h2>{" "}
                <ul>
                  <li className="flex gap-x-1 items-center text-gray-500">
                    {" "}
                    <span className=" text-[#18AD58] font-bold">1.</span>{" "}
                    <span>Set up an account</span>{" "}
                  </li>
                  <li className="flex gap-x-1 items-center text-gray-500">
                    {" "}
                    <span className=" text-[#18AD58] font-bold">2.</span>{" "}
                    <span>Verify your account</span>{" "}
                  </li>
                  <li className="flex gap-x-1 items-center text-gray-500">
                    {" "}
                    <span className=" text-[#18AD58] font-bold">3.</span>{" "}
                    <span>Dive into your content</span>{" "}
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="pt-16">
        <div className="w-[80%] text-gray-400 mx-auto text-center text-xs font-medium pb-[50px]">
          <p className="mb-4">
            A credit/debit card is used to verify the user's geographical location, enabling us to provide licensed content in the appropriate language. Subscription services are provided on a recurring monthly basis until canceled. You can access all relevant account information in the members' section and in your welcome email.
          </p>
          {/* <p className=' mb-4'> <Link to={"/terms-condition"}>Terms of Service</Link> | <Link to={"/privacy"}>Privacy Policy</Link> | <Link to={"/cookie"}>Cookie Policy</Link>  | Contact</p>
                    <p>Copyright © 2023 OneHubPlay.com</p> */}
        </div>
      </footer>

      {cancelModal && (
        <PrimePlayRegister
          setCancelModal={setCancelModal}
          geoData={geoData}
          campaignData={params}
        ></PrimePlayRegister>
      )}
    </div>
  );
};

export default PrimePlayPromo;
